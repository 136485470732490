export const SET_CURRENT_PROFILE = 'auth/SET_CURRENT_PROFILE';
export const SET_AIRPORT = 'flight/AIRPORT';

const initialState = {
  airport: []
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_CURRENT_PROFILE:
      return {
        ...state,
        currentProfile: action.profile
      };

    case SET_AIRPORT:
      return {
        ...state,
        airport: action.airport
      };

    default:
      return state;
  }
};

export const getCurrentProfile = id => dispatch =>
  new Promise(resolve => {
    setTimeout(() => {
      let profile;

      if (id === 1) {
        profile = {
          id,
          name: 'Pekka Rinne',
          image: 'pekka'
        };
      } else {
        profile = {
          id,
          name: 'Viktor Arvidsson',
          image: 'arvidsson'
        };
      }

      dispatch({
        type: SET_CURRENT_PROFILE,
        profile
      });

      resolve(profile);
    }, 3000);
  });

export const setCurrentProfile = data => dispatch =>
  new Promise(resolve => {
    dispatch({
      type: SET_AIRPORT,
      airport: data
    });

    resolve(data);
  });

export const removeCurrentProfile = () => dispatch =>
  new Promise(resolve => {
    dispatch({
      type: SET_CURRENT_PROFILE,
      profile: {}
    });

    resolve({});
  });
