export const SET_DYNAMICPAGE = 'genel/DYNAMICPAGE';
export const SET_BANNER = 'genel/BANNER';
export const SET_BANNER_TITLE = 'genel/BANNERTITLE';
export const SET_ROUTES = 'genel/ROUTES';
export const SET_SYSTEMTEXT = 'genel/SYSTEMTEXT';
export const SET_BLOGLIST2 = 'genel/BLOGLIST2';
export const SET_BLOGLIST3 = 'genel/BLOGLIST3';
export const SET_BLOGLIST4 = 'genel/BLOGLIST4';
export const SET_WEATHERTEXT = 'genel/WEATHERTEXT';
export const SET_MENU = 'genel/MENU';
export const SET_PROP = 'genel/PROP';
const initialState = {
  banner: [],
  dynamicpage: [],
  bannertitle: '',
  systemText: [],
  blogList2: [],
  blogList3: [],
  blogList4: [],
  menuvlig: [],
  weather: []
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_DYNAMICPAGE:
      return {
        ...state,
        dynamicpage: action.dynamicpage
      };
    case SET_BANNER_TITLE:
      return {
        ...state,
        bannertitle: action.bannertitle
      };
    case SET_BANNER:
      return {
        ...state,
        banner: action.banner
      };

    case SET_WEATHERTEXT:
      return {
        ...state,
        weatherText: action.weatherText
      };

    case SET_ROUTES:
      return {
        ...state,
        routes: action.routes
      };

    case SET_SYSTEMTEXT:
      return {
        ...state,
        [action.suffix]: action.systemText
      };

    case SET_BLOGLIST2:
      return {
        ...state,
        blogList2: action.blogList2
      };

    case SET_BLOGLIST3:
      return {
        ...state,
        blogList3: action.blogList3
      };

    case SET_BLOGLIST4:
      return {
        ...state,
        blogList4: action.blogList4
      };
    case SET_MENU:
      return {
        ...state,
        [action.suffix]: action.menu
      };
    case SET_PROP:
      return {
        ...state,
        [action.propname]: action.data
      };

    default:
      return state;
  }
};

export const setDynamicPage = data => dispatch =>
  new Promise(resolve => {
    dispatch({
      type: SET_DYNAMICPAGE,
      dynamicpage: data
    });

    resolve(data);
  });

export const setBanner = data => dispatch =>
  new Promise(resolve => {
    dispatch({
      type: SET_BANNER,
      banner: data
    });

    resolve(data);
  });

export const setBannerTitle = data => dispatch =>
  new Promise(resolve => {
    dispatch({
      type: SET_BANNER_TITLE,
      bannertitle: data
    });

    resolve(data);
  });

export const setRoutes = data => dispatch =>
  new Promise(resolve => {
    dispatch({
      type: SET_ROUTES,
      routes: data
    });

    resolve(data);
  });

export const setBlogList2 = data => dispatch =>
  new Promise(resolve => {
    dispatch({
      type: SET_BLOGLIST2,
      blogList2: data
    });

    resolve(data);
  });

export const setBlogList3 = data => dispatch =>
  new Promise(resolve => {
    dispatch({
      type: SET_BLOGLIST3,
      blogList3: data
    });

    resolve(data);
  });

export const setBlogList4 = data => dispatch =>
  new Promise(resolve => {
    dispatch({
      type: SET_BLOGLIST4,
      blogList4: data
    });

    resolve(data);
  });

export const setSystemText = (data, suffix) => dispatch =>
  new Promise(resolve => {
    dispatch({
      type: SET_SYSTEMTEXT,
      systemText: data,
      suffix: suffix != undefined ? 'systemText' + suffix : 'systemText'
    });

    resolve(data);
  });

export const setWeatherText = data => dispatch =>
  new Promise(resolve => {
    dispatch({
      type: SET_WEATHERTEXT,
      weatherText: data
    });

    resolve(data);
  });

export const setMenu = (data, suffix) => dispatch =>
  new Promise(resolve => {
    dispatch({
      type: SET_MENU,
      menu: data,
      suffix: 'menu' + suffix
    });

    resolve(data);
  });

export const setProp = (data, propname) => dispatch =>
  new Promise(resolve => {
    dispatch({
      type: SET_PROP,
      data: data,
      propname: propname
    });

    resolve(data);
  });
