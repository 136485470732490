import * as React from 'react';

class Border_soft extends React.Component {
  render() {
    return (
      <div className="theme-hero-area">
        <div className="theme-hero-area-body">
          <div className="container">
            <div className="row row-col-mob-gap">
              <div className="col-md-2 ">
                <a href="/laagste-prijsgarantie">
                  <div className="feature h-fix-80  _bsh-light feature-wrap-fade-white feature-center">
                    <i className="feature-icon feature-icon-primary-inverse feature-icon-box feature-icon-round fa fa-euro" />
                    <div className="feature-caption _c-w">
                      <h2 className="feature-title">Laagste prijsgarantie</h2>
                    </div>
                  </div>
                </a>
              </div>
              <div className="col-md-3">
                <a href="/ruim-aanbod-luchtvaartmaatschappijen">
                  <div className="feature   h-fix-80 _bsh-light feature-wrap-fade-white feature-center">
                    <i className="feature-icon feature-icon-primary-inverse feature-icon-box feature-icon-round fa fa-plane" />
                    <div className="feature-caption _c-w">
                      <h2 className="feature-title">Ruim aanbod luchtvaartmaatschappijen</h2>
                    </div>
                  </div>
                </a>
              </div>
              <div className="col-md-2">
                <a href="/reizen-zonder-ticket">
                  <div className="feature  h-fix-80 _bsh-light feature-wrap-fade-white feature-center">
                    <i className="feature-icon feature-icon-primary-inverse feature-icon-box feature-icon-round fa fa-ticket" />
                    <div className="feature-caption _c-w">
                      <h2 className="feature-title">Reizen zonder ticket</h2>
                    </div>
                  </div>
                </a>
              </div>
              <div className="col-md-3">
                <a href="/verzorgt-vliegtickets-voor-meer-dan-50000-passagiers-per-jaar">
                  <div className="feature   h-fix-80 _bsh-light feature-wrap-fade-white feature-center">
                    <i className="feature-icon feature-icon-primary-inverse feature-icon-box feature-icon-round fa fa-users" />
                    <div className="feature-caption _c-w">
                      <h2 className="feature-title">Verzorgt vliegtickets voor meer dan 50.000 passagiers per jaar</h2>
                    </div>
                  </div>
                </a>
              </div>

              <div className="col-md-2">
                <a href="/nederlands-klantenservice">
                  <div className="feature  h-fix-80 _bsh-light feature-wrap-fade-white feature-center">
                    <i className="feature-icon feature-icon-primary-inverse feature-icon-box feature-icon-round fa fa-headphones" />
                    <div className="feature-caption _c-w">
                      <h2 className="feature-title">Nederlands Klantenservice</h2>
                    </div>
                  </div>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Border_soft;
