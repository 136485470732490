import * as sessionUtil from '../utils/sessionUtil';
import { contentManagementApi } from '../api/contentManagementApi';
import { getLanguageID } from '../routes/shared/functions';

export async function loadLanguageData() {
  let sessionId = sessionUtil.getSessionId();
  if(typeof window !== "undefined")
    window.sessionStorage.clear();
  sessionUtil.setSessionId(sessionId);
  let languageData = await contentManagementApi.systemText(getLanguageID());
  if(typeof window !== "undefined"){
    languageData.d.forEach(x => {
      window.sessionStorage.setItem(x.SystemTextId, x.Text);
    });
  }
}
