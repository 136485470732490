import { client } from './config/axiosConfig';

export const shoppingCartApi = {
  paymentReceiver: {
    get: async data => {
      //TODO
    },
    post: async data => {
      //TODO
    }
  },
  traveller: async data => {
    const response = await client.request({
      url: '/traveller',
      method: 'POST',
      data: data
    });

    return response.data;
  },
  ticketing: async data => {
    //TODO
  },
  shoppingCart: {
    get: async data => {
      const response = await client.request({
        url: '/shopping-cart',
        method: 'POST',
        data: data
      });

      return response.data;
    },
    product: {
      add: async data => {
        const response = await client.request({
          url: '/shopping-cart/product',
          method: 'POST',
          data: data
        });

        return response.data;
      },
      delete: async data => {
        const response = await client.delete('/shopping-cart/product', {
          data: {
            source: data
          }
        });

        return response.data;
      }
    },
    flight: {
      add: async data => {
        const response = await client.request({
          url: '/shopping-cart/flight',
          method: 'POST',
          data: data
        });

        return response.data;
      },
      delete: async data => {
        //TODO
      }
    },
    manyProducts: async data => {
      const response = await client.request({
        url: '/shopping-cart/many-products',
        method: 'POST',
        data: data
      });

      return response.data;
    },
    details: async data => {
      const response = await client.request({
        url: '/shopping-cart/details',
        method: 'POST',
        data: data
      });

      return response.data;
    },
    generateForDelayedPayment: async data => {
    const response = await client.request({
      url: "/shopping-cart/delayed-payment",
      method: 'POST',
      data: data
    });
    return response.data;
  },
    genrateForPaymentLink: async data => {
      const response = await client.request({
        url : "/shopping-cart/payment-link",
        method: 'POST',
        data: data
      });
    }
  },
  reserve: async data => {
    const response = await client.request({
      url: '/reserve',
      method: 'POST',
      data: data
    });

    return response.data;
  },
  product: async queryParams => {
    let url = '/product';
    if (queryParams) if (queryParams.length > 0) url += '?' + queryParams;
    const response = await client.request({
      url: url,
      method: 'GET'
    });
    return response.data;
  },

};
