import * as sessionUtil from '../utils/sessionUtil';
import { shoppingCartApi } from '../api/shoppingCartApi';
import { func } from 'prop-types';

export async function getShoppingCart() {
  let data = {
    sessionid: sessionUtil.getSessionId()
  };

  return await shoppingCartApi.shoppingCart.get(data);
}

export async function addFlightToCart(offerId, departure, adultCount, childCount, infantCount) {
  let data = {
    offerId: offerId,
    sessionid: sessionUtil.getSessionId(),
    departure: departure,
    adultCount: adultCount,
    childCount: childCount,
    infantCount: infantCount
  };

  return await shoppingCartApi.shoppingCart.flight.add(data);
}

export async function startAddFlightToCartSequence(offerId, departure, adultCount, childCount, infantCount, products) {
  await addFlightToCart(offerId, departure, adultCount, childCount, infantCount);
  if (products) await addManyProductsToFlight(products);
}

export async function addManyProductsToFlight(products, offerId) {
  let productRequest = [];
  products.forEach(product => {
    let data = {
      productId: product,
      sessionid: sessionUtil.getSessionId()
    };
    if (offerId) data.offerId = offerId;
    productRequest.push(data);
    });

  return await shoppingCartApi.shoppingCart.manyProducts(productRequest);
}

export async function addManyProductWithGeneratedData(data) {
  return await shoppingCartApi.shoppingCart.manyProducts(data);
}

export async function getProductByProviderCode(providerCode, flightType = null, category = null) {
  let data = {
    providerCode: providerCode,
    flightType: flightType,
    category: category
  };
  let queryParams = encodeQueryData(data);
  return await shoppingCartApi.product(queryParams);
}

export async function getProductsByCategory(category){
  return await getProductByProviderCode(null, null, category);
}

export async function getProducts() {
  return await shoppingCartApi.product(null);
}

export async function addProductToFlight(productId, offerId, paxUniqueId) {
  let data = {
    productId: productId,
    paxUniqueId: paxUniqueId,
    sessionId: sessionUtil.getSessionId
  };
  if (offerId != null) data.offerId = offerId;

  return await shoppingCartApi.shoppingCart.product.add(data);
}

export async function getDefaultTravellers() {
  let data = {
    sessionId: sessionUtil.getSessionId()
  };

  return await shoppingCartApi.traveller(data);
}

export async function reserveFlight(travellers, customer) {
  let data = {
    sessionid: sessionUtil.getSessionId(),
    Travellers: travellers,
    customerData: customer
  };

  return await shoppingCartApi.reserve(data);
}

export async function getShoppingCartDetails() {
  let data = {
    sessionid: sessionUtil.getSessionId()
  };

  return await shoppingCartApi.shoppingCart.details(data);
}

export async function removeProduct(productId, offerId, paxUniqueId) {
  let data = {
    productId: productId,
    offerId: offerId,
    paxUniqueId: paxUniqueId,
    sessionid: sessionUtil.getSessionId()
  };

  return await shoppingCartApi.shoppingCart.product.delete(data);
}

export async function generateShoppingCartForPaymentLink(reservationId,expiresTimeStamp,hash,sessionId) {
  const data = {
    reservationId: reservationId,
    timestamp: expiresTimeStamp,
    hash: hash,
    sessionId: sessionId
  }
  return await shoppingCartApi.shoppingCart.genrateForPaymentLink(data);
}

export async function generateShoppingCartForDelayedPayment(reservationId,expiresTimeStamp,hash,sessionId) {
  const data = {
    reservationId: reservationId,
    timestamp: expiresTimeStamp,
    hash: hash,
    sessionId: sessionId
  }
  return await shoppingCartApi.shoppingCart.generateForDelayedPayment(data);
}


function encodeQueryData(data) {
  const ret = [];
  for (let d in data) {
    if (data[d] != null) {
      ret.push(encodeURIComponent(d) + '=' + encodeURIComponent(data[d]));
    }
  }
  return ret.join('&');
}
