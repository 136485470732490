import uuid from 'uuid';

function getSessionId() {
  if(typeof window !== "undefined")
    return window.sessionStorage.getItem('sessionId');
  return null;
}

function setSessionId(value) {
  if(typeof window !== "undefined")
    window.sessionStorage.setItem('sessionId', value);
}

function ensureCreated() {
  let regex = /^[0-9a-f]{8}-[0-9a-f]{4}-[0-5][0-9a-f]{3}-[089ab][0-9a-f]{3}-[0-9a-f]{12}$/i;
  if (!regex.test(getSessionId())) setSessionId(uuid.v4());
}

function createNewSessionId() {
  let id = uuid.v4();
  setSessionId(id);
  return id;
}

export { getSessionId, setSessionId, ensureCreated, createNewSessionId };
