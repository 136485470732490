import React from 'react';
import { render, hydrate } from 'react-dom';
import { Provider } from 'react-redux';
import Loadable from 'react-loadable';
import { Frontload } from 'react-frontload';
import { ConnectedRouter } from 'connected-react-router';
import createStore from './store';
import * as sessionUtil from './app/utils/sessionUtil';
import * as contentManagementService from './app/service/contentManagementService';

import App from './app/app';
import './index.css';
import { Provider as ProviderKrax } from 'react-krax';
import * as serviceWorker from './serviceWorker';
import { initializeFirebase } from './app/push-notification';

function timeout() {
  return new Promise(resolve => setTimeout(resolve, 15000));
}

//Ensure sessionId is created
sessionUtil.ensureCreated();

//Loading language data
contentManagementService.loadLanguageData().then(() => {
  // Create a store and get back itself and its history object
  const { store, history } = createStore();

  // Running locally, we should run on a <ConnectedRouter /> rather than on a <StaticRouter /> like on the server
  // Let's also let React Frontload explicitly know we're not rendering on the server here
  const Application = (
    <Provider store={store}>
      <ProviderKrax>
        <ConnectedRouter history={history}>
          <Frontload noServerRender={true}>
            <App />
          </Frontload>
        </ConnectedRouter>
      </ProviderKrax>
    </Provider>
  );

  const root = document.querySelector('#root');

  if (root.hasChildNodes() === true) {
    // If it's an SSR, we use hydrate to get fast page loads by just
    // attaching event listeners after the initial render
    Loadable.preloadReady().then(() => {
      hydrate(Application, root);
    });
  } else {
    // If we're not running on the server, just render like normal
    render(Application, root);
  }

  // If you want your app to work offline and load faster, you can change
  // unregister() to register() below. Note this comes with some pitfalls.
  // Learn more about service workers: https://bit.ly/CRA-PWA
  //serviceWorker.unregister();
  serviceWorker.unregister();
  initializeFirebase();
});
