import axios from 'axios';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';

const baseUrl = process.env.DOMAIN;
const apiUrl = process.env.REACT_APP_API;
const swal = withReactContent(Swal);

export const client = axios.create({
  baseURL: apiUrl,
  headers: {
    'Content-type': 'application/json',
    'Access-Control-Allow-Origin': '*',
    'Access-Control-Allow-Methods': 'GET, PUT, POST, DELETE, OPTIONS'
  }
});