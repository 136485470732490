import { client } from './config/axiosConfig';

export const contentManagementApi = {
  systemText: async languageID => {
    const response = await client.request({
      url: `/cms/system-text?languageId=${languageID}`,
      method: 'GET'
    });

    return response.data;
  }
};
