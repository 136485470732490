export const SET_AIRPORT = 'flight/AIRPORT';

const initialState = {
  currentProfile: {}
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_AIRPORT:
      return {
        ...state,
        airport: action.airport
      };

    default:
      return state;
  }
};

export const setAirport = data => dispatch =>
  new Promise(resolve => {
    dispatch({
      type: SET_AIRPORT,
      airport: data
    });

    resolve(data);
  });
