import firebase from 'firebase';
import { getDataAsync } from './helpers/loadData';
import Cookies from 'js-cookie';
export const initializeFirebase = () => {
  firebase.initializeApp({
    apiKey: 'AIzaSyD13VoICT5KJQ8RBS-IWV6iF5Fq--4t58E',
    authDomain: 'triptimenl-7a969.firebaseapp.com',
    databaseURL: 'https://triptimenl-7a969.firebaseio.com',
    projectId: 'triptimenl-7a969',
    storageBucket: 'triptimenl-7a969.appspot.com',
    messagingSenderId: '44652972232',
    appId: '1:44652972232:web:768cc81d2a5a428ba77dfd',
    measurementId: 'G-WSSJSK1EE7' // tvoj sender id
  });
};
//
// function subscribeTokenToTopic(token) {
//     fetch('https://iid.googleapis.com/iid/v1/'+token+'/rel/topics/all', {
//         method: 'POST',
//         headers: new Headers({
//             'Authorization': 'key=' + config.serverToken,
//
//         })
//     }).then(response => {
//         if (response.status < 200 || response.status >= 400) {
//             throw 'Hata oluştuc: '+response.status + ' - ' + response.text();
//         }
//     }).catch(error => {
//         console.error(error);
//     })
// }
export const askForPermissioToReceiveNotifications = async () => {
  try {
    const messaging = firebase.messaging();

    await messaging.requestPermission();

    const token = await messaging.getToken();

    let _loginData = Cookies.get('Login');
    let userGuid = '';
    let loginData = _loginData ? JSON.parse(_loginData) : {};
    if (loginData && loginData.username && loginData.key) {
      userGuid = loginData.key.clientGuid;
    }

    let body = { token: token, userGuid: userGuid, type: 1 };
    let retData = await getDataAsync('saveSubscriber', body);
    return token;
  } catch (error) {
    console.error(error);
  }
};
