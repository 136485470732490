import React, { useEffect } from 'react';
import { generateShoppingCartForDelayedPayment, generateShoppingCartForPaymentLink } from '../service/shoppingCartService';
import * as sessionUtil from '../utils/sessionUtil';
import Swal from 'sweetalert2';


const DelayedPaymentCallback = ({ location }) => {
  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const reservationId = queryParams.get("reservationId")
    const expirationTimestamp = queryParams.get("timestamp");
    const hash = queryParams.get("hash");
    const sessionId = sessionUtil.createNewSessionId();

    const fetchData = async () => {
      await generateShoppingCartForDelayedPayment(reservationId, expirationTimestamp, hash, sessionId);
      if (typeof window !== "undefined")
        window.location.href = `/payment?sessionId=${sessionId}`
    };

    fetchData();
  }, [location.search]);

  return (
    <>
    </>
  );
};



const PaymentCallback = ({ location }) => {

  function getCookie(name) {
    if (typeof document == "undefined")
      return '';
    const value = `; ${document.cookie}`;
    const parts = value.split(`; ${name}=`);
    if (parts.length === 2)
      return parts
        .pop()
        .split(';')
        .shift();
  }
  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const reservationId = queryParams.get("reservationId")
    const expirationTimestamp = queryParams.get("timestamp");
    const hash = queryParams.get("hash");
    const sessionId = sessionUtil.createNewSessionId();

    const fetchData = async () => {
      try {
        await generateShoppingCartForPaymentLink(reservationId, expirationTimestamp, hash, sessionId);
        if (typeof window !== "undefined") {
          window.location.href = `/payment?sessionId=${sessionId}`;
        }
      } catch (error) {





        console.error("Error generating shopping cart for payment link:", error);

        let errorText = 'De betalingslink is verlopen. Neem contact op met de klantenservice op het nummer +31 75 8200 115.';
        switch (getCookie('languageID')) {
          case '3': // Dutch
            errorText = 'De betalingslink is verlopen. Neem contact op met de klantenservice op het nummer +31 75 8200 115.';
            break;
          case '5': // Turkish
            errorText = 'Ödeme bağlantısının süresi doldu. Lütfen müşteri hizmetleriyle iletişime geçin: +31 75 8200 115.';
            break;
          case '2': // German
            errorText = 'Der Zahlungslink ist abgelaufen. Bitte wenden Sie sich an unseren Kundenservice unter der Nummer +31 75 8200 115.';
            break;
          case '1': // English
            errorText = 'The payment link has been expired. Please contact our customer service at +31 75 8200 115.';
            break;
          default: // Default to Dutch
            errorText = 'De betalingslink is verlopen. Neem contact op met de klantenservice op het nummer +31 75 8200 115.';
            break;
        }


        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: errorText
        }).then(() => {
          if (typeof window !== "undefined")
            window.location.href = '/';
        });
      }
    };

    fetchData();
  }, [location.search]);


  return (
    <>
    </>
  );
};

export { DelayedPaymentCallback, PaymentCallback };


