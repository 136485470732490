import * as React from 'react';
import { connect } from 'react-redux';
import { getControlText, getLanguageID } from '../shared/functions';
import { AgentValidate, getAirports, getSystemText } from '../store/flight';
import { BrowserRouter, Link } from 'react-router-dom';
import moment from 'moment';
import Img from 'react-image';
import { bindActionCreators } from 'redux';
import { setSystemText } from '../../../modules/general';
import { frontloadConnect } from 'react-frontload';

async function frontload(props) {
  let promise = new Promise((resolve, reject) => {
    getSystemText('1', getLanguageID()).then(val => {
      resolve('ok');
    });
  });

  let result = await promise; // wait until the promise resolves (*)
}

class banner extends React.Component {
  constructor(props) {
    super(props);

    moment.locale('nl');

    this.state = {
      background: ''
    };
  }

  componentDidMount() {}

  render() {
    const fileExists = require('file-exists');

    const officersIds = [];

    if (this.props && this.props.dataSource) {
      let fto = '';
      let freturn = '';
      let foperator = '';

      if (this.props && this.props.general) {
        // if(this.props && this.props.systemText && this.props.systemText.payload) {
        fto = getControlText('895', this.props.general.systemText) ? getControlText('58', this.props.general.systemText).Text : '';
        freturn = getControlText('896', this.props.general.systemText) ? getControlText('72', this.props.general.systemText).Text : '';
        foperator = getControlText('908', this.props.general.systemText) ? getControlText('908', this.props.general.systemText).Text : '';
      }

      this.props.dataSource.forEach(row => {
        let rowTo = {
          pathname: '/',
          state: {
            SearchType: '1',
            FlightDate: row.FlightDatemini2,
            RetFlightDate: moment(row.FlightDatemini).format('YYYY-MM-DD'),
            FromAirport: row.DepartureID,
            ToAirport: row.ArrivalID,
            oneway: '1',
            Adult: 1,
            Child: 0,
            Baby: 0,
            OnlyDirect: 1,
            From: row.DepartureText,
            To: row.ArrivalText
          }
        };
        // let rowTo = {
        //     pathname: '/vliegtickets/turkije/' + row.DepartureText.toLowerCase().replace(' ','') + '/' + row.ArrivalText.toLowerCase().replace(' ',''),
        //     state:{
        //         SearchType: '1',
        //         FlightDate:row.FlightDatemini2,
        //         RetFlightDate:moment(row.FlightDatemini).format('YYYY-MM-DD'),
        //         FromAirport:row.DepartureID,
        //         ToAirport: row.ArrivalID,
        //         oneway:'1',
        //         Adult:1,
        //         Child:0,
        //         Baby:0,
        //         OnlyDirect:1,
        //         From:row.DepartureText,
        //         To:row.ArrivalText,

        //     }
        // }

        officersIds.push(
          <div className="col-md-4 ">
            <div className="theme-search-results-item theme-search-results-item-grid">
              <div className="theme-search-results-item-grid-body-full">
                <div className="banner _h-40vh banner-">
                  <div className="banner_price_logo">
                    <img src={'/img/airline/' + row.AirlineImage} alt={row.Airline} />
                  </div>
                  <div className="banner_price">€{row.minprice}</div>
                  <div className="datemini_date"> {row.FlightDate.replace('00:00:00', '')}</div>
                  <div className="banner-bg">
                    <Img src={['/img/bg/mini/' + row.Arrival + '_' + row.sira + '.jpg', '/img/bg/mini/404.jpg']} alt={row.ArrivalText} />
                  </div>
                  <div className="banner-caption banner-caption-bottom">
                    <div className="row _pt-10 _ph-20">
                      <div className="col-xs-12">
                        <div className="row">
                          <div className="col-xs-6 banner_caption_to">
                            <div>
                              <i className="fa fa-plane" /> {row.DepartureText}
                            </div>
                            <div className="soft-color">
                              {' '}
                              <i className="fa fa-plane return_icon" /> {row.ArrivalText}{' '}
                            </div>
                          </div>

                          <div className="col-xs-6 banner_caption_from_arr_depp">
                            {' '}
                            <div>
                              {fto} : {row.DepartureTimeShort}{' '}
                            </div>
                            <div className="soft-color">
                              {' '}
                              {freturn} : {row.ArrivalTimeShort}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="banner_grey text-center">
                      {foperator} {row.Airline}{' '}
                    </div>
                  </div>
                </div>
                <BrowserRouter forceRefresh={true}>
                  <Link rel="nofollow" className="theme-search-results-item-mask-link" to={rowTo}>
                    {' '}
                  </Link>
                </BrowserRouter>
              </div>
            </div>
          </div>
        );
      });
    }
    return (
      <div className="theme-page-section bg_alternate">
        <div className="container">
          <div className="theme-search-results _mb-10">
            <div className="row row-col-gap">{officersIds}</div>
          </div>
        </div>
      </div>
    );
  }
}
//export default connect((state) => (state))(banner);

function mapStateToProps(state) {
  return { ...state };
}

const mapDispatchToProps = dispatch => bindActionCreators({ setSystemText }, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(
  frontloadConnect(frontload, {
    onMount: true,
    onUpdate: false
  })(banner)
);
