import { combineReducers } from 'redux';

import auth from './auth';
import profile from './profile';
import flight from './flight';
import general from './general';

export default combineReducers({
  auth,
  profile,
  flight,
  general
});
