import * as React from 'react';
import { getSystemText, getIP, GetAccountInfo, saveContact, getMenu, getBlog } from '../store/flight';
import { getControlText, getLanguageID } from '../shared/functions';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import { Button } from 'antd';
import { krax } from 'react-krax/lib';
import moment from 'moment';
import { string } from 'prop-types';
import ReactGA from 'react-ga';
import { Helmet } from 'react-helmet';
import { BrowserRouter, Link } from 'react-router-dom';
import SearchFlight from '../inc/SearchFlightBar';
import { bindActionCreators } from 'redux';
import { setBlogList3, setSystemText } from '../../../modules/general';
import { frontloadConnect } from 'react-frontload';

async function frontload(props) {
  let promise = new Promise((resolve, reject) => {
    getSystemText('1', getLanguageID()).then(val => {
      if(val && val.data && val.data.d)
        props.setSystemText(val.data.d);

      resolve('ok');

      // resolve('ok');
    });
  });

  let result = await promise; // wait until the promise resolves (*)
}

class Faq extends React.Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {}

  render() {
    let title = '',
      titleh1 = '',
      description = '';

    if (
      this.props &&
      this.props.general &&
      this.props.general.systemText &&
      this.props.general.systemText &&
      this.props.general.systemText.length > 0
    ) {
      titleh1 = getControlText('325', this.props.general.systemText).Text;
      title = getControlText('1088', this.props.general.systemText).Text;
      description = getControlText('1089', this.props.general.systemText).Text;
    }

    return (
      <div>
        <div className="theme-hero-area front" style={{height: '477px'}}>
          <Helmet>
            <title>
              {title}
            </title>
            <meta name="description" content="{description}" />
            <meta name="robots" content="index, follow" />
          </Helmet>
          <div className="theme-hero-area-bg-wrap">
            <div className="theme-hero-area-bg" style={{ backgroundImage: 'url(/img/bg/genel_18.jpg' }}>
              <div className="theme-hero-area-mask theme-hero-area-mask-half" />
              <div className="theme-hero-area-body">
                <div className="container">
                  <div className="_pb-50 _pt-100 _pv-mob-50">
                    <SearchFlight />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="theme-page-section theme-footer">
          <div className="container">
            <h1>
              {titleh1}
            </h1>
            <div className="card">
              <div className="card-header">
                <h2 className="mb-0">
                  <span>Reservering - Mijn reservering controleren bij de luchtvaartmaatschappij.</span>
                </h2>
              </div>
              <div className="collapse show">
                <div>
                  Het is enkel mogelijk om op de websites van Transavia en Corendon uw reservering te controleren. Hiervoor ontvangt u van ons direct
                  na het boeken een uniek boekingsnummer waarmee u op de website van de luchtvaartmaatschappij kunt inloggen.
                </div>
              </div>
            </div>
            <div className="card">
              <div className="card-header">
                <h2 className="mb-0">
                  <span>Reservering - Ik heb niet gevlogen krijg ik restitutie?</span>
                </h2>
              </div>
              <div className="collapse show">
                <div>Er is geen restitutie mogelijk bij het missen van een vlucht of bij geen gebruik maken van een vlucht.</div>
              </div>
            </div>
            <div className="card">
              <div className="card-header">
                <h2 className="mb-0">
                  <span>Wat gebeurt er tijdens het inchecken?</span>
                </h2>
              </div>
              <div className="collapse show">
                <div>
                  <div>
                    De check-in medewerker zal u vragen om uw identiteit te laten zien, en de bevestigingscode met de geldige reisdocumenten.&nbsp;
                  </div>
                  <div>
                    Uw ingecheckte bagage zal gewogen worden en naar het bagageruim van het vliegtuig gestuurd worden. Tot slot ontvangt u uw
                    instapkaart.
                  </div>
                </div>
              </div>
            </div>
            <div className="card">
              <div className="card-header">
                <h2 className="mb-0">
                  <span> Wat is mijn bevestigingscode?</span>
                </h2>
              </div>
              <div className="collapse show">
                <div>
                  Zodra de boeking zonder problemen is voltooid, krijgt u een bevestigingscode (PNR). Deze code wordt vermeld op de bevestigingspagina
                  en tevens in het reisschema dat u per e-mail wordt toegezonden (bevestigings-e-mail). Deze code bestaat uit 6 tekens (cijfers en/of
                  letters).&nbsp;
                </div>
              </div>
            </div>
            <div className="card">
              <div className="card-header">
                <h2 className="mb-0">
                  <span>Besmettelijke ziekte/huidaandoening</span>
                </h2>
              </div>
              <div className="collapse show">
                <div>
                  Alle luchtvaartmaatschappijen hebben het recht om passagiers te weigeren met aandoeningen die tijdens de vlucht kunnen verergeren of
                  ernstige gevolgen hebben. Download de informatie van de Wereldgezondheidsorganisatie over lichamelijke gesteldheid om te vliegen. (
                  <a href="http://whqlibdoc.who.int/publications/2005/9241580364_chap2.pdf" target="_blank">
                    klik hier
                  </a>
                  ). Als er ook maar enige indicatie is dat een passagier een besmettelijke ziekte/huidaandoening heeft, kan de luchtvaartmaatschappij
                  om een medische verklaring vragen. Passagiers met een zichtbare huidaandoening (met inbegrip van, maar niet beperkt tot onderstaande
                  aandoeningen) wordt aangeraden om te reizen met een medische verklaring waarin wordt bevestigd dat ze voldoende hersteld zijn om te
                  vliegen.
                </div>
              </div>
            </div>
            <div className="card">
              <div className="card-header">
                <h2 className="mb-0">
                  <span>Bagage (ruim) - Artikelen gestolen</span>
                </h2>
              </div>
              <div className="collapse show">
                <div>
                  U moet allereerst aangifte doen bij de politie, voordat de luchtvaart maatschappij uw eventuele claim in behandeling kan nemen. Deze
                  aangifte is nodig om in aanmerking te komen voor een schadevergoeding.
                </div>
              </div>
            </div>
            <div className="card">
              <div className="card-header">
                <h2 className="mb-0">
                  <span>Bagage (ruim) - Ik neem een rolstoel mee of heb deze nodig.</span>
                </h2>
              </div>
              <div className="collapse show">
                <div>
                  <p>
                    Indien u slecht ter been bent, &nbsp;is op elke luchthaven een rolstoel beschikbaar. U dient zich minimaal 2,5 uur voor de
                    geplande vluchttijd in te checken en bij het check-in personeel om een rolstoel te vragen.
                    <br />
                  </p>
                </div>
              </div>
            </div>
            <div className="card">
              <div className="card-header">
                <h2 className="mb-0">
                  <span>Bagage (ruim) - Mag een baby ook bagage meenemen?</span>
                </h2>
              </div>
              <div className="collapse show">
                <div>
                  Voor een baby mag er max. 10 kg aan bagage meegenomen worden. Dit kan per maatschappij verschillen, zie hiervoor de bijlage van uw
                  e-ticket.
                </div>
              </div>
            </div>
            <div className="card">
              <div className="card-header">
                <h2 className="mb-0">
                  <span>Bagage (ruim) - extra bagage bijkopen</span>
                </h2>
              </div>
              <div className="collapse show">
                <div>
                  <p className="MsoNormal" style={{ marginBottom: '0.0001pt' }}>
                    <span
                      style={{
                        fontSize: '9pt',
                        fontFamily: 'Arial, sans-serif',
                        backgroundImage: 'initial',
                        backgroundAttachment: 'initial',
                        backgroundSize: 'initial',
                        backgroundOrigin: 'initial',
                        backgroundClip: 'initial',
                        backgroundPosition: 'initial',
                        backgroundRepeat: 'initial'
                      }}>
                      Vooraf bagage bijkopen is enkel mogelijk indien geboekt met de luchtvaartmaatschappijen Corendon en Transavia.
                    </span>
                    <span style={{ fontSize: '9pt', fontFamily: 'Arial, sans-serif' }}>
                      <br />
                      <span
                        style={{
                          backgroundImage: 'initial',
                          backgroundAttachment: 'initial',
                          backgroundSize: 'initial',
                          backgroundOrigin: 'initial',
                          backgroundClip: 'initial',
                          backgroundPosition: 'initial',
                          backgroundRepeat: 'initial'
                        }}>
                        U ontvangt
                      </span>
                    </span>
                    <span style={{ fontSize: '9pt', fontFamily: 'Arial, sans-serif' }}>
                      &nbsp;
                      <strong>
                        <span style={{ textDecoration: 'underline' }}>direct</span>
                      </strong>
                      &nbsp;
                    </span>
                    <span
                      style={{
                        fontSize: '9pt',
                        fontFamily: 'Arial, sans-serif',
                        backgroundImage: 'initial',
                        backgroundAttachment: 'initial',
                        backgroundSize: 'initial',
                        backgroundOrigin: 'initial',
                        backgroundClip: 'initial',
                        backgroundPosition: 'initial',
                        backgroundRepeat: 'initial'
                      }}>
                      na het plaatsen van de boeking een uniek boekingsnummer. Aan de hand van dit boekingsnummer kunt u inloggen op de website van de
                      desbetreffende luchtvaartmaatschappij en bagage bijkopen.&nbsp;
                    </span>
                    <span
                      style={{
                        fontSize: '12pt',
                        fontFamily: '"Times New Roman", "serif"',
                        msoFareastFontFamily: '"Times New Roman"',
                        msoFareastLanguage: 'NL'
                      }}
                    />
                  </p>
                  <p className="MsoNormal" style={{ marginBottom: '12pt' }}>
                    <span style={{ fontSize: '9pt', fontFamily: 'Arial, sans-serif' }}>
                      Bij andere luchtvaartmaatschappijen is het niet mogelijk om vooraf bagage bij te kopen.
                      <br />
                      <br />
                      {'{'}' '{'}'}
                    </span>
                    <strong>
                      <em>
                        <span style={{ textDecoration: 'underline' }}>
                          <span
                            style={{
                              fontSize: '9pt',
                              fontFamily: 'Arial, sans-serif'
                            }}>
                            Corendon Airlines en Corendon Dutch Airlines kennen de volgende tarieven
                          </span>
                        </span>
                      </em>
                    </strong>
                    <span style={{ fontSize: '9pt', fontFamily: 'Arial, sans-serif' }} />
                  </p>
                  <table
                    className="MsoNormalTable"
                    border={1}
                    cellSpacing={0}
                    cellPadding={0}
                    width="100%"
                    style={{
                      width: '100%',
                      borderCollapse: 'collapse',
                      msoYftiTbllook: 1184
                    }}>
                    <tbody>
                      <tr>
                        <td style={{ padding: '0.75pt 0.75pt 0.75pt 0.75pt' }}>
                          <p className="MsoNormal" style={{ marginBottom: '0.0001pt' }}>
                            <strong>
                              <span
                                style={{
                                  fontSize: '10pt',
                                  msoBidiFontSize: '11pt',
                                  fontFamily: '"Helvetica", "sans-serif"',
                                  msoFareastFontFamily: '"Times New Roman"',
                                  color: '#333333',
                                  msoFareastLanguage: 'NL'
                                }}>
                                Tarieven online (tot 5 uur voor vertrek)
                              </span>
                            </strong>
                            <span
                              style={{
                                fontSize: '10pt',
                                fontFamily: '"Helvetica", "sans-serif"',
                                msoFareastFontFamily: '"Times New Roman"',
                                color: '#333333',
                                msoFareastLanguage: 'NL'
                              }}
                            />
                          </p>
                        </td>
                      </tr>
                      <tr>
                        <td style={{ padding: '0.75pt 0.75pt 0.75pt 0.75pt' }}>
                          <p className="MsoNormal" style={{ marginBottom: '0.0001pt' }}>
                            <span
                              style={{
                                fontSize: '10pt',
                                fontFamily: '"Helvetica", "sans-serif"',
                                msoFareastFontFamily: '"Times New Roman"',
                                color: '#333333',
                                msoFareastLanguage: 'NL'
                              }}>
                              Handbagage tot max. 7kg
                            </span>
                          </p>
                        </td>
                        <td style={{ padding: '0.75pt 0.75pt 0.75pt 0.75pt' }}>
                          <p className="MsoNormal" style={{ marginBottom: '0.0001pt' }}>
                            <span
                              style={{
                                fontSize: '10pt',
                                fontFamily: '"Helvetica", "sans-serif"',
                                msoFareastFontFamily: '"Times New Roman"',
                                color: '#333333',
                                msoFareastLanguage: 'NL'
                              }}>
                              gratis
                            </span>
                          </p>
                        </td>
                      </tr>
                      <tr>
                        <td style={{ padding: '0.75pt 0.75pt 0.75pt 0.75pt' }}>
                          <p className="MsoNormal" style={{ marginBottom: '0.0001pt' }}>
                            <span
                              style={{
                                fontSize: '10pt',
                                fontFamily: '"Helvetica", "sans-serif"',
                                msoFareastFontFamily: '"Times New Roman"',
                                color: '#333333',
                                msoFareastLanguage: 'NL'
                              }}>
                              0 kilo - 20 kilo
                            </span>
                          </p>
                        </td>
                        <td style={{ padding: '0.75pt 0.75pt 0.75pt 0.75pt' }}>
                          <p className="MsoNormal" style={{ marginBottom: '0.0001pt' }}>
                            <span
                              style={{
                                fontSize: '10pt',
                                fontFamily: '"Helvetica", "sans-serif"',
                                msoFareastFontFamily: '"Times New Roman"',
                                color: '#333333',
                                msoFareastLanguage: 'NL'
                              }}>
                              ..euro per enkele reis
                            </span>
                          </p>
                        </td>
                      </tr>
                      <tr>
                        <td style={{ padding: '0.75pt 0.75pt 0.75pt 0.75pt' }}>
                          <p className="MsoNormal" style={{ marginBottom: '0.0001pt' }}>
                            <span
                              style={{
                                fontSize: '10pt',
                                fontFamily: '"Helvetica", "sans-serif"',
                                msoFareastFontFamily: '"Times New Roman"',
                                color: '#333333',
                                msoFareastLanguage: 'NL'
                              }}>
                              21 kilo - 30 kilo
                            </span>
                          </p>
                        </td>
                        <td style={{ padding: '0.75pt 0.75pt 0.75pt 0.75pt' }}>
                          <p className="MsoNormal" style={{ marginBottom: '0.0001pt' }}>
                            <span
                              style={{
                                fontSize: '10pt',
                                fontFamily: '"Helvetica", "sans-serif"',
                                msoFareastFontFamily: '"Times New Roman"',
                                color: '#333333',
                                msoFareastLanguage: 'NL'
                              }}>
                              ..euro per enkele reis
                            </span>
                          </p>
                        </td>
                      </tr>
                      <tr>
                        <td style={{ padding: '0.75pt 0.75pt 0.75pt 0.75pt' }}>
                          <p className="MsoNormal" style={{ marginBottom: '0.0001pt' }}>
                            <span
                              style={{
                                fontSize: '10pt',
                                fontFamily: '"Helvetica", "sans-serif"',
                                msoFareastFontFamily: '"Times New Roman"',
                                color: '#333333',
                                msoFareastLanguage: 'NL'
                              }}>
                              31 kilo - 40 kilo
                            </span>
                          </p>
                        </td>
                        <td style={{ padding: '0.75pt 0.75pt 0.75pt 0.75pt' }}>
                          <p className="MsoNormal" style={{ marginBottom: '0.0001pt' }}>
                            <span
                              style={{
                                fontSize: '10pt',
                                fontFamily: '"Helvetica", "sans-serif"',
                                msoFareastFontFamily: '"Times New Roman"',
                                color: '#333333',
                                msoFareastLanguage: 'NL'
                              }}>
                              ..euro per enkele reis
                            </span>
                          </p>
                        </td>
                      </tr>
                      <tr>
                        <td style={{ padding: '0.75pt 0.75pt 0.75pt 0.75pt' }}>
                          <p className="MsoNormal" style={{ marginBottom: '0.0001pt' }}>
                            <span
                              style={{
                                fontSize: '10pt',
                                fontFamily: '"Helvetica", "sans-serif"',
                                msoFareastFontFamily: '"Times New Roman"',
                                color: '#333333',
                                msoFareastLanguage: 'NL'
                              }}>
                              boven 40 kilo
                            </span>
                          </p>
                        </td>
                        <td style={{ padding: '0.75pt 0.75pt 0.75pt 0.75pt' }}>
                          <p className="MsoNormal" style={{ marginBottom: '0.0001pt' }}>
                            <span
                              style={{
                                fontSize: '10pt',
                                fontFamily: '"Helvetica", "sans-serif"',
                                msoFareastFontFamily: '"Times New Roman"',
                                color: '#333333',
                                msoFareastLanguage: 'NL'
                              }}>
                              ..euro extra voor iedere extra kilo (max. 50kg per persoon)
                            </span>
                          </p>
                        </td>
                      </tr>
                      <tr>
                        <td style={{ padding: '0.75pt 0.75pt 0.75pt 0.75pt' }}>
                          <p className="MsoNormal" style={{ marginBottom: '0.0001pt' }}>
                            <span
                              style={{
                                fontSize: '10pt',
                                fontFamily: '"Helvetica", "sans-serif"',
                                msoFareastFontFamily: '"Times New Roman"',
                                color: '#333333',
                                msoFareastLanguage: 'NL'
                              }}>
                              N.B.: 1 bagagestuk mag maximaal 32kg wegen
                            </span>
                          </p>
                        </td>
                        <td style={{ padding: '0.75pt 0.75pt 0.75pt 0.75pt' }} />
                      </tr>
                    </tbody>
                  </table>
                  <p className="MsoNormal" style={{ marginBottom: '0.0001pt' }}>
                    <span style={{ fontSize: '9pt', fontFamily: 'Arial, sans-serif' }}>&nbsp;</span>
                  </p>
                  <table
                    className="MsoNormalTable"
                    border={1}
                    cellSpacing={0}
                    cellPadding={0}
                    width="100%"
                    style={{
                      width: '100%',
                      borderCollapse: 'collapse',
                      msoYftiTbllook: 1184
                    }}>
                    <tbody>
                      <tr>
                        <td style={{ padding: '0.75pt 0.75pt 0.75pt 0.75pt' }}>
                          <p className="MsoNormal" style={{ marginBottom: '0.0001pt' }}>
                            <strong>
                              <span
                                style={{
                                  fontSize: '10pt',
                                  msoBidiFontSize: '11pt',
                                  fontFamily: '"Helvetica", "sans-serif"',
                                  msoFareastFontFamily: '"Times New Roman"',
                                  color: '#333333',
                                  msoFareastLanguage: 'NL'
                                }}>
                                Tarieven op de luchthaven
                              </span>
                            </strong>
                            <span
                              style={{
                                fontSize: '10pt',
                                fontFamily: '"Helvetica", "sans-serif"',
                                msoFareastFontFamily: '"Times New Roman"',
                                color: '#333333',
                                msoFareastLanguage: 'NL'
                              }}
                            />
                          </p>
                        </td>
                      </tr>
                      <tr>
                        <td style={{ padding: '0.75pt 0.75pt 0.75pt 0.75pt' }}>
                          <p className="MsoNormal" style={{ marginBottom: '0.0001pt' }}>
                            <span
                              style={{
                                fontSize: '10pt',
                                fontFamily: '"Helvetica", "sans-serif"',
                                msoFareastFontFamily: '"Times New Roman"',
                                color: '#333333',
                                msoFareastLanguage: 'NL'
                              }}>
                              Handbagage tot max. 7kg
                            </span>
                          </p>
                        </td>
                        <td style={{ padding: '0.75pt 0.75pt 0.75pt 0.75pt' }}>
                          <p className="MsoNormal" style={{ marginBottom: '0.0001pt' }}>
                            <span
                              style={{
                                fontSize: '10pt',
                                fontFamily: '"Helvetica", "sans-serif"',
                                msoFareastFontFamily: '"Times New Roman"',
                                color: '#333333',
                                msoFareastLanguage: 'NL'
                              }}>
                              gratis
                            </span>
                          </p>
                        </td>
                      </tr>
                      <tr>
                        <td style={{ padding: '0.75pt 0.75pt 0.75pt 0.75pt' }}>
                          <p className="MsoNormal" style={{ marginBottom: '0.0001pt' }}>
                            <span
                              style={{
                                fontSize: '10pt',
                                fontFamily: '"Helvetica", "sans-serif"',
                                msoFareastFontFamily: '"Times New Roman"',
                                color: '#333333',
                                msoFareastLanguage: 'NL'
                              }}>
                              0 kilo - 20 kilo
                            </span>
                          </p>
                        </td>
                        <td style={{ padding: '0.75pt 0.75pt 0.75pt 0.75pt' }}>
                          <p className="MsoNormal" style={{ marginBottom: '0.0001pt' }}>
                            <span
                              style={{
                                fontSize: '10pt',
                                fontFamily: '"Helvetica", "sans-serif"',
                                msoFareastFontFamily: '"Times New Roman"',
                                color: '#333333',
                                msoFareastLanguage: 'NL'
                              }}>
                              ..euro per enkele reis
                            </span>
                          </p>
                        </td>
                      </tr>
                      <tr>
                        <td style={{ padding: '0.75pt 0.75pt 0.75pt 0.75pt' }}>
                          <p className="MsoNormal" style={{ marginBottom: '0.0001pt' }}>
                            <span
                              style={{
                                fontSize: '10pt',
                                fontFamily: '"Helvetica", "sans-serif"',
                                msoFareastFontFamily: '"Times New Roman"',
                                color: '#333333',
                                msoFareastLanguage: 'NL'
                              }}>
                              21 kilo - 30 kilo
                            </span>
                          </p>
                        </td>
                        <td style={{ padding: '0.75pt 0.75pt 0.75pt 0.75pt' }}>
                          <p className="MsoNormal" style={{ marginBottom: '0.0001pt' }}>
                            <span
                              style={{
                                fontSize: '10pt',
                                fontFamily: '"Helvetica", "sans-serif"',
                                msoFareastFontFamily: '"Times New Roman"',
                                color: '#333333',
                                msoFareastLanguage: 'NL'
                              }}>
                              ..euro per enkele reis
                            </span>
                          </p>
                        </td>
                      </tr>
                      <tr>
                        <td style={{ padding: '0.75pt 0.75pt 0.75pt 0.75pt' }}>
                          <p className="MsoNormal" style={{ marginBottom: '0.0001pt' }}>
                            <span
                              style={{
                                fontSize: '10pt',
                                fontFamily: '"Helvetica", "sans-serif"',
                                msoFareastFontFamily: '"Times New Roman"',
                                color: '#333333',
                                msoFareastLanguage: 'NL'
                              }}>
                              31 kilo - 40 kilo
                            </span>
                          </p>
                        </td>
                        <td style={{ padding: '0.75pt 0.75pt 0.75pt 0.75pt' }}>
                          <p className="MsoNormal" style={{ marginBottom: '0.0001pt' }}>
                            <span
                              style={{
                                fontSize: '10pt',
                                fontFamily: '"Helvetica", "sans-serif"',
                                msoFareastFontFamily: '"Times New Roman"',
                                color: '#333333',
                                msoFareastLanguage: 'NL'
                              }}>
                              ..euro per enkele reis
                            </span>
                          </p>
                        </td>
                      </tr>
                      <tr>
                        <td style={{ padding: '0.75pt 0.75pt 0.75pt 0.75pt' }}>
                          <p className="MsoNormal" style={{ marginBottom: '0.0001pt' }}>
                            <span
                              style={{
                                fontSize: '10pt',
                                fontFamily: '"Helvetica", "sans-serif"',
                                msoFareastFontFamily: '"Times New Roman"',
                                color: '#333333',
                                msoFareastLanguage: 'NL'
                              }}>
                              boven 40 kilo
                            </span>
                          </p>
                        </td>
                        <td style={{ padding: '0.75pt 0.75pt 0.75pt 0.75pt' }}>
                          <p className="MsoNormal" style={{ marginBottom: '0.0001pt' }}>
                            <span
                              style={{
                                fontSize: '10pt',
                                fontFamily: '"Helvetica", "sans-serif"',
                                msoFareastFontFamily: '"Times New Roman"',
                                color: '#333333',
                                msoFareastLanguage: 'NL'
                              }}>
                              ..euro extra voor iedere extra kilo (max. 50kg per persoon)
                            </span>
                          </p>
                        </td>
                      </tr>
                      <tr>
                        <td style={{ padding: '0.75pt 0.75pt 0.75pt 0.75pt' }}>
                          <p className="MsoNormal" style={{ marginBottom: '0.0001pt' }}>
                            <span
                              style={{
                                fontSize: '10pt',
                                fontFamily: '"Helvetica", "sans-serif"',
                                msoFareastFontFamily: '"Times New Roman"',
                                color: '#333333',
                                msoFareastLanguage: 'NL'
                              }}>
                              N.B.: 1 bagagestuk mag maximaal 32kg wegen
                            </span>
                          </p>
                        </td>
                        <td style={{ padding: '0.75pt 0.75pt 0.75pt 0.75pt' }} />
                      </tr>
                    </tbody>
                  </table>
                  <p className="MsoNormal" style={{ marginBottom: '0.0001pt' }}>
                    <span style={{ fontSize: '9pt', fontFamily: 'Arial, sans-serif' }}>&nbsp;</span>
                  </p>
                  <p className="MsoNormal" style={{ marginBottom: '0.0001pt' }}>
                    <span style={{ fontSize: '9pt', fontFamily: 'Arial, sans-serif' }}>
                      U kunt de bagage bijboeken tot maximaal 5 uur voor vertrek.&nbsp;
                      <br />
                      Via de volgende link kunt u bagage bijkopen:
                    </span>
                    <span style={{ fontSize: '9pt', fontFamily: 'Arial, sans-serif' }}>&nbsp;</span>
                    <span
                      style={{
                        fontSize: '12pt',
                        fontFamily: '"Times New Roman", serif'
                      }}>
                      <a href="http://www.flycorendon.com/" target="_blank">
                        <span
                          style={{
                            fontSize: '9pt',
                            msoBidiFontSize: '11pt',
                            fontFamily: '"Arial", "sans-serif"',
                            color: 'blue'
                          }}>
                          www.flycorendon.com
                        </span>
                      </a>
                    </span>
                  </p>
                  <p className="MsoNormal" style={{ marginBottom: '12pt' }}>
                    <span style={{ fontSize: '9pt', fontFamily: 'Arial, sans-serif' }}>
                      <br />
                      {'{'}' '{'}'}
                    </span>
                    <strong>
                      <em>
                        <span style={{ textDecoration: 'underline' }}>
                          <span
                            style={{
                              fontSize: '9pt',
                              fontFamily: 'Arial, sans-serif'
                            }}>
                            Transavia.com hanteert de volgende tarieven voor het bijboeken van ruimbagage
                          </span>
                        </span>
                      </em>
                    </strong>
                    <span style={{ fontSize: '9pt', fontFamily: 'Arial, sans-serif' }} />
                  </p>
                  <table
                    className="MsoNormalTable"
                    border={1}
                    cellSpacing={0}
                    cellPadding={0}
                    width="100%"
                    style={{
                      width: '100%',
                      borderCollapse: 'collapse',
                      msoYftiTbllook: 1184,
                      msoPaddingAlt: '0cm 0cm 0cm 0cm'
                    }}>
                    <tbody>
                      <tr>
                        <td style={{ padding: '0.75pt 0.75pt 0.75pt 0.75pt' }}>
                          <p className="MsoNormal" style={{ marginBottom: '0.0001pt' }}>
                            <span
                              style={{
                                fontSize: '10pt',
                                fontFamily: '"Helvetica", "sans-serif"',
                                msoFareastFontFamily: '"Times New Roman"',
                                color: '#333333',
                                msoFareastLanguage: 'NL'
                              }}>
                              15 kg ruimbagage
                            </span>
                            <span
                              style={{
                                fontSize: '12pt',
                                fontFamily: '"Times New Roman", "serif"',
                                msoFareastFontFamily: '"Times New Roman"',
                                msoFareastLanguage: 'NL'
                              }}
                            />
                          </p>
                        </td>
                        <td style={{ padding: '0.75pt 0.75pt 0.75pt 0.75pt' }}>
                          <p className="MsoNormal" style={{ marginBottom: '0.0001pt' }}>
                            <span
                              style={{
                                fontSize: '10pt',
                                fontFamily: '"Helvetica", "sans-serif"',
                                msoFareastFontFamily: '"Times New Roman"',
                                color: '#333333',
                                msoFareastLanguage: 'NL'
                              }}>
                              ..euro per enkele reis
                            </span>
                            <span
                              style={{
                                fontSize: '12pt',
                                fontFamily: '"Times New Roman", "serif"',
                                msoFareastFontFamily: '"Times New Roman"',
                                msoFareastLanguage: 'NL'
                              }}
                            />
                          </p>
                        </td>
                      </tr>
                      <tr>
                        <td style={{ padding: '0.75pt 0.75pt 0.75pt 0.75pt' }}>
                          <p className="MsoNormal" style={{ marginBottom: '0.0001pt' }}>
                            <span
                              style={{
                                fontSize: '10pt',
                                fontFamily: '"Helvetica", "sans-serif"',
                                msoFareastFontFamily: '"Times New Roman"',
                                color: '#333333',
                                msoFareastLanguage: 'NL'
                              }}>
                              20 kg ruimbagage
                            </span>
                            <span
                              style={{
                                fontSize: '12pt',
                                fontFamily: '"Times New Roman", "serif"',
                                msoFareastFontFamily: '"Times New Roman"',
                                msoFareastLanguage: 'NL'
                              }}
                            />
                          </p>
                        </td>
                        <td style={{ padding: '0.75pt 0.75pt 0.75pt 0.75pt' }}>
                          <p className="MsoNormal" style={{ marginBottom: '0.0001pt' }}>
                            <span
                              style={{
                                fontSize: '10pt',
                                fontFamily: '"Helvetica", "sans-serif"',
                                msoFareastFontFamily: '"Times New Roman"',
                                color: '#333333',
                                msoFareastLanguage: 'NL'
                              }}>
                              ..euro per enkele reis
                            </span>
                            <span
                              style={{
                                fontSize: '12pt',
                                fontFamily: '"Times New Roman", "serif"',
                                msoFareastFontFamily: '"Times New Roman"',
                                msoFareastLanguage: 'NL'
                              }}
                            />
                          </p>
                        </td>
                      </tr>
                      <tr>
                        <td style={{ padding: '0.75pt 0.75pt 0.75pt 0.75pt' }}>
                          <p className="MsoNormal" style={{ marginBottom: '0.0001pt' }}>
                            <span
                              style={{
                                fontSize: '10pt',
                                fontFamily: '"Helvetica", "sans-serif"',
                                msoFareastFontFamily: '"Times New Roman"',
                                color: '#333333',
                                msoFareastLanguage: 'NL'
                              }}>
                              25 kg ruimbagage
                            </span>
                            <span
                              style={{
                                fontSize: '12pt',
                                fontFamily: '"Times New Roman", "serif"',
                                msoFareastFontFamily: '"Times New Roman"',
                                msoFareastLanguage: 'NL'
                              }}
                            />
                          </p>
                        </td>
                        <td style={{ padding: '0.75pt 0.75pt 0.75pt 0.75pt' }}>
                          <p className="MsoNormal" style={{ marginBottom: '0.0001pt' }}>
                            <span
                              style={{
                                fontSize: '10pt',
                                fontFamily: '"Helvetica", "sans-serif"',
                                msoFareastFontFamily: '"Times New Roman"',
                                color: '#333333',
                                msoFareastLanguage: 'NL'
                              }}>
                              ..euro per enkele reis
                            </span>
                            <span
                              style={{
                                fontSize: '12pt',
                                fontFamily: '"Times New Roman", "serif"',
                                msoFareastFontFamily: '"Times New Roman"',
                                msoFareastLanguage: 'NL'
                              }}
                            />
                          </p>
                        </td>
                      </tr>
                      <tr>
                        <td style={{ padding: '0.75pt 0.75pt 0.75pt 0.75pt' }}>
                          <p className="MsoNormal" style={{ marginBottom: '0.0001pt' }}>
                            <span
                              style={{
                                fontSize: '10pt',
                                fontFamily: '"Helvetica", "sans-serif"',
                                msoFareastFontFamily: '"Times New Roman"',
                                color: '#333333',
                                msoFareastLanguage: 'NL'
                              }}>
                              30 kg ruimbagage
                            </span>
                            <span
                              style={{
                                fontSize: '12pt',
                                fontFamily: '"Times New Roman", "serif"',
                                msoFareastFontFamily: '"Times New Roman"',
                                msoFareastLanguage: 'NL'
                              }}
                            />
                          </p>
                        </td>
                        <td style={{ padding: '0.75pt 0.75pt 0.75pt 0.75pt' }}>
                          <p className="MsoNormal" style={{ marginBottom: '0.0001pt' }}>
                            <span
                              style={{
                                fontSize: '10pt',
                                fontFamily: '"Helvetica", "sans-serif"',
                                msoFareastFontFamily: '"Times New Roman"',
                                color: '#333333',
                                msoFareastLanguage: 'NL'
                              }}>
                              ..euro per enkele reis
                            </span>
                            <span
                              style={{
                                fontSize: '12pt',
                                fontFamily: '"Times New Roman", "serif"',
                                msoFareastFontFamily: '"Times New Roman"',
                                msoFareastLanguage: 'NL'
                              }}
                            />
                          </p>
                        </td>
                      </tr>
                      <tr>
                        <td style={{ padding: '0.75pt 0.75pt 0.75pt 0.75pt' }}>
                          <p className="MsoNormal" style={{ marginBottom: '0.0001pt' }}>
                            <span
                              style={{
                                fontSize: '10pt',
                                fontFamily: '"Helvetica", "sans-serif"',
                                msoFareastFontFamily: '"Times New Roman"',
                                color: '#333333',
                                msoFareastLanguage: 'NL'
                              }}>
                              40 kg ruimbagage
                            </span>
                            <span
                              style={{
                                fontSize: '12pt',
                                fontFamily: '"Times New Roman", "serif"',
                                msoFareastFontFamily: '"Times New Roman"',
                                msoFareastLanguage: 'NL'
                              }}
                            />
                          </p>
                        </td>
                        <td style={{ padding: '0.75pt 0.75pt 0.75pt 0.75pt' }}>
                          <p className="MsoNormal" style={{ marginBottom: '0.0001pt' }}>
                            <span
                              style={{
                                fontSize: '10pt',
                                fontFamily: '"Helvetica", "sans-serif"',
                                msoFareastFontFamily: '"Times New Roman"',
                                color: '#333333',
                                msoFareastLanguage: 'NL'
                              }}>
                              ..euro per enkele reis
                            </span>
                            <span
                              style={{
                                fontSize: '12pt',
                                fontFamily: '"Times New Roman", "serif"',
                                msoFareastFontFamily: '"Times New Roman"',
                                msoFareastLanguage: 'NL'
                              }}
                            />
                          </p>
                        </td>
                      </tr>
                      <tr>
                        <td style={{ padding: '0.75pt 0.75pt 0.75pt 0.75pt' }}>
                          <p className="MsoNormal" style={{ marginBottom: '0.0001pt' }}>
                            <span
                              style={{
                                fontSize: '10pt',
                                fontFamily: '"Helvetica", "sans-serif"',
                                msoFareastFontFamily: '"Times New Roman"',
                                color: '#333333',
                                msoFareastLanguage: 'NL'
                              }}>
                              50 kg ruimbagage
                            </span>
                            <span
                              style={{
                                fontSize: '12pt',
                                fontFamily: '"Times New Roman", "serif"',
                                msoFareastFontFamily: '"Times New Roman"',
                                msoFareastLanguage: 'NL'
                              }}
                            />
                          </p>
                        </td>
                        <td style={{ padding: '0.75pt 0.75pt 0.75pt 0.75pt' }}>
                          <p className="MsoNormal" style={{ marginBottom: '0.0001pt' }}>
                            <span
                              style={{
                                fontSize: '10pt',
                                fontFamily: '"Helvetica", "sans-serif"',
                                msoFareastFontFamily: '"Times New Roman"',
                                color: '#333333',
                                msoFareastLanguage: 'NL'
                              }}>
                              ..euro per enkele reis
                            </span>
                            <span
                              style={{
                                fontSize: '12pt',
                                fontFamily: '"Times New Roman", "serif"',
                                msoFareastFontFamily: '"Times New Roman"',
                                msoFareastLanguage: 'NL'
                              }}
                            />
                          </p>
                        </td>
                      </tr>
                      <tr>
                        <td style={{ padding: '0.75pt 0.75pt 0.75pt 0.75pt' }}>
                          <p className="MsoNormal" style={{ marginBottom: '0.0001pt' }}>
                            <span
                              style={{
                                fontSize: '10pt',
                                fontFamily: '"Helvetica", "sans-serif"',
                                msoFareastFontFamily: '"Times New Roman"',
                                color: '#333333',
                                msoFareastLanguage: 'NL'
                              }}>
                              Meer gewicht/Overbagage*
                            </span>
                            <span
                              style={{
                                fontSize: '12pt',
                                fontFamily: '"Times New Roman", "serif"',
                                msoFareastFontFamily: '"Times New Roman"',
                                msoFareastLanguage: 'NL'
                              }}
                            />
                          </p>
                        </td>
                        <td style={{ padding: '0.75pt 0.75pt 0.75pt 0.75pt' }}>
                          <p className="MsoNormal" style={{ marginBottom: '0.0001pt' }}>
                            <span
                              style={{
                                fontSize: '10pt',
                                fontFamily: '"Helvetica", "sans-serif"',
                                msoFareastFontFamily: '"Times New Roman"',
                                color: '#333333',
                                msoFareastLanguage: 'NL'
                              }}>
                              ..euro per kg per enkele reis
                            </span>
                            <span
                              style={{
                                fontSize: '12pt',
                                fontFamily: '"Times New Roman", "serif"',
                                msoFareastFontFamily: '"Times New Roman"',
                                msoFareastLanguage: 'NL'
                              }}
                            />
                          </p>
                        </td>
                      </tr>
                      <tr>
                        <td style={{ padding: '0.75pt 0.75pt 0.75pt 0.75pt' }}>
                          <p className="MsoNormal" style={{ marginBottom: '0.0001pt' }}>
                            <span
                              style={{
                                fontSize: '10pt',
                                fontFamily: '"Helvetica", "sans-serif"',
                                msoFareastFontFamily: '"Times New Roman"',
                                color: '#333333',
                                msoFareastLanguage: 'NL'
                              }}>
                              *Maximaal 32 kg per bagagestuk en maximaal 50kg per persoon
                            </span>
                            <span
                              style={{
                                fontSize: '12pt',
                                fontFamily: '"Times New Roman", "serif"',
                                msoFareastFontFamily: '"Times New Roman"',
                                msoFareastLanguage: 'NL'
                              }}
                            />
                          </p>
                        </td>
                        <td
                          style={{
                            border: 'none',
                            borderRight: 'inset 1pt',
                            padding: '0.75pt 0.75pt 0.75pt 0.75pt'
                          }}
                        />
                      </tr>
                      <tr>
                        <td style={{ padding: '0.75pt 0.75pt 0.75pt 0.75pt' }}>
                          <p className="MsoNormal" style={{ marginBottom: '12pt' }}>
                            <span
                              style={{
                                fontSize: '10pt',
                                fontFamily: '"Helvetica", "sans-serif"',
                                msoFareastFontFamily: '"Times New Roman"',
                                color: '#333333',
                                msoFareastLanguage: 'NL'
                              }}>
                              Maximaal 5 stuks per persoon.
                            </span>
                            <span
                              style={{
                                fontSize: '12pt',
                                fontFamily: '"Times New Roman", "serif"',
                                msoFareastFontFamily: '"Times New Roman"',
                                msoFareastLanguage: 'NL'
                              }}
                            />
                          </p>
                        </td>
                        <td
                          style={{
                            borderTop: 'none',
                            borderLeft: 'none',
                            borderBottom: 'inset 1pt',
                            borderRight: 'inset 1pt',
                            padding: '0.75pt 0.75pt 0.75pt 0.75pt'
                          }}
                        />
                      </tr>
                    </tbody>
                  </table>
                  <p className="MsoNormal" style={{ marginBottom: '0.0001pt' }}>
                    <span style={{ fontSize: '9pt', fontFamily: 'Arial, sans-serif' }}>&nbsp;</span>
                  </p>
                  <p className="MsoNormal" style={{ marginBottom: '0.0001pt' }}>
                    <span style={{ fontSize: '9pt', fontFamily: 'Arial, sans-serif' }}>
                      Indien u ruimbagage heeft bijgeboekt bij Transavia.com, ontvangt u hiervan een boekingsbevestiging van Transavia.com via uw
                      e-mail.&nbsp;
                    </span>
                  </p>
                  <p className="MsoNormal" style={{ marginBottom: '0.0001pt' }}>
                    <span style={{ fontSize: '9pt', fontFamily: 'Arial, sans-serif' }}>
                      Deze dient u te overleggen bij de check-in.
                      <br />
                    </span>
                    <span style={{ fontFamily: 'Arial, sans-serif', fontSize: '9pt' }}>
                      U kunt de bagage bijboeken tot maximaal 4 uur voor vertrek.&nbsp;
                      <br />
                    </span>
                    <span style={{ fontSize: '9pt', fontFamily: 'Arial, sans-serif' }}>Via de volgende link kunt u bagage bijkopen:</span>
                    <span style={{ fontSize: '9pt', fontFamily: 'Arial, sans-serif' }}>&nbsp;</span>
                    <span style={{ fontSize: '9pt', fontFamily: 'Arial, sans-serif' }}>
                      <a href="http://selfservice.transavia.com/" target="_blank">
                        <span style={{ msoBidiFontSize: '11pt', color: 'blue' }}>http://selfservice.transavia.com</span>
                      </a>
                    </span>
                  </p>
                </div>
              </div>
            </div>
            <div className="card">
              <div className="card-header">
                <h2 className="mb-0">
                  <span>Bagage (ruim) - Vervoeren van een fiets</span>
                </h2>
              </div>
              <div className="collapse show">
                <div>
                  <p>Aanvraag voor het vervoeren van een fiets gaat uitsluitend per mail (klantenservice@triptime.nl)</p>
                  <p>Kosten per fiets: ca. 40 EUR. Dit tarief is ook van toepassing voor een fiets in een fietskoffer.</p>
                  <p>Zo moet u de fiets aanleveren:</p>
                  <p>
                    * Bijna lege banden <br />* Opgeklapte of gedemonteerde pedalen <br />* Stuur gedraaid in lengterichting <br />* U mag geen
                    fietspompen met CO2 gevulde patronen vervoeren
                    <br />* Bij voorkeur in een fietsdoos, meer informatie te verkrijgen bij de fietshandel
                  </p>
                </div>
              </div>
            </div>
            <div className="card">
              <div className="card-header">
                <h2 className="mb-0">
                  <span>Bagage (ruim) - Hoeveel bagage mag ik meenemen?</span>
                </h2>
              </div>
              <div className="collapse show">
                <div>
                  <div>Dit verschilt per luchtvaartmaatschappij.</div>
                  <div>
                    <br />
                  </div>
                  <div>Pegasus</div>
                  <div>
                    Standaard inbegrepen in uw ticketprijs heeft u recht op per persoon (volwassene en kind) 20kg ruimbagage. Extra bagage vooraf
                    bijkopen is niet mogelijk.
                  </div>
                  <div>Voor baby's tot 2 jaar geldt een bagagevrijdom van 10 kg incl. buggy of maxicosy.</div>
                  <div>
                    <br />
                  </div>
                  <div>Onur Air&nbsp;</div>
                  <div>
                    Standaard inbegrepen in uw ticketprijs heeft u recht op per persoon (volwassene en kind) 20kg ruimbagage. Extra bagage vooraf
                    bijkopen is niet mogelijk.
                  </div>
                  <div>Voor baby's tot 2 jaar geldt een bagagevrijdom van 10 kg incl. buggy of maxicosy.</div>
                  <div>
                    <br />
                  </div>
                  <div>SunExpress</div>
                  <div>
                    Standaard inbegrepen in uw ticketprijs heeft u recht op per persoon (volwassene en kind) 20kg ruimbagage. Als uitzondering bij
                    SunExpress van en naar de bestemmingen Izmir en Istanbul Sabiha Gokcen is dit 30kg per persoon. Extra bagage vooraf bijkopen is
                    niet mogelijk.
                  </div>
                  <div>Voor baby's tot 2 jaar geldt een bagagevrijdom van 10 kg incl. buggy of maxicosy.</div>
                  <div>
                    <br />
                  </div>
                  <div>Transavia (vluchten vanaf 1 april 2014)</div>
                  <div>
                    Standaard inbegrepen in uw ticketprijs heeft u recht op per persoon (volwassene en kind) 10kg ruimbagage. Extra bagage vooraf
                    bijkopen is mogelijk via deze link http://selfservice.transavia.com
                  </div>
                  <div>Voor baby's tot 2 jaar geldt een bagagevrijdom van 10 kg incl. buggy of maxicosy.</div>
                  <div>Let op: Bij vluchten van Transavia mag u per passagier één koffer van 20kg vervoeren.</div>
                  <div>
                    <br />
                  </div>
                  <div>Corendon (vluchten vanaf 1 april 2014)</div>
                  <div>
                    Standaard inbegrepen in uw ticketprijs heeft u geen recht op ruimbagage. Extra bagage vooraf bijkopen is mogelijk via deze link
                    www.flycorendon.com&nbsp;
                  </div>
                  <div>Voor baby's tot 2 jaar is er geen bagagevrijdom. Het vervoeren van een buggy of maxicosy is wel toegestaan.</div>
                  <div>
                    <br />
                  </div>
                  <div>Kan ik extra bagage bijkopen?</div>
                  <div>Deze mogelijkheid word enkel door Transavia en Corendon aangeboden.&nbsp;</div>
                  <div>
                    <br />
                  </div>
                  <div>Transavia</div>
                  <div>U dient deze link te raadplegen http://selfservice.transavia.com</div>
                  <div>
                    <br />
                  </div>
                  <div>Corendon (vluchten vanaf 1 april 2014)</div>
                  <div>U dient deze link te raadplegen</div>
                  <div>www.flycorendon.com</div>
                  <div>
                    <br />
                  </div>
                  <div>
                    <br />
                  </div>
                  <div>
                    Bij andere maatschappijen is het helaas nog niet mogelijk om extra bagage vooraf in te kopen. Deze mogelijkheid word enkel
                    aangeboden bij boekingen die rechtstreeks bij de luchtvaartmaatschappij geplaatst worden.
                  </div>
                  <div>
                    Extra bagage dient u voor het inchecken af te betalen op de luchthaven bij de betreffende servicebalie van de
                    luchtvaartmaatschappij. De kosten hiervan kunnen per luchtvaartmaatschappij variëren, u dient rekening te houden met ca. 8 &nbsp;-
                    10 EUR per 1kg aan overbagage. &nbsp;
                  </div>
                  <div>
                    <br />
                  </div>
                </div>
              </div>
            </div>
            <div className="card">
              <div className="card-header">
                <h2 className="mb-0">
                  <span>Bagage (algemeen) - Medicijnen mee</span>
                </h2>
              </div>
              <div className="collapse show">
                <div>
                  <p>
                    Vaste medische bagage mag u gewoon meenemen. U moet dan de medicijnen of dieetsupplementen wel apart aanbieden bij de
                    handbagagecontrole. U hoeft dit niet in een doorzichtige zak te vervoeren en er geldt geen beperking op de hoeveelheid, mits deze
                    binnen de toegestane handbagagevrijdom valt. Neem een Engelse versie van het recept van uw medicijnen mee voor het geval douane-
                    of veiligheidsbeambten vragen stellen over uw medicijnen of injectienaalden.Vloeibare medische bagage moet worden doorgegeven aan
                    de luchtvaartmaatschappij. U dient dan in bezit te zijn van een doktersverklaring. Gelieve dit ruim voor vertrek te melden aan
                    Triptime.nl.&nbsp;
                  </p>
                </div>
              </div>
            </div>
            <div className="card">
              <div className="card-header">
                <h2 className="mb-0">
                  <span>Bagage (algemeen) - Mag ik een kinderwagen meenemen?</span>
                </h2>
              </div>
              <div className="collapse show">
                <div>
                  U mag alleen een inklapbare lichtgewicht paraplubuggy (5-7 kg) tot aan het vliegtuig meenemen. Alle overige buggy's en kinderwagens
                  moet u bij de check-in balie als ruimbagage inchecken. Het is daarom handig om een draagzak mee te nemen voor gebruik op de
                  luchthaven.
                </div>
              </div>
            </div>
            <div className="card">
              <div className="card-header">
                <h2 className="mb-0">
                  <span>Bagage (algemeen) - Welke voorwerpen mag ik nooit meenemen als bagage</span>
                </h2>
              </div>
              <div className="collapse show">
                <div>
                  <div>
                    - Explosieven (bijvoorbeeld zoals ontstekingsinrichtingen, lonten, granaten, mijnen en springstoffen)
                    <br />
                    <br />
                  </div>
                  <div>
                    - Gassen: propaan en butaan
                    <br />
                    <br />
                  </div>
                  <div>
                    - Ontvlambare vloeistoffen (bijvoorbeeld benzine en methanol)
                    <br />
                    <br />
                  </div>
                  <div>- Ontvlambare vaste stoffen en reactieven stoffen (bijvoorbeeld magnesium, aanmaakblokjes, vuurwerk en seinvuur)</div>
                  <div>
                    <br />
                  </div>
                  <div>- Oxidatiemiddelen en organische peroxiden (bijvoorbeeld bleekmiddel en reparatiekits voor carrosserieën)</div>
                  <div>
                    <br />
                  </div>
                  <div>
                    - Toxische of besmettelijke stoffen (bijvoorbeeld rattengif en besmet bloed)
                    <br />
                    <br />
                  </div>
                  <div>
                    - Radioactief materiaal (bijvoorbeeld medische of commerciële isotopen)
                    <br />
                    <br />
                  </div>
                  <div>
                    - Corrosief materiaal (bijvoorbeeld kwik en voertuigbatterijen)
                    <br />
                    <br />
                  </div>
                  <div>- Onderdelen van voertuigbrandstofsystemen die brandstof bevatten</div>
                  <div>
                    <br />
                  </div>
                </div>
              </div>
            </div>
            <div className="card">
              <div className="card-header">
                <h2 className="mb-0">
                  <span>Bagage (algemeen) - golfuitrusting meenemen.</span>
                </h2>
              </div>
              <div className="collapse show">
                <div>
                  <span style={{ fontFamily: '"Times New Roman"', fontSize: '12pt' }} className="Apple-style-span">
                    <div
                      style={{
                        backgroundImage: 'none',
                        borderBottomColor: '#4986a2',
                        borderBottomWidth: 0,
                        borderBottomStyle: 'solid',
                        borderLeftColor: '#4986a2',
                        borderLeftWidth: 0,
                        borderLeftStyle: 'solid',
                        paddingBottom: 0,
                        marginTop: 0,
                        marginRight: 0,
                        marginBottom: 0,
                        marginLeft: 0,
                        paddingLeft: 4,
                        paddingRight: 0,
                        borderTopColor: '#4986a2',
                        borderTopWidth: 0,
                        borderTopStyle: 'solid',
                        borderRightColor: '#4986a2',
                        borderRightWidth: 0,
                        borderRightStyle: 'solid',
                        paddingTop: 0
                      }}>
                      Aan golfuitrusting mag er maximaal 15 kilo meegenomen worden. Echter kan dit per maatschappij verschillen. Voor meer informatie
                      neem contact op.
                      <br />
                      <br />
                      De kosten voor de sportuitrusting bij Transavia.com (golftas, wintersport-, vis- en duik uitrusting) bedragen 25.- per enkele
                      reis
                    </div>
                    <div
                      style={{
                        backgroundImage: 'none',
                        borderBottomColor: '#4986a2',
                        borderBottomWidth: 0,
                        borderBottomStyle: 'solid',
                        borderLeftColor: '#4986a2',
                        borderLeftWidth: 0,
                        borderLeftStyle: 'solid',
                        paddingBottom: 0,
                        marginTop: 0,
                        marginRight: 0,
                        marginBottom: 0,
                        marginLeft: 0,
                        paddingLeft: 4,
                        paddingRight: 0,
                        borderTopColor: '#4986a2',
                        borderTopWidth: 0,
                        borderTopStyle: 'solid',
                        borderRightColor: '#4986a2',
                        borderRightWidth: 0,
                        borderRightStyle: 'solid',
                        paddingTop: 0
                      }}>
                      <br />
                    </div>
                    <div
                      style={{
                        backgroundImage: 'none',
                        borderBottomColor: '#4986a2',
                        borderBottomWidth: 0,
                        borderBottomStyle: 'solid',
                        borderLeftColor: '#4986a2',
                        borderLeftWidth: 0,
                        borderLeftStyle: 'solid',
                        paddingBottom: 0,
                        marginTop: 0,
                        marginRight: 0,
                        marginBottom: 0,
                        marginLeft: 0,
                        paddingLeft: 4,
                        paddingRight: 0,
                        borderTopColor: '#4986a2',
                        borderTopWidth: 0,
                        borderTopStyle: 'solid',
                        borderRightColor: '#4986a2',
                        borderRightWidth: 0,
                        borderRightStyle: 'solid',
                        paddingTop: 0
                      }}>
                      <br />
                    </div>
                  </span>
                </div>
              </div>
            </div>
            <div className="card">
              <div className="card-header">
                <h2 className="mb-0">
                  <span>Bagage (algemeen) - Mag ik mijn huisdier meenemen ?</span>
                </h2>
              </div>
              <div className="collapse show">
                <div>
                  <strong> </strong>
                  <p>Een huisdier dient uiterlijk één week voor vertrek te worden aangevraagd/aangemeld .</p>
                  <p>
                    <strong>&nbsp;Pegasus</strong> <br />
                    Huisdieren betreffen uitsluitend katten, honden en vogels en mogen mee in de cabine in door de passagier meegebrachte
                    transporttas/kennel van 55 cm x 40 cm x 20 cm (LxBxH).
                  </p>
                  <div>
                    <div>
                      Passagiers moeten beschikken over de nodige gezondheidspapieren, inentingsgegevens en identiteitskaart van het huisdier dat met
                      hen meereist. Passagiers die binnen de EU reizen, moeten een paspoort hebben voor hun huisdier.
                    </div>
                    <div>
                      Huisdieren (zoals honden of katten) die naar de Europese Unie (EU) reizen vanuit derdewereldlanden zoals Turkije moeten een
                      inactieve inenting tegen hondsdolheid krijgen en een bloedafname voor titratie van anti-stoffen minstens 30 dagen na de
                      inenting. &nbsp; De datum en geldigheidsperiode van deze procedure moeten worden opgenomen in een vaccinatieboekje.
                    </div>
                    <div>
                      Het totale gewicht van het huisdier en de kooi mag niet meer dan 5 kg bedragen. Indien een huisdier meer dan 5 kg weegt, moet
                      het meereizen in de laadruimte van het vliegtuig. Het is echter mogelijk dat in de romp van sommige van onze vliegtuigen geen
                      ruimte is voor huisdieren. Om huisdieren in de laadruimte mee te laten reizen, moet Pegasus Airlines eerst de goedkeuring
                      verkrijgen van het Ground Operations Directorate.
                    </div>
                    <div>In één kooi mogen maximaal twee honden (moeder en puppy) of twee katten (moeder en klein poesje) worden getransporteerd</div>
                    <div>&nbsp; &nbsp; * In de cabine worden in totaal twee huisdieren toegelaten.</div>
                    <div>&nbsp; &nbsp; * De meereizende huisdieren moeten gezond, ongevaarlijk, schoon en reukloos zijn.</div>
                    <div>
                      <br />
                    </div>
                    <div>
                      Indien het dier nerveus, agressief of ziek lijkt te zijn, kan het grondpersoneel het de toegang tot het vliegtuig weigeren.
                    </div>
                  </div>
                  <div>
                    <br />
                  </div>
                  <div>
                    <div>De kosten voor het vervoeren in de cabine bedragen:</div>
                    <div>- 25,- aanvraagkosten Triptime per enkele reis</div>
                    <div>- 40,- vervoerkosten per enkele reis, die op de luchthaven dienen te worden voldaan&nbsp;</div>
                  </div>
                  <div>
                    <br />
                  </div>
                  <div>
                    <strong>
                      Sunexpress
                      <br />
                    </strong>
                    De maximale afmetingen van de transporttas of cabinekennel zijn 55x40x20cm (lxbxh). Het gewicht van uw huisdier inclusief de
                    tas/kennel is maximaal 6 kilo.
                  </div>
                  <div>
                    <br />
                  </div>
                  <div>Voor vervoer van een klein huisdier in de cabine&nbsp;geldt een tarief:</div>
                  <div>- 25,- aanvraagkosten Triptime per enkele reis</div>
                  <div>- 40,- vervoerkosten per enkele reis, die op de luchthaven dienen te worden voldaan</div>
                  <div>&nbsp;</div>
                  <div>
                    <strong>
                      Transavia
                      <br />
                    </strong>
                    De maximale afmetingen van de transporttas of cabinekennel zijn 47x30x27cm (LxBxH). Het gewicht van uw huisdier inclusief de
                    tas/kennel is maximaal 10&nbsp;kilo.&nbsp;
                  </div>
                  <div>
                    <br />
                  </div>
                  <div>De kosten voor het vervoeren in de cabine bedragen:</div>
                  <div>- 25,- aanvraagkosten Triptime per enkele reis</div>
                  <div>- 40,- vervoerkosten per enkele reis, die op de luchthaven dienen te worden voldaan&nbsp;</div>
                  <div>
                    <p>Ook voor huisdieren zijn er reis documenten nodig zoals een dierenpaspoort. Informeer hiernaar bij boeking.</p>
                    <br />
                  </div>
                </div>
              </div>
            </div>
            <div className="card">
              <div className="card-header">
                <h2 className="mb-0">
                  <span>Bagage (hand) - Mag ik een Maxi-Cosi meenemen aan boord?</span>
                </h2>
              </div>
              <div className="collapse show">
                <div>
                  <p>
                    U mag voor een baby tot en met 23 maanden een Maxi-Cosi of paraplubuggy (maximaal 5 kilogram) meenemen tot aan het vliegtuig. U
                    neemt de baby tijdens de vlucht op schoot. Per volwassene mag slechts één baby op schoot meereizen. Er zijn geen wiegjes aan
                    boord.
                  </p>
                  <p>
                    Een uitzondering geldt als u voor uw baby een stoel heeft gekocht, dan kunt u de Maxi-Cosi mee aan boord nemen; een medewerker zal
                    deze aan de stoel bevestigen. De Maxi-Cosi moet wel door de ANWB of TNO goedgekeurd zijn en een maximale afmeting hebben van 41 cm
                    breed en 57 cm lang.
                  </p>
                </div>
              </div>
            </div>
            <div className="card">
              <div className="card-header">
                <h2 className="mb-0">
                  <span>Bagage (hand) - Mag ik insuline en bijbehorende naalden in mijn handbagage meenemen?</span>
                </h2>
              </div>
              <div className="collapse show">
                <div>
                  <div>
                    Ja dat mag, als deze goed verpakt zijn. Medicijnen die koel bewaard moeten worden, kunt u het best vervoeren in koelzakken of
                    vacuümflacons die vallen binnen de handbagageregels.
                  </div>
                  <div>
                    Neem een Engelse versie van het recept van uw medicijnen mee voor het geval douane- of veiligheidsbeambten vragen stellen over uw
                    medicijnen of injectie naalden.
                  </div>
                  <div>
                    <br />
                  </div>
                </div>
              </div>
            </div>
            <div className="card">
              <div className="card-header">
                <h2 className="mb-0">
                  <span>Bagage (hand) - Kan ik nog toiletartikelen als handbagage meenemen?</span>
                </h2>
              </div>
              <div className="collapse show">
                <div>
                  Nee, dit is niet meer mogelijk door de geldende regels voor vloeistoffen in uw handbagage. Uw toiletartikelen zullen als ruimbagage
                  worden ingecheckt.
                </div>
              </div>
            </div>
            <div className="card">
              <div className="card-header">
                <h2 className="mb-0">
                  <span>Bagage (hand) - Reizen met alleen handbagage</span>
                </h2>
              </div>
              <div className="collapse show">
                <div>
                  Als u alleen met handbagage reist en u uw instapkaart via online check-in (via de luchtvaartmaatschappij) heeft afgedrukt, kunt u
                  meteen doorlopen naar de gate.
                </div>
              </div>
            </div>
            <div className="card">
              <div className="card-header">
                <h2 className="mb-0">
                  <span>Bagage (hand) - Vloeibare middelen meenemen.</span>
                </h2>
              </div>
              <div className="collapse show">
                <div>
                  <p>
                    Iedere passagier, ook een baby, mag&nbsp;als handbagage&nbsp; vloeistoffen meenemen aan boord. De vloeistoffen moeten echter
                    wel&nbsp;in aparte verpakkingen zitten met een maximum inhoud&nbsp;van 100 ml per verpakking. De verpakkingen met vloeibare inhoud
                    moeten in één transparante plastic zak met een maximale inhoud van&nbsp;1&nbsp;L kunnen worden vervoerd.
                    <br />
                  </p>
                </div>
              </div>
            </div>
            <div className="card">
              <div className="card-header">
                <h2 className="mb-0">
                  <span>Bagage (hand) - handbagagecontrole op de luchthaven</span>
                </h2>
              </div>
              <div className="collapse show">
                <div>
                  Bij de beveiligingscontrole gaat alle handbagage op een lopende band door een scanner. Hier wordt ook gecontroleerd of u
                  vloeistoffen in uw handbagage hebt. Als u vloeistoffen mee wilt nemen in uw handbagage, dan moet u voor vertrek &nbsp;thuis of op de
                  luchthaven &nbsp;alles verpakken volgens de voorschriften
                </div>
              </div>
            </div>
            <div className="card">
              <div className="card-header">
                <h2 className="mb-0">
                  <span>Bagage (hand) - Hoeveel bagage mag ik meenemen?</span>
                </h2>
              </div>
              <div className="collapse show">
                <div>
                  <div>De toegestane handbagage bij Pegasus per persoon is:</div>
                  <div>* 1 handtas met de maximale afmetingen van&nbsp;</div>
                  <div>55 cm x 40 cm x 20 cm en een maximaal gewicht van 8 kilogram</div>
                  <div>* 1 jas, een paraplu en leesmateriaal voor de vlucht</div>
                  <div>
                    <br />
                  </div>
                  <div>De toegestane handbagage bij Onur Air per persoon is:</div>
                  <div>* 1 handtas met de maximale afmetingen van&nbsp;</div>
                  <div>55 cm x 40 cm x 20 cm en een maximaal gewicht van 8 kilogram</div>
                  <div>* 1 jas, een paraplu en leesmateriaal voor de vlucht</div>
                  <div>
                    <br />
                  </div>
                  <div>De toegestane handbagage bij Sunexpress per persoon is:</div>
                  <div>* 1 handtas met de maximale afmetingen van&nbsp;</div>
                  <div>55 cm x 40 cm x 20 cm en een maximaal gewicht van 6 kilogram</div>
                  <div>* 1 jas, een paraplu en leesmateriaal voor de vlucht</div>
                  <div>
                    <br />
                  </div>
                  <div>De toegestane handbagage bij Transavia is per persoon :</div>
                  <div>* 1 handtas met de maximale afmetingen van&nbsp;</div>
                  <div>55c m x 35 cm x 25 cm en een maximaal gewicht van 5 kilogram</div>
                  <div>* 1 jas, een paraplu en leesmateriaal voor de vlucht</div>
                  <div>
                    <br />
                  </div>
                  <div>De toegestane handbagage bij Corendon per persoon is:</div>
                  <div>* 1 handtas met de maximale afmetingen van&nbsp;</div>
                  <div>55 cm x 40 cm x 20 cm en een maximaal gewicht van 7 kilogram</div>
                  <div>* 1 jas, een paraplu en leesmateriaal voor de vlucht</div>
                  <div>
                    <br />
                  </div>
                </div>
              </div>
            </div>
            <div className="card">
              <div className="card-header">
                <h2 className="mb-0">
                  <span>Bereikbaarheid</span>
                </h2>
              </div>
              <div className="collapse show">
                <div>
                  Triptime
                  <div>
                    <a href="http://www.triptime.nl/">www.triptime.nl</a>
                    <br />
                    <br />
                    <div>Westzijde 118</div>
                    <div>1506 EJ, Zaandam</div>
                    <br />
                    Emailadres:
                    <a href="mailto:info@triptime.nl">info@triptime.nl</a> (helpdesk)
                    <br />
                    <br />
                  </div>
                  <div>Tel: +31 (0)75 8200 115&nbsp;(ma t/m vrij van 10:00 tot 18:00 uur)</div>
                  <div>Houdt u graag uw boekingsnummer bij de hand voor een snellere service.</div>
                  <div>
                    <br />
                  </div>
                  <div>
                    Fax: +31 (0)75 8200 116&nbsp; <br />
                    <br />
                    <br />
                    <br />
                  </div>
                </div>
              </div>
            </div>
            <div className="card">
              <div className="card-header">
                <h2 className="mb-0">
                  <span>Betalen - Hoe kan ik betalen?</span>
                </h2>
              </div>
              <div className="collapse show">
                <div>
                  Betalingen bij Triptime.nl geschieden door middel van iDeal. Bij de laatste stap van de boeking komt u terecht op de
                  betalingspagina. Hier kunt u uw bank selecteren en daarmee uw betaling afronden.
                  <div>
                    <br />
                  </div>
                  <div>
                    <br />
                    <div>Het rekeningnummer:</div>
                    <div>61.81.97.133 t.n.v. Triptime</div>
                    <div>ABN-AMRO te Zaandam</div>
                    <div>
                      <br />
                    </div>
                    <div>
                      Zodra de betaling bij ons op de rekening is bijgeschreven ontvangt u hier automatisch een bevestiging van en daarmee ook de
                      E-tickets.&nbsp;
                    </div>
                    <div>
                      <br />
                    </div>
                    <div>Betalingen met creditcard is bij ons niet mogelijk.</div>
                    <div>
                      <div>
                        <br />
                      </div>
                      <div>Voor betalingen vanuit het buitenland:</div>
                      <div>
                        <br />
                      </div>
                      <div>IBAN: NL24 ABNA 0618 1971 33</div>
                      <div>BIC: ABNANL2A</div>
                    </div>
                    <div>
                      <br />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="card">
              <div className="card-header">
                <h2 className="mb-0">
                  <span>Betalen - Wanneer moet ik betalen?</span>
                </h2>
              </div>
              <div className="collapse show">
                <div>
                  &nbsp;De volledige reissom dient per ommegaande voldaan te worden.
                  <br />
                </div>
              </div>
            </div>
            <div className="card">
              <div className="card-header">
                <h2 className="mb-0">
                  <span>Betaling - Is mijn (aan)betaling binnen gekomen?</span>
                </h2>
              </div>
              <div className="collapse show">
                <div>
                  De verwerking van uw (aan)betaling neemt enkele werkdagen in beslag. Zodra deze (aan)betaling is verwerkt krijgt u hiervan per mail
                  een bevestiging. Ook kunt u uw betaling in de gaten houden door in te loggen op ons website.
                </div>
              </div>
            </div>
            <div className="card">
              <div className="card-header">
                <h2 className="mb-0">
                  <span>Bevestiging / Factuur - Ik ben mijn bevestiging kwijt.</span>
                </h2>
              </div>
              <div className="collapse show">
                <div>
                  <div>Hiervoor verzoeken wij u contact op te nemen met onze callcenter (075) 8200 115.</div>
                </div>
              </div>
            </div>
            <div className="card">
              <div className="card-header">
                <h2 className="mb-0">
                  <span>Boardingpass - Wat moet ik doen als ik op de luchthaven mijn instapkaart verlies?</span>
                </h2>
              </div>
              <div className="collapse show">
                <div>
                  <div>
                    Op de luchthaven kunt u&nbsp;zich wenden tot&nbsp;een van de medewerker(s)&nbsp;van de incheckbalie of desbetreffende
                    luchtvaartmaatschappij.
                  </div>
                </div>
              </div>
            </div>
            <div className="card">
              <div className="card-header">
                <h2 className="mb-0">
                  <span>Boeking wijzigen</span>
                </h2>
              </div>
              <div className="collapse show">
                <div>
                  U kunt uw boeking wijzigingen via Triptime.nl. U kunt uw reisdatum en tijdstip van de heen- en terugvlucht,&nbsp;&nbsp;de
                  vertrekplaats en de bestemming wijzigen. Deze wijzigingen kunt u doorgeven per mail of telefoon. &nbsp;Per wijziging rekenen wij per
                  persoon 60 EUR aan wijzigingskosten. Als het geldende tarief op het moment van wijzigen hoger is dan het door u in eerste instantie
                  betaalde tarief, betaalt u eveneens het verschil tussen beide tarieven.
                </div>
              </div>
            </div>
            <div className="card">
              <div className="card-header">
                <h2 className="mb-0">
                  <span>Openings- en sluitingstijden incheckbalie</span>
                </h2>
              </div>
              <div className="collapse show">
                <div>
                  <div>
                    Op Schiphol kunt u vanaf 2,5 uur voor vertrek inchecken. Wij adviseren u ruim voor de geplande vertrektijd in te checken. De
                    check-in balies op Schiphol sluiten 50 minuten voor de geplande vertrektijd van uw vlucht.
                    <br />
                    <br />
                  </div>
                  <div>De check-in-balie's van Onur Air ,Pegasus en Sunexpress vindt u in vertrekhal 3.</div>
                  <div>
                    Transavia in vertrekhal 1.
                    <br />
                    <br />
                  </div>
                  <div>
                    Indien de luchthaven de check-in balie eerder sluit dan kan de reisorganisatie niet aansprakelijk gesteld worden voor het missen
                    van de vlucht.
                  </div>
                  <div />
                </div>
              </div>
              <div className="card">
                <div className="card-header">
                  <h2 className="mb-0">
                    <span>Dienstregeling - Waar kan ik de dienstregeling van mijn vlucht bekijken?</span>
                  </h2>
                </div>
                <div className="collapse show">
                  <div>
                    <div>
                      Voor actuele vertrek- en aankomsttijden van vluchten op Schiphol verwijzen wij u graag naar www.schiphol.nl of Teletekst.
                    </div>
                    <div>
                      <br />
                    </div>
                  </div>
                </div>
              </div>
              <div className="card">
                <div className="card-header">
                  <h2 className="mb-0">
                    <span>Huisdieren - Hoe oud moet mijn huisdier minimaal zijn?</span>
                  </h2>
                </div>
                <div className="collapse show">
                  <div>Uw hond of kat moet minimaal 3 maanden oud zijn om vervoerd te mogen worden.</div>
                </div>
              </div>
              <div className="card">
                <div className="card-header">
                  <h2 className="mb-0">
                    <span>Huisdieren - Kosten voor vervoer</span>
                  </h2>
                </div>
                <div className="collapse show">
                  <div>
                    <p className="MsoNormal" style={{ marginBottom: '0.0001pt' }}>
                      <span style={{ fontSize: '9pt', fontFamily: 'Arial, sans-serif' }}>Pegasus</span>
                    </p>
                    <p className="MsoNormal" style={{ marginBottom: '0.0001pt' }}>
                      <span style={{ fontSize: '9pt', fontFamily: 'Arial, sans-serif' }}>
                        Afmeting transporttas/kennel: 55 cm x 40 cm x 20 cm
                        <br />
                      </span>
                      <span style={{ fontFamily: 'Arial, sans-serif', fontSize: '9pt' }}>
                        Gewicht inclusief transporttas/kennel: 6kg
                        <br />
                      </span>
                      <span style={{ fontFamily: 'Arial, sans-serif', fontSize: '9pt' }}>
                        Tarief per enkele reis vervoer in de cabine: 40,-
                        <br />
                      </span>
                      <span style={{ fontFamily: 'Arial, sans-serif', fontSize: '9pt' }}>Tarief per enkele reis vervoer in het ruim: 80,-&nbsp;</span>
                      <br />
                    </p>
                    <br />
                    <br />
                    <p className="MsoNormal" style={{ marginBottom: '0.0001pt' }}>
                      <span style={{ fontFamily: 'Arial, sans-serif', fontSize: '9pt' }}>
                        Sunexpress
                        <br />
                      </span>
                      <span style={{ fontFamily: 'Arial, sans-serif', fontSize: '9pt' }}>
                        Afmeting transporttas/kennel: 55 cm x 40 cm x 20 cm
                        <br />
                      </span>
                      <span style={{ fontFamily: 'Arial, sans-serif', fontSize: '9pt' }}>
                        Gewicht inclusief transporttas/kennel: 6kg
                        <br />
                      </span>
                      <span style={{ fontFamily: 'Arial, sans-serif', fontSize: '9pt' }}>
                        Tarief per enkele reis vervoer in de cabine: 20,-
                        <br />
                      </span>
                      <span style={{ fontFamily: 'Arial, sans-serif', fontSize: '9pt' }}>Tarief per enkele reis vervoer in het ruim: 80,-</span>
                    </p>
                    <br />
                    <br />
                    <p className="MsoNormal" style={{ marginBottom: '0.0001pt' }}>
                      <span style={{ fontFamily: 'Arial, sans-serif', fontSize: '9pt' }}>
                        Transavia
                        <br />
                      </span>
                      <span style={{ fontFamily: 'Arial, sans-serif', fontSize: '9pt' }}>
                        Afmeting transporttas/kennel: 47 cm x 30 cm x 27 cm
                        <br />
                      </span>
                      <span style={{ fontFamily: 'Arial, sans-serif', fontSize: '9pt' }}>
                        Gewicht inclusief transporttas/kennel: 10kg
                        <br />
                      </span>
                      <span style={{ fontFamily: 'Arial, sans-serif', fontSize: '9pt' }}>
                        Tarief per enkele reis vervoer in de cabine: 40,-
                        <br />
                      </span>
                      <span style={{ fontFamily: 'Arial, sans-serif', fontSize: '9pt' }}>Tarief per enkele reis vervoer in het ruim: 60,-</span>
                    </p>
                    <br />
                    <br />
                    <p className="MsoNormal" style={{ marginBottom: '0.0001pt' }}>
                      <span style={{ fontFamily: 'Arial, sans-serif', fontSize: '9pt' }}>
                        Corendon
                        <br />
                      </span>
                      <span style={{ fontFamily: 'Arial, sans-serif', fontSize: '9pt' }}>
                        Afmeting transporttas/kennel: 55 cm x 40 cm x 20 cm
                        <br />
                      </span>
                      <span style={{ fontFamily: 'Arial, sans-serif', fontSize: '9pt' }}>
                        Gewicht inclusief transporttas/kennel: 7kg
                        <br />
                      </span>
                      <span style={{ fontFamily: 'Arial, sans-serif', fontSize: '9pt' }}>
                        Tarief per enkele reis vervoer in de cabine: 20,-
                        <br />
                      </span>
                      <span style={{ fontFamily: 'Arial, sans-serif', fontSize: '9pt' }}>Tarief per enkele reis vervoer in het ruim: 40,-</span>
                    </p>
                    <br />
                    <br />
                    <p className="MsoNormal" style={{ marginBottom: '0.0001pt' }}>
                      <span style={{ fontSize: '9pt', fontFamily: 'Arial, sans-serif' }}>
                        Onur Air
                        <br />
                      </span>
                      <span style={{ fontFamily: 'Arial, sans-serif', fontSize: '9pt' }}>Geen vervoer mogelijk.</span>
                    </p>
                    <p className="MsoNormal" style={{ marginBottom: '0.0001pt' }}>
                      <span style={{ fontSize: '9pt', fontFamily: 'Arial, sans-serif' }}>&nbsp;</span>
                      <span style={{ fontFamily: 'Arial, sans-serif', fontSize: '9pt' }}>
                        U dient de onderstaande kosten te voldoen.
                        <br />
                      </span>
                      <span style={{ fontFamily: 'Arial, sans-serif', fontSize: '9pt' }}>
                        - Als aanvraagkosten word er door Triptime 25,- in rekening gebracht.
                        <br />
                      </span>
                      <span style={{ fontFamily: 'Arial, sans-serif', fontSize: '9pt' }}>
                        - De vervoerskosten dient u aan de servicebalie van de luchtvaartmaatschappij op de luchthaven te voldoen.
                      </span>
                    </p>
                  </div>
                </div>
              </div>
              <div className="card">
                <div className="card-header">
                  <h2 className="mb-0">
                    <span>Huisdieren - Heb ik een dierenpaspoort nodig?</span>
                  </h2>
                </div>
                <div className="collapse show">
                  <div>
                    <div>
                      Ja, een EU-verordening bepaalt dat honden en katten tijdens het verkeer tussen lidstaten vergezeld moeten gaan van een paspoort
                      voor gezelschapsdieren. Dit paspoort moet gegevens bevatten over rabiësvaccinaties en andere vereiste gegevens over de
                      gezondheidstoestand van de dieren. Bovendien moet het paspoort rubrieken bevatten voor klinisch onderzoek en legalisatie, zodat
                      het paspoort ook geschikt is voor het vervoer van dieren buiten de EU. Het paspoort moet zijn afgegeven door een door de
                      bevoegde autoriteit aangewezen dierenarts. Voor een geldige gezondheidsverklaring moet de rubriek klinisch onderzoek ingevuld
                      zijn.
                    </div>
                    <div>Honden en katten moeten een elektronisch identificatiesysteem (transponder) dragen.</div>
                    <div>
                      <br />
                    </div>
                    <div>
                      Het vervoer van het huisdier is voor uw eigen risico. Als u geen paspoort voor gezelschapsdieren kunt laten zien of een tatoeage
                      of elektronisch identificatiesysteem ontbreekt, wordt uw huisdier niet geaccepteerd op de vlucht.
                    </div>
                    <div>
                      <br />
                    </div>
                    <div>
                      Voor meer informatie over de importvoorschriften van het huisdier kunt u contact opnemen met de ambassade van het land van
                      bestemming.
                    </div>
                    <div>
                      <br />
                    </div>
                  </div>
                </div>
              </div>
              <div className="card">
                <div className="card-header">
                  <h2 className="mb-0">
                    <span>Huisdieren - Voorwaarden transporttas</span>
                  </h2>
                </div>
                <div className="collapse show">
                  <div>
                    <div>
                      Een transporttas moet aan de volgende voorwaarden voldoen:
                      <br />
                      <br />
                    </div>
                    <div>
                      - Het moet een officiële transporttas zijn, zoals te koop bij een dierenwinkel
                      <br />
                      <br />
                    </div>
                    <div>
                      - Maximale afmetingen: 47x30x27 centimeter (LxBxH)
                      <br />
                      <br />
                    </div>
                    <div>
                      - Het gewicht van uw huisdier inclusief de tas is maximaal 10 kilogram
                      <br />
                      <br />
                    </div>
                    <div>
                      - De tas moet helemaal afsluitbaar zijn
                      <br />
                      <br />
                    </div>
                    <div>
                      - De tas is voorzien van luchtgaten, zodat het dier voldoende zuurstof heeft
                      <br />
                      <br />
                    </div>
                    <div>
                      - De tas is voorzien van plastic ruiten zodat het dier kan kijken
                      <br />
                      <br />
                    </div>
                    <div>- Het dier moet voldoende ruimte hebben in de tas om zich te bewegen</div>
                    <div>
                      <br />
                    </div>
                  </div>
                </div>
              </div>
              <div className="card">
                <div className="card-header">
                  <h2 className="mb-0">
                    <span>Huisdieren - Mag mijn huisdier ook zonder begeleiding reizen?</span>
                  </h2>
                </div>
                <div className="collapse show">
                  <div>Nee, het is niet mogelijk uw huisdier te vervoeren zonder begeleiding.</div>
                </div>
              </div>
              <div className="card">
                <div className="card-header">
                  <h2 className="mb-0">
                    <span>Huisdieren - Tips reizen met uw huisdier</span>
                  </h2>
                </div>
                <div className="collapse show">
                  <div>
                    <div>Praktische tips voor als u op reis gaat met uw huisdier:</div>
                    <div>
                      - Regel zaken als inentingen en reisdocumenten (dierenpaspoort) altijd ruim van tevoren.
                      <br />
                      <br />
                    </div>
                    <div>- Houd er rekening mee dat sommige landen strikte quarantainebepalingen hanteren voor elk dier&nbsp;</div>
                    <div>
                      &nbsp; &nbsp;dat het land binnenkomt.
                      <br />
                      <br />
                    </div>
                    <div>
                      - Bevestig een label met de naam van het dier en voederinstructies aan de zijkant van de kennel.
                      <br />
                      <br />
                    </div>
                    <div>
                      - De bodem van de kennel moet voorzien zijn van een deken of kranten.
                      <br />
                      <br />
                    </div>
                    <div>- Om veiligheidsredenen is het niet toegestaan om artikelen zoals speeltjes, knuffels en kluiven etc. in&nbsp;</div>
                    <div>
                      &nbsp; &nbsp;de kennel te vervoeren.
                      <br />
                      <br />
                    </div>
                    <div>- Laat uw huisdier al voor de vlucht wennen aan de kennel. Vijf dagen is meestal genoeg voor een&nbsp;</div>
                    <div>
                      &nbsp; &nbsp;dier om vertrouwd te raken met een kennel.
                      <br />
                      <br />
                    </div>
                    <div>- Dieren kunnen last hebben van luchtziekte. Geef uw huisdier vanaf 6 uur voor vertrek niets meer te&nbsp;</div>
                    <div>
                      &nbsp; &nbsp;eten en vanaf 4 uur voor vertrek niets meer te drinken.
                      <br />
                      <br />
                    </div>
                    <div>
                      Wij raden u sterk af om uw huisdier voor de reis een kalmerend middel te geven. Hierdoor zal het dier minder snel wennen aan
                      zijn nieuwe omgeving en mogelijk afkoelen tijdens de vlucht.
                    </div>
                    <div>
                      <br />
                    </div>
                  </div>
                </div>
              </div>
              <div className="card">
                <div className="card-header">
                  <h2 className="mb-0">
                    <span>Bagage (ruim) - Schade aan de bagage</span>
                  </h2>
                </div>
                <div className="collapse show">
                  <div>
                    Zorg ervoor dat u bij aankomst een schaderapport invult, voordat u de transitruimte verlaat. Hierbij ontvangt u de benodigde
                    informatie van het grondpersoneel voor de afhandeling van de schade aan uw bagage.
                    <br />
                  </div>
                </div>
              </div>
              <div className="card">
                <div className="card-header">
                  <h2 className="mb-0">
                    <span>Hoe kan ik een vraag, klacht, compliment of suggestie doorgeven?</span>
                  </h2>
                </div>
                <div className="collapse show">
                  <div>
                    <div>
                      Voor een snelle en correcte afhandeling van uw vragen, klachten, complimenten en&nbsp;suggesties kunt u naar
                      klantenservice@triptime.nl&nbsp;een mail zenden.&nbsp;
                    </div>
                    <div>
                      <br />
                    </div>
                  </div>
                </div>
              </div>
              <div className="card">
                <div className="card-header">
                  <h2 className="mb-0">
                    <span>Hoe maak ik mijn elektrische rolstoel of scootmobiel reis klaar?</span>
                  </h2>
                </div>
                <div className="collapse show">
                  <div>
                    <div>
                      Het vervoer van mobiliteitsmiddelen met een accu, zoals een elektrische rolstoel, een scootmobiel of elektrische step, is aan
                      voorwaarden verbonden. Alleen als u aan onderstaande voorwaarden heeft voldaan, kunnen wij uw rolstoel vervoeren.
                      <br />
                      <br />
                    </div>
                    <div>
                      U moet zelf het vervoermiddel reisklaar maken zoals hieronder beschreven. De check-in balie is niet voorzien van gereedschappen
                      of andere middelen om aan onderstaande voorwaarden te voldoen.
                      <br />
                      <br />
                    </div>
                    <div>Voor vervoer van een accu moet u:</div>
                    <div>- deze ontkoppelen en stevig aan de rolstoel/scootmobiel bevestigen</div>
                    <div>- de polen met tape isoleren om kortsluiting te voorkomen</div>
                    <div>Uitzondering: een gelaccu hoeft u niet te ontkoppelen mits u de polen met tape afplakt.</div>
                    <div>
                      <br />
                    </div>
                    <div>Droge accu's</div>
                    <div>
                      Droge accu's die een vast onderdeel van uw rolstoel zijn, moet u stevig bevestigen in de accuhouder en zodanig verpakken dat u
                      kortsluiting voorkomt.
                      <br />
                      <br />
                    </div>
                    <div>Natte accu's</div>
                    <div>
                      Rolstoel/scootmobiel en accu moeten rechtop geladen worden (max hoogte 86 cm). Als u uw rolstoel met natte accu niet rechtop
                      kunt laden, moet u deze verwijderen en de natte accu vervoeren in een sterke, lekdichte, tegen accuzuur bestaande verpakking.
                      Verder moet u:
                    </div>
                    <div>
                      - de accu beschermen tegen beschadiging bij stuwage.
                      <br />
                      <br />
                    </div>
                    <div>
                      -de polen met tape isoleren om kortsluiting te voorkomen.
                      <br />
                      <br />
                    </div>
                    <div>
                      -de accu rechtop in de verpakking vastzetten.
                      <br />
                      <br />
                    </div>
                    <div>
                      -de accu omgeven met voldoende absorberend materiaal om de gehele inhoud te kunnen absorberen.
                      <br />
                      <br />
                    </div>
                    <div>
                      - de verpakking merken met het opschrift: BATTERY WET, WITH WHEELCHAIR of BATTERY, WET, WITH MOBILITY AID en etiketteren met het
                      gevaaretiket: CORROSIVE en het PACKAGE ORIENTATION label (pijllabels)
                    </div>
                    <div>
                      <br />
                    </div>
                  </div>
                </div>
              </div>
              <div className="card">
                <div className="card-header">
                  <h2 className="mb-0">
                    <span>Hoe kan ik ervoor zorgen dat mijn voeten niet opzwellen tijdens de vlucht?</span>
                  </h2>
                </div>
                <div className="collapse show">
                  <div>
                    <div>
                      - Draaien met de enkels: til uw voeten van de vloer en maak met uw tenen een cirkel, waarbij u de ene voet met de klok mee en de
                      andere tegen de klok in beweegt. Maak vervolgens cirkels in tegengestelde richting (15 seconden in elke richting).
                    </div>
                    <div>
                      <br />
                    </div>
                    <div>
                      - Voet strekken: plaats uw hakken op de vloer en breng uw tenen zo hoog mogelijk omhoog. Zet de voeten weer plat op de vloer.
                      (doe de oefening gedurende 30 seconden)
                    </div>
                    <div>
                      <br />
                    </div>
                  </div>
                </div>
              </div>
              <div className="card">
                <div className="card-header">
                  <h2 className="mb-0">
                    <span>Hoe kan ik er voor zorgen dat ik me aan boord fit blijf voelen?</span>
                  </h2>
                </div>
                <div className="collapse show">
                  <div>
                    <div>
                      - Drink meer dan gewoonlijk om uitdroging te voorkomen. Wees matig met cafeïne en alcohol.
                      <br />
                      <br />
                    </div>
                    <div>
                      - Kies voor lichte maaltijden.
                      <br />
                      <br />
                    </div>
                    <div>
                      - Loop, wanneer mogelijk, een stukje door de cabine.
                      <br />
                      <br />
                    </div>
                    <div>- Houd tijdens het opstijgen en het landen kauwgom of snoep bij de hand om zo de&nbsp;</div>
                    <div>&nbsp; druk op uw oren te verminderen.</div>
                    <div>
                      <br />
                    </div>
                  </div>
                </div>
              </div>
              <div className="card">
                <div className="card-header">
                  <h2 className="mb-0">
                    <span>Is mijn reservering gelijk definitief geboekt?</span>
                  </h2>
                </div>
                <div className="collapse show">
                  <div>
                    <div>
                      Ja, nadat u de stappen in ons boekingsformulier hebt ingevoerd en een bevestiging&nbsp;terug ontvangt op uw e-mail adres dan is
                      uw reservering bindend. Wilt u de&nbsp;reservering nog wijzigen of annuleren, dan zijn hierop de algemeen voorwaarden&nbsp;van
                      toepassing.
                    </div>
                  </div>
                </div>
              </div>
              <div className="card">
                <div className="card-header">
                  <h2 className="mb-0">
                    <span>Ik reis met een scootmobiel. Waarmee moet ik rekening houden?</span>
                  </h2>
                </div>
                <div className="collapse show">
                  <div>
                    <div>
                      Per vlucht is plaats voor één scootmobiel. Het vervoer van een scootmobiel is gratis. Een scootmobiel kan niet mee tot aan het
                      vliegtuig. U moet uiterlijk 48 uur voor vertrek melden dat u reist met een rolstoel.U kunt tijdens of na uw online boeking uw
                      scootmobiel aanmelden.&nbsp;
                    </div>
                    <div>
                      <br />
                    </div>
                    <div>U kunt uw scootmobiel meenemen als:</div>
                    <div>- Deze niet hoger is dan 86 centimeter</div>
                    <div>- U deze bij boeking vermeldt</div>
                    <div>- U deze zelf reisklaar maakt</div>
                    <div>
                      - U met eigen gereedschap de accu verwijdert.
                      <br />
                      <br />
                      <div>
                        <strong>rolstoelen zonder accu</strong>
                      </div>
                      <div>
                        Uw eigen inklapbare rolstoel wordt ingenomen bij de check-in balie. Deze gaat mee in het bagageruim. Op basis van de
                        informatie die u bij het maken van uw boeking heeft verstrekt, wordt voor u de juiste begeleiding van en naar het vliegtuig
                        verzorgd. Op de luchthavens buiten Nederland volgt u de aanwijzingen van de afhandelaar ter plaatse.&nbsp;
                      </div>
                      <div>
                        <br />
                      </div>
                      <div>
                        <strong>elektrische rolstoelen (met accu)</strong>
                      </div>
                      <div>
                        Een elektrische rolstoel kan niet mee tot aan het vliegtuig. Deze nemen wij in bij de check-in balie. Op basis van de
                        informatie die u bij het maken van uw boeking heeft verstrekt, wordt voor u de juiste begeleiding van en naar het vliegtuig
                        verzorgd. Wij laden uw rolstoel vervolgens in het laadruim. Het vervoer van een elektrische rolstoel met een (natte) accu
                        kunnen wij slechts onder bepaalde voorwaarden accepteren. U bent verplicht om uw elektrische rolstoel bij uw boeking te
                        melden.&nbsp;
                      </div>
                      <div>
                        <br />
                      </div>
                      <div>
                        <strong>driewiel-elektroscooters</strong>
                      </div>
                      <div>
                        Driewiel elektro-scooters, ook wel scootmobiels genoemd, worden alleen geaccepteerd als u deze minimaal 48 uur van tevoren
                        heeft aangemeld bij ons. Per vlucht is plaats voor één scootmobiel. Vervoer is gratis. Een electroscooter mag niet hoger zijn
                        dan 86 cm. U dient zelf uw scootmobiel reisklaar te maken. Let op! De check-in balie is niet voorzien van gereedschappen of
                        andere middelen om een accu te ontkoppelen. Het vervoer van een elektrische rolstoel met een accu kunnen wij slechts
                        accepteren als aan de voorwaarden voor het vervoer van een accu is voldaan.
                      </div>
                    </div>
                    <div>
                      <br />
                    </div>
                    <div>
                      <br />
                    </div>
                  </div>
                </div>
              </div>
              <div className="card">
                <div className="card-header">
                  <h2 className="mb-0">
                    <span>Ik heb in de boeking mijn voornaam en achternaam verwisseld. Is dit een probleem?</span>
                  </h2>
                </div>
                <div className="collapse show">
                  <div>
                    Het is beter om dit te wijzigen om problemen op de luchthaven te voorkomen. Dit geldt wel als een naamswijziging en er zijn dan
                    ook kosten aan verbonden van 100 EUR. Neem contact op met ons Callcenter, zij kunnen de wijziging voor u doorvoeren.
                  </div>
                </div>
              </div>
              <div className="card">
                <div className="card-header">
                  <h2 className="mb-0">
                    <span>Is roken op Schiphol toegestaan ?</span>
                  </h2>
                </div>
                <div className="collapse show">
                  <div>
                    Met ingang van 1 januari 2008 geldt een algeheel rookverbod in alle publiek toegankelijke ruimten in de terminal van Schiphol. Dit
                    betekent dat u ook in de horecavoorzieningen en de Airlines lounges niet meer mag roken. Voor de passagiers in de terminal zijn er
                    geen vervangende rookvoorzieningen.
                  </div>
                </div>
              </div>
              <div className="card">
                <div className="card-header">
                  <h2 className="mb-0">
                    <span>Is het tijdsverschil met mijn bestemming al verwerkt in de aankomsttijd?</span>
                  </h2>
                </div>
                <div className="collapse show">
                  <div>
                    Ja, alle vertrek- en aankomsttijden staan weergegeven in de plaatselijke tijd. Door een eventueel tijdsverschil tussen
                    vertrekplaats en bestemming is het mogelijk dat het lijkt alsof de duur van de heenvlucht verschilt van die van de terugvlucht.
                  </div>
                </div>
              </div>
              <div className="card">
                <div className="card-header">
                  <h2 className="mb-0">
                    <span>Ik heb speciale begeleiding nodig aan boord. Kan iemand van het cabinepersoneel mij begeleiden?</span>
                  </h2>
                </div>
                <div className="collapse show">
                  <div>
                    <div>
                      Tijdens de vlucht kunt u al uw wensen en behoeftes bespreken. Het cabinepersoneel is echter niet bevoegd passagiers te
                      assisteren:
                    </div>
                    <div>- bij het eten</div>
                    <div>- bij de persoonlijke hygiëne</div>
                    <div>- voor het optillen of dragen van passagiers</div>
                    <div>- voor het toedienen van medicijnen of injecties.</div>
                    <div>
                      <br />
                    </div>
                    <div>
                      Als een passagier dergelijke verzorging nodig heeft, dan moet deze reizen met een persoonlijk begeleider die hem of haar tijdens
                      de vlucht kan assisteren. Voor een begeleider geldt het normale vluchttarief
                    </div>
                    <div>
                      <br />
                    </div>
                  </div>
                </div>
              </div>
              <div className="card">
                <div className="card-header">
                  <h2 className="mb-0">
                    <span>Ik wil met een rugzak reizen. Hoe kan ik het beste inchecken?</span>
                  </h2>
                </div>
                <div className="collapse show">
                  <div>
                    <div>Om het transport van uw rugzak te vergemakkelijken, gelieve uw rugzak als volgt te leveren:</div>
                    <div>
                      <br />
                    </div>
                    <div>-&nbsp;Onbeschadigd en goed gesloten</div>
                    <div>-&nbsp;Alle banden gebonden aan de rugzak</div>
                    <div>-&nbsp;Geen losse onderdelen, zoals potten en pannen aan de rugzak</div>
                    <div>- Bij voorkeur voorzien van een hoes om de rugzak&nbsp;</div>
                    <div>
                      <br />
                    </div>
                    <div>
                      <br />
                    </div>
                  </div>
                </div>
              </div>
              <div className="card">
                <div className="card-header">
                  <h2 className="mb-0">
                    <span>Is het mogelijk om een muziekinstrument mee te nemen in de cabine?</span>
                  </h2>
                </div>
                <div className="collapse show">
                  <div>
                    <div>
                      Muziekinstrumenten worden in het vrachtruim vervoerd, voor het vervoer van muziekinstrumenten geldt ons reguliere
                      ruimbagagetarief. Een klein muziekinstrument mag u ook meenemen als handbagage. Alleen muziekinstrumenten die (inclusief
                      beschermhoes) niet groter zijn dan 25 x 117 x 38 cm en een gewicht hebben van maximaal 10 kg kunnen in de cabine worden
                      vervoerd.
                    </div>
                    <div>
                      <br />
                    </div>
                  </div>
                </div>
              </div>
              <div className="card">
                <div className="card-header">
                  <h2 className="mb-0">
                    <span>Kinderen - reizen met of zonder begeleiding</span>
                  </h2>
                </div>
                <div className="collapse show">
                  <div>
                    <p>
                      Kinderen jonger dan 5 jaar mogen niet alleen reizen. Voor een alleenreizend kind (tussen 5-15 jaar) moet u begeleiding
                      aanvragen. De purser ontvangt het alleenreizende kind aan boord en draagt het kind bij aankomst op de bestemming over aan een
                      begeleider ter plaatse. Er moet altijd een afhaler (van 16 jaar of ouder) op de luchthaven van bestemming zijn.
                    </p>
                    <p>
                      <strong>Transavia</strong>
                      <br />
                      Kinderen worden naar en van het vliegtuig en aan boord begeleid. Geaccepteerd met een maximum van 4 per vlucht. Service fee 
                      50,00 per enkele reis.
                      <br />
                      Alleenreizende kinderen in de leeftijd van 12 t/m 15 jaar mogen alleen reizen maar krijgen verder geen begeleiding van het
                      transavia.com personeel. Indien gewenst kunt u de service als hierboven beschreven ook aanvragen voor een kind in de leeftijd
                      van 12 t/m 15 jaar. De kosten voor deze service bedragen  50,00 per kind per enkele reis.&nbsp;
                      <br />
                      Alleen passagiers van zestien jaar en ouder mogen kinderen jonger dan twaalf jaar begeleiden.&nbsp;
                      <br />
                      Voor een kind moet altijd een afhaler ter plekke aanwezig zijn, anders gaat het kind direct op de retourvlucht mee terug naar de
                      luchthaven van vertrek. De afhaler dient zich van tevoren te melden bij de transavia.com-afhandelaar op desbetreffende
                      luchthaven.&nbsp;
                      <br />
                      Verzoeken tot begeleiding dienen minimaal 6 werkdagen voor vertrek te worden gemeld
                    </p>
                  </div>
                </div>
              </div>
              <div className="card">
                <div className="card-header">
                  <h2 className="mb-0">
                    <span>Kind heeft de waterpokken of een andere kinderziekte. Mag een kind hiermee reizen?</span>
                  </h2>
                </div>
                <div className="collapse show">
                  <div>
                    <p>
                      Een kind dat geïnfecteerd is door een kinderziekte, wordt zonder een door een (onafhankelijk) arts ondertekende medische
                      verklaring (alleen in het Nederlands of Engels) niet geaccepteerd. In deze medische verklaring dient aangegeven te zijn dat de
                      ziekte(s) niet (meer) besmettelijk is/zijn, en dat het veilig is om te gaan vliegen. Dit voor de veiligheid van uzelf, uw kind
                      en voor de veiligheid van andere passagiers en de bemanning.
                    </p>
                  </div>
                </div>
              </div>
              <div className="card">
                <div className="card-header">
                  <h2 className="mb-0">
                    <span>Kan ik mijn eigen zuurstof meenemen aan boord?</span>
                  </h2>
                </div>
                <div className="collapse show">
                  <div>
                    We accepteren reizigers die normaal gesproken om medische redenen extra zuurstof nodig hebben, op voorwaarde dat zij beschikken
                    over een medische verklaring waarin wordt bevestigd dat ze gezond genoeg zijn om met de betreffende vlucht te vliegen zonder extra
                    zuurstof. Passagiers die de vereiste medische verklaring niet bij de incheckbalie kunnen overleggen, worden niet aan boord
                    toegelaten.&nbsp;
                  </div>
                </div>
              </div>
              <div className="card">
                <div className="card-header">
                  <h2 className="mb-0">
                    <span>Kan ik een boeking maken en betalen voor een andere persoon, zonder dat ik zelf meereis?</span>
                  </h2>
                </div>
                <div className="collapse show">
                  <div>
                    U kunt voor een andere persoon boeken en betalen. Vul tijdens uw boeking via de website de naam van de passagier in zoals vermeld
                    in zijn of haar paspoort. Bij de betaling vult u dan uw eigen gegevens in.
                  </div>
                </div>
              </div>
              <div className="card">
                <div className="card-header">
                  <h2 className="mb-0">
                    <span>Kan ik mijn bagage 24 uur voor vertrek van mijn vlucht al afgeven?</span>
                  </h2>
                </div>
                <div className="collapse show">
                  <div>
                    Nee, u kunt uw bagage alleen en uitsluitend op de dag van vertrek op de luchthaven afgeven. Dit kunt u doen vanaf 2,5 uur voor
                    vertrek.
                  </div>
                </div>
              </div>
              <div className="card">
                <div className="card-header">
                  <h2 className="mb-0">
                    <span>Kan ik alleen een pakketje afgeven op de bestemming en direct weer terugvliegen?</span>
                  </h2>
                </div>
                <div className="collapse show">
                  <div>
                    Het is niet mogelijk om naar een bestemming te vliegen en met dezelfde vlucht terug te reizen. U moet bij aankomst eerst door de
                    douane om vervolgens weer in te checken. U kunt daardoor niet op tijd zijn om met hetzelfde vliegtuig terug te vliegen.
                  </div>
                </div>
              </div>
              <div className="card">
                <div className="card-header">
                  <h2 className="mb-0">
                    <span>Kan ik een stoel met extra beenruimte boeken?</span>
                  </h2>
                </div>
                <div className="collapse show">
                  <div>Passagiers kunnen stoelen met extra beenruimte boeken. Dit geldt alleen als u heeft geboekt bij Transavia en/of Corendon.</div>
                </div>
              </div>
              <div className="card">
                <div className="card-header">
                  <h2 className="mb-0">
                    <span>Luchthaven - Hoe laat moet ik aanwezig zijn ?</span>
                  </h2>
                </div>
                <div className="collapse show">
                  <div>
                    <div
                      style={{
                        backgroundImage: 'none',
                        border: '0px solid #4986a2',
                        textAlign: 'left',
                        padding: '0px 0px 0px 4px',
                        backgroundColor: '#ffffff',
                        margin: 0
                      }}>
                      <p
                        style={{
                          fontFamily: '"Times New Roman"',
                          fontSize: 12,
                          fontStyle: 'normal',
                          fontVariant: 'normal',
                          fontWeight: 'normal',
                          lineHeight: 'normal'
                        }}
                      />
                      <div
                        style={{
                          backgroundImage: 'none',
                          border: '0px solid #4986a2',
                          textAlign: 'left',
                          padding: '0px 0px 0px 4px',
                          backgroundColor: '#ffffff',
                          margin: 0
                        }}>
                        <p>
                          Op Schiphol en andere luchthavens kunt u vanaf 2,5 uur voor vertrek inchecken. Zorg ervoor dat u minimaal 2 uur voor vertrek
                          van uw vlucht incheckt. De check-in balies op de luchthavens sluiten 50 minuten voor de geplande vertrektijd van uw vlucht.
                        </p>
                        <p
                          style={{
                            fontFamily: 'Arial',
                            fontSize: 12,
                            fontStyle: 'normal',
                            fontVariant: 'normal',
                            fontWeight: 'normal',
                            lineHeight: 'normal'
                          }}>
                          <em>
                            Indien de luchthaven de check-in eerder sluit dan kan de reisorganisatie niet aansprakelijk gesteld worden voor het missen
                            van de vlucht.
                            <br />
                          </em>
                        </p>
                      </div>
                      <p
                        style={{
                          fontFamily: '"Times New Roman"',
                          fontSize: 12,
                          fontStyle: 'normal',
                          fontVariant: 'normal',
                          fontWeight: 'normal',
                          lineHeight: 'normal'
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="card">
                <div className="card-header">
                  <h2 className="mb-0">
                    <span>Maaltijd aan boord ?</span>
                  </h2>
                </div>
                <div className="collapse show">
                  <div>
                    <div>
                      <strong>
                        <br />
                      </strong>
                    </div>
                    Aan boord bij <strong>Onur Air</strong>
                    ,&nbsp;
                    <strong>Pegasus</strong>,<strong>Corendon</strong> en&nbsp;
                    <strong>Transavia</strong>
                    ,kunt u tegen betaling een keuze maken uit veelzijdige à la carte assortimenten. Cabinepersoneel komt langs met een
                    &nbsp;uitgebreid assortiment koude - en op de langere vluchten ook warme - snacks en (fris)dranken (dit verschilt per
                    vliegtuigmaatschapij). U betaalt uw bestelling direct aan het cabinepersoneel. Het is toegestaan om alcoholische dranken te
                    nuttigen, mits die aan boord gekocht zijn.&nbsp;
                    <div>
                      <div>
                        <br />
                      </div>
                      <div>
                        <strong>Sunexpress </strong>
                        serveert gratis een sandwich aan boord met een kopje thee of koffie. &nbsp;
                      </div>
                      <div>
                        <br />
                      </div>
                      <div>
                        <br />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="card">
                <div className="card-header">
                  <h2 className="mb-0">
                    <span>Mag ik medicijnen in mijn handbagage meenemen?</span>
                  </h2>
                </div>
                <div className="collapse show">
                  <div>
                    Dat mag, u moet medicijnen of dieetsupplementen wel apart aanbieden bij de handbagagecontrole. U hoeft dit niet in een
                    doorzichtige zak te vervoeren en er geldt geen beperking op de hoeveelheid, mits deze binnen de toegestane handbagagevrijdom valt.
                    Neem een Engelse versie van het recept van uw medicijnen mee voor het geval douane- of veiligheidsbeambten vragen stellen over uw
                    medicijnen of injectienaalden. U hoeft uw medicijnen niet vooraf aan te melden.
                  </div>
                </div>
              </div>
              <div className="card">
                <div className="card-header">
                  <h2 className="mb-0">
                    <span>Medische verklaring</span>
                  </h2>
                </div>
                <div className="collapse show">
                  <div>
                    &nbsp;Een medische verklaring mag niet langer dan een maand oud zijn. Bij voorkeur moet deze verklaring in het Engels zijn.
                  </div>
                </div>
              </div>
              <div className="card">
                <div className="card-header">
                  <h2 className="mb-0">
                    <span>Moet mijn aansteker ook in een transparante zakje?</span>
                  </h2>
                </div>
                <div className="collapse show">
                  <div>
                    Nee,&nbsp;
                    <span style={{ color: '#003145', lineHeight: '14.77199935913086px' }}>
                      Het is verboden om in uw hand- of ruimbagage voorwerpen mee te nemen, waarmee een voorwerp kan worden afgeschoten of waarmee
                      letsel kan worden toegebracht, of dat daarvoor lijkt te kunnen worden gebruikt
                    </span>
                    . Kortom, een aansteker mag niet vervoerd worden.
                  </div>
                </div>
              </div>
              <div className="card">
                <div className="card-header">
                  <h2 className="mb-0">
                    <span>Moet mijn baby ook in een veiligheidsriem zitten bij start en landing?</span>
                  </h2>
                </div>
                <div className="collapse show">
                  <div>
                    Ja, u ontvangt een verlenggordel om uw baby op schoot te laten meereizen. Als u voor uw baby een eigen stoel heeft gereserveerd,
                    moet u uw baby tijdens de start en landing op schoot nemen.
                  </div>
                </div>
              </div>
              <div className="card">
                <div className="card-header">
                  <h2 className="mb-0">
                    <span>Mag ik volle zuurstofflessen voor gebruik op de bestemming meenemen?</span>
                  </h2>
                </div>
                <div className="collapse show">
                  <div>
                    <div>U mag maximaal vier volle zuurstofflessen vervoeren (als handbagage) voor gebruik op de bestemming. &nbsp;</div>
                    <div>Voor meer informatie en aanvragen kunt u terecht bij: Medidis T: 0320-267660 F: 0320-267666.</div>
                  </div>
                </div>
              </div>
              <div className="card">
                <div className="card-header">
                  <h2 className="mb-0">
                    <span>Mag ik met een verlopen of een beschadigd reisdocument reizen?</span>
                  </h2>
                </div>
                <div className="collapse show">
                  <div>Nee, reisdocumenten die verlopen of beschadigd zijn, worden niet geaccepteerd.</div>
                </div>
              </div>
              <div className="card">
                <div className="card-header">
                  <h2 className="mb-0">
                    <span>Namen - Ik ben getrouwd, welke naam geef ik bij mijn boeking op?</span>
                  </h2>
                </div>
                <div className="collapse show">
                  <div>
                    <div>
                      <div>
                        De naam die in uw paspoort staat vermeld, is de enige juiste naam om in te vullen bij uw boeking. Op de luchthaven wordt de
                        naam op uw boekingsbevestiging en die in uw paspoort met elkaar vergeleken, daarom is het belangrijk de juiste naam in te
                        vullen. Als deze namen niet met elkaar overeenkomen, wordt u niet geaccepteerd op de vlucht.&nbsp;
                      </div>
                      <div>
                        <br />
                      </div>
                      <div>
                        Let op: Als u alleen uw getrouwde naam opgeeft bij boeking en in uw paspoort staat alleen maar uw meisjesnaam wordt u niet
                        geaccepteerd.
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="card">
                <div className="card-header">
                  <h2 className="mb-0">
                    <span>Naar welke landen mag ik reizen met mijn identiteitskaart?</span>
                  </h2>
                </div>
                <div className="collapse show">
                  <div>
                    <div>
                      Als Nederlandse staatsburger kunt u ervoor kiezen om te reizen met een paspoort dat geldig is over de gehele wereld of te reizen
                      met een Europese identiteitskaart, die geldig is in de meeste Europese landen. Een identiteitskaart is 5 jaar geldig. Op een
                      identiteitskaart kunnen echter geen kinderen worden bijgeschreven. Een identiteitskaart kunt u uitsluitend aanvragen bij de
                      gemeente waar u woonachtig bent en bij een beperkt aantal Nederlandse vertegenwoordigingen in Europa.&nbsp;
                    </div>
                    <div>
                      <br />
                    </div>
                    <div>
                      De Nederlandse identiteitskaart is geldig voor reizen naar de volgende landen: Andorra, België, Cyprus, Denemarken, Duitsland,
                      Estland, Finland, Frankrijk, Griekenland, Groot-Brittannië en Noord-Ierland (inclusief de Kanaaleilanden), Hongarije, Ierland,
                      Italië, Letland, Liechtenstein, Litouwen, Luxemburg, Malta, Monaco, Noorwegen, Oostenrijk, Polen, Portugal (inclusief Madeira en
                      de Azoren), San Marino, Slovenië, Slowakije, Spanje (inclusief de Canarische eilanden), Turkije, Tsjechië, IJsland, Zweden,
                      Zwitserland.
                    </div>
                    <div>
                      <br />
                    </div>
                  </div>
                </div>
              </div>
              <div className="card">
                <div className="card-header">
                  <h2 className="mb-0">
                    <span>Online inchecken</span>
                  </h2>
                </div>
                <div className="collapse show">
                  <div>
                    <div>Deze mogelijkheid wordt enkel door Transavia en Corendon aangeboden.&nbsp;</div>
                    <div>
                      <br />
                    </div>
                    <div>Transavia</div>
                    <div>U dient deze link te raadplegen http://selfservice.transavia.com&nbsp;</div>
                    <div>
                      <br />
                    </div>
                    <div>Corendon (vluchten vanaf 1 april 2014)</div>
                    <div>U dient deze link te raadplegen</div>
                    <div>www.flycorendon.com&nbsp;</div>
                    <div>
                      <br />
                    </div>
                    <div>Bij andere maatschappijen is het helaas nog niet mogelijk om online in te checken.&nbsp;</div>
                    <div>Deze mogelijkheid word u enkel aangeboden indien u rechtstreeks bij de luchtvaartmaatschappij boekt.</div>
                    <div>
                      <br />
                    </div>
                  </div>
                </div>
              </div>
              <div className="card">
                <div className="card-header">
                  <h2 className="mb-0">
                    <span>Op dit moment is het tarief lager dan toen ik boekte, krijg ik geld terug?</span>
                  </h2>
                </div>
                <div className="collapse show">
                  <div>Het is niet mogelijk om geld terug te krijgen als er een lager tarief beschikbaar is.</div>
                </div>
              </div>
              <div className="card">
                <div className="card-header">
                  <h2 className="mb-0">
                    <span>Reisdocumenten</span>
                  </h2>
                </div>
                <div className="collapse show">
                  <div>
                    Doorgaans geldt dat u in het bezit moet zijn van een geldig paspoort of identiteitskaart en soms ook een visum. Het is uw eigen
                    verantwoordelijkheid om ervoor te zorgen dat u tijdens uw reis de juiste documenten kunt laten zien. Verlopen of beschadigde
                    reisdocumenten worden niet geaccepteerd. Overige documenten zoals het rijbewijs of bankpas zijn geen formeel identiteitsbewijs en
                    worden dus ook niet geaccepteerd.
                  </div>
                </div>
              </div>
              <div className="card">
                <div className="card-header">
                  <h2 className="mb-0">
                    <span>Reisverzekering - Waarom zou ik een reisverzekering afsluiten?</span>
                  </h2>
                </div>
                <div className="collapse show">
                  <div>
                    <p>
                      Wanneer u op reis gaat, wordt u met verschillende risico's geconfronteerd. De gelopen risico's hangen van uw bestemming, het
                      vervoermiddel en uw activiteiten tijdens uw vakantie af. Om de financiële gevolgen van deze risico's te dragen kunt u een
                      reisverzekering afsluiten. Een reisverzekering kunt u per persoon afsluiten. Een reisverzekering is een aanvullende verzekering
                      voor de schade die u tijdens uw vakantie kunt lijden. Vooral wanneer u naar het buitenland vertrekt, is het belangrijk om na te
                      gaan of uw lopende verzekeringen de tijdens reizen geleden schade dekken en tot welk bedrag. In de landen van de Europese Unie
                      kunnen uw verzorgingskosten door de plaatselijke Sociale Zekerheid overgenomen worden. Controleer of uw aanvullende
                      ziekteverzekering de kosten vergoedt, die u in het buitenland maakt. Een medische handeling kan in het buitenland veel duurder
                      zijn en zonder reisverzekering worden deze bijkomende kosten niet vergoed. De reisverzekering omvat in het algemeen garanties
                      voor bagage, medische kosten, onvoorziene kosten, ongevallen en repatriëring. Bij de meeste reisverzekeringen kunt u zelf
                      bepalen welke opties u wenst af te sluiten. Wanneer u bijvoorbeeld geen waardevolle voorwerpen meeneemt, vindt u het misschien
                      niet noodzakelijk om uw bagage te verzekeren. Uw eigen schuld en nalatigheid zijn bijna altijd uitgesloten. Dit is het geval
                      wanneer u waardevolle voorwerpen zichtbaar in uw voertuig hebt achtergelaten en deze gestolen zijn. Wanneer uw goederen tijdens
                      de reis gestolen of verloren werden, is het belangrijk om de verzekeraar een aangifte bij de plaatselijke politie te
                      overhandigen. De bijdrage voor de reisverzekering hangt van de duur van de reis af en van de opties die u wenst af te sluiten.
                      Een extra premie wordt soms toegepast om de beoefening van bepaalde sporten te verzekeren, zoals skiën en duiken.
                    </p>
                  </div>
                </div>
              </div>
              <div className="card">
                <div className="card-header">
                  <h2 className="mb-0">
                    <span>Reizen met een baby</span>
                  </h2>
                </div>
                <div className="collapse show">
                  <div>
                    Passagiers jonger dan 2 jaar (0-23 maanden) worden als baby's beschouwd. Baby's van minder dan 1 week oud mogen niet vliegen, ook
                    al reizen zij met hun ouders. Indien de baby tussen 48 uur en 7 dagen oud is, mogen moeder en baby slechts reizen met de
                    schriftelijke toestemming van een arts. Conform veiligheidsreglementen mag een volwassene tijdens een vlucht slechts één baby
                    begeleiden.
                  </div>
                </div>
              </div>
              <div className="card">
                <div className="card-header">
                  <h2 className="mb-0">
                    <span>Terminal - Op welke terminal arriveer/vertrek ik in Antalya?</span>
                  </h2>
                </div>
                <div className="collapse show">
                  <div>
                    <div>Zomerperiode april t/m oktober</div>
                    <div>Terminal 1</div>
                    <div>Pegasus, Transavia, Sunexpress en Onur Air.</div>
                    <div>
                      <br />
                    </div>
                    <div>Terminal 2</div>
                    <div>Corendon&nbsp;</div>
                    <div>
                      <br />
                    </div>
                    <div>Winterperiode november t/m maart</div>
                    <div>Terminal 1</div>
                    <div>Gesloten</div>
                    <div>
                      <br />
                    </div>
                    <div>Terminal 2</div>
                    <div>Alle luchtvaartmaatschappijen&nbsp;</div>
                    <div>
                      <br />
                    </div>
                    <div>
                      Let op: Luchtvaartmaatschappijen behouden het recht om tussentijds (tijdelijk seizoensgebonden) hiervan af te wijken. Hier
                      kunnen geen rechten aan worden ontleend. Voor actuele informatie adviseren wij om www.aytport.com te raadplegen.
                    </div>
                    <div>
                      <br />
                    </div>
                  </div>
                </div>
              </div>
              <div className="card">
                <div className="card-header">
                  <h2 className="mb-0">
                    <span>Tickets - Ik kan mijn tickets niet printen omdat ik geen printer heb, kan ik ook inchecken zonder mijn tickets?</span>
                  </h2>
                </div>
                <div className="collapse show">
                  <div>
                    <div>U bent verplicht om de reisbescheiden uit te printen.</div>
                    <div>
                      <br />
                    </div>
                  </div>
                </div>
              </div>
              <div className="card">
                <div className="card-header">
                  <h2 className="mb-0">
                    <span>Vertraging</span>
                  </h2>
                </div>
                <div className="collapse show">
                  <div>
                    <p>Afhankelijk van de vliegafstand en de duur van de vertraging heeft u recht op:</p>
                    <p>
                      * Maaltijden en/of consumpties in verhouding tot de wachttijd <br />* Twee telefoongesprekken (maximaal 2 minuten per gesprek)
                      <br />* Indien een verblijf van één of meer nachten noodzakelijk wordt, zal de luchtvaartmaatschappij &nbsp;zorgen voor de
                      accommodatie en het vervoer tussen de accommodatie en de luchthaven.
                      <br />* Gevolgschade wordt niet vergoed
                    </p>
                    <p>
                      Als het verlenen van de verzorging zoals hierboven omschreven een extra vertraging oplevert, kan het voorkomen dat deze zorg
                      niet als zodanig wordt verleend.
                    </p>
                    <p>Al deze vergoedingen zijn op kosten van de desbetreffende luchtvaartmaatschappij.</p>
                  </div>
                </div>
              </div>
              <div className="card">
                <div className="card-header">
                  <h2 className="mb-0">
                    <span>Vanaf welke leeftijd mogen kinderen zonder begeleiding reizen?</span>
                  </h2>
                </div>
                <div className="collapse show">
                  <div>
                    <div>
                      U moet uw kind uiterlijk 48 uur van tevoren aanmelden. Degene die het kind naar de luchthaven brengt, moet zich samen met het
                      kind bij de incheckbalie melden en daar een document voor de overdracht tekenen. Wij zorgen voor begeleiding van het kind van en
                      naar het vliegtuig. De purser ontvangt het alleen reizende kind aan boord en draagt het kind bij aankomst op de bestemming over
                      aan een begeleider van het luchtvaartmaatschappij ter plaatse. Er moet altijd een afhaler (van 16 jaar of ouder) op de
                      luchthaven van bestemming zijn, anders gaat het kind op de vlucht mee terug. Deze afhaler moet zich vooraf melden bij de
                      informatie balie van het luchtvaartmaatschappij op de desbetreffende luchthaven. U betaalt voor deze service een bepaald bedrag
                      per kind per enkele reis.
                    </div>
                    <div>
                      <br />
                    </div>
                    <div>Kinderen in de leeftijd van 12 tot en met 15 jaar:</div>
                    <div>
                      Deze kinderen mogen alleen reizen en krijgen geen begeleiding. Maar als u dat wilt, kunt u de service zoals hierboven beschreven
                      ook aanvragen voor uw kind in de leeftijd van 12 tot en met 15 jaar. U betaalt voor deze service een bepaald bedrag per kind per
                      enkele reis.
                    </div>
                    <div>
                      <br />
                    </div>
                    <div>Voor minderjarigen reizend van/naar Frankrijk en Portugal geldt een aantal aanvullende regels</div>
                    <div>
                      <br />
                    </div>
                  </div>
                </div>
              </div>
              <div className="card">
                <div className="card-header">
                  <h2 className="mb-0">
                    <span>Visum</span>
                  </h2>
                </div>
                <div className="collapse show">
                  <div>
                    <div>
                      Reizigers met de Nederlandse nationaliteit hebben vanaf 1 januari 1997 een visum nodig. De eenvoudigste manier om dit visum te
                      verkrijgen, is op de luchthaven in Turkije. Op alle Turkse luchthavens tegen een betaling van 15 &nbsp;verkrijgbaar. Het visum
                      is ook verkrijgbaar bij het Turkse consulaat in Rotterdam. Het visum is 90 dagen geldig.
                    </div>
                    <div>
                      <br />
                    </div>
                    <div>
                      <div>
                        Vanaf 1 februari 2012 is het verblijf in Turkije voor toeristische redenen aan strengere regels gebonden. <br />
                        Dit is op 17 januari officieel bevestigd door de ambassade.
                      </div>
                      <div>
                        Nederlanders moeten nog steeds voor een toeristisch verblijf bij binnenkomst in Turkije een visum aanschaffen en dit visum is
                        ook nog steeds maximaal 90 dagen geldig.
                      </div>
                    </div>
                    <div>
                      <br />
                      <strong>Wat verandert er precies?&nbsp;</strong>
                      <br />
                      <div>
                        Vanaf 1 februari 2012 mag een Nederlandse toerist maximaal 90 dagen per 180 dagen in Turkije verblijven. <br />
                        Dit wordt de 90/180 dagen regel genoemd.&nbsp;
                      </div>
                      <div>
                        <br />
                        Voorbeeld: Ben je 90 dagen in Turkije verbleven en ga je terug naar Nederland, dan mag je dus de komende 90 dagen Turkije niet
                        meer in. Pas na die periode wordt er weer een nieuw toeristenvisum verstrekt aan dezelfde persoon.
                        <br />
                        <br />
                      </div>
                      <div>
                        <strong>Let op: </strong>
                        Het is vanaf 1 februari dus officieel niet meer mogelijk even heen en weer te pendelen naar een Grieks eiland om een nieuw
                        toeristenvisum van 90 dagen te verkrijgen.
                      </div>
                      <div>
                        Zie ook:
                        <a href="http://turkije.nlambassade.org ">turkije.nlambassade.org&nbsp;</a>
                      </div>
                    </div>
                    <div>
                      <br />
                    </div>
                  </div>
                </div>
              </div>
              <div className="card">
                <div className="card-header">
                  <h2 className="mb-0">
                    <span>Vluchtwijziging - Wordt er contact met mij opgenomen wanneer er een wijziging in mijn vluchtschema plaatsvindt?</span>
                  </h2>
                </div>
                <div className="collapse show">
                  <div>
                    <div>
                      Reiziger is zelf verantwoordelijk om tot 24 uur voor vertrek via onze website&nbsp;www.triptime.nl de vluchttijden te
                      controleren.
                    </div>
                  </div>
                </div>
              </div>
              <div className="card">
                <div className="card-header">
                  <h2 className="mb-0">
                    <span>Waarmee moet ik rekening houden als ik met een baby reis?</span>
                  </h2>
                </div>
                <div className="collapse show">
                  <div>
                    <p>
                      Voor baby's tot en met 23 maanden geldt aan boord een aantal regels waar u rekening mee moet houden bij uw boeking:
                      <br />
                      &nbsp;Elke baby moet worden begeleid door iemand van 16 jaar of ouder.
                      <br />
                      &nbsp;De begeleider moet de baby op schoot vervoeren.
                      <br />
                      &nbsp;Een volwassene mag in verband met veiligheidsvoorschriften met maximaal één baby op schoot reizen.
                      <br />
                      &nbsp;Alleen als u een aparte stoel heeft gekocht voor de baby voor het reguliere tarief is er plaats voor een Maxi-Cosi,
                      goedgekeurd door ANWB of TNO met een maximale afmeting van 41 centimeter breed en 57 centimeter lang. Een autostoel of reiswieg
                      is vanwege afmeting en vliegveiligheid niet toegestaan.
                      <br />
                      &nbsp;Onze bemanning heeft altijd het recht om in het belang van de vliegveiligheid een autostoel/Maxi-Cosi te weigeren.
                      <br />
                      &nbsp;transavia.com heeft geen wiegjes aan boord.
                      <br />
                      &nbsp;Pasgeboren baby's mogen pas zeven dagen na hun geboorte reizen in een drukcabine.
                      <br />
                      &nbsp;De bevalling moet zonder complicaties zijn verlopen.
                      <br />
                      &nbsp;Premature baby's worden niet vervoerd.
                      <br />
                      <br />
                      Babyvoeding aan boord:
                      <br />U mag babyvoeding aan boord meenemen: voor gebruik tijdens de vlucht (bijvoorbeeld nutrilon poeder met pap) moet u de
                      babyvoeding bij de bagagecontrole apart aanbieden. Dit hoeft niet in een doorzichtige plastic zak en de hoeveelheid heeft geen
                      beperking. U kunt het babyvoedingpoeder zowel kant en klaar als in de poedervorm vervoeren. Aan boord kunt u warm water
                      verkrijgen, helaas beschikt men niet over opwarmmogelijkheden.
                      <br />
                      &nbsp;
                      <br />
                      Vervoer van uw buggy:
                      <br />
                      Alleen inklapbare lichtgewicht buggy's (zogenaamde paraplubuggy's) van 5-7 kilogram kunt u meenemen tot de gate. Overige buggy's
                      en kinderwagens moet u bij de check-in balie als ruimbagage inchecken.
                    </p>
                    <p>&nbsp;</p>
                  </div>
                </div>
              </div>
              <div className="card">
                <div className="card-header">
                  <h2 className="mb-0">
                    <span>Waarom wijzigen mijn vertrektijden?</span>
                  </h2>
                </div>
                <div className="collapse show">
                  <div>
                    Triptime biedt reizen aan met zowel chartervluchten als lijndiensten. Chartervluchtmaatschappijen zoals: Transavia, Pegasus en
                    anderen, hebben het recht om de vertrektijden tot kort voor vertrek te wijzigen. Echter zijn wij hiervan afhankelijk en hebben
                    helaas geen invloed op deze tijden.&nbsp;
                    <div>
                      <br />
                    </div>
                    <div>
                      De vertrektijden voor lijndiensten staan doorgaans vast. Houdt u hierbij wel rekening met afwijkende annuleringsvoorwaarden.
                    </div>
                  </div>
                </div>
              </div>
              <div className="card">
                <div className="card-header">
                  <h2 className="mb-0">
                    <span>Waarom zou ik een annuleringsverzekering afsluiten?</span>
                  </h2>
                </div>
                <div className="collapse show">
                  <div>
                    <p>
                      Met een annuleringsverzekering kunt u uw reis kosteloos annuleren als u hiervoor een geldige reden heeft. De verzekering vergoed
                      dan de kosten die hieraan verbonden zijn. Dit geldt ook als u uw reis moet onderbreken of zelfs afbreken. Een
                      annuleringsverzekering geldt voor de hele boeking. Overigens dekt een annuleringsverzekering ook de kosten van gemiste
                      vakantiedagen, bijvoorbeeld wanneer u met dagen vertraging op de plek van bestemming aankomt, en de kosten die u maakt als u om
                      onvoorziene redenen uw vakantie eerder dan gepland moet afbreken. Eenmaal een reis geboekt kan er altijd nog van alles gebeuren
                      waardoor de geplande vakantie of zakenreis niet door kan gaan of tijdens het verblijf in het water valt. Een reisorganisatie
                      betaalt een eenmaal geboekte reis echter niet zomaar terug. Een annuleringsverzekering is dan ook geen overbodige luxe en kan
                      veel financiële ellende voorkomen. Deze verzekering betaalt uw reissom terug of vergoed een vervangende vakantie in het geval u
                      uw reeds geboekte reis af moet zeggen of uw al begonnen vakantie onverwachts af moet breken. Gedekt zijn: ziekte en overlijden
                      (van u of uw directe familieleden), echtscheiding, schade aan uw woning, werkloosheid, (her)examen en geen visum kunnen krijgen.
                      Hoe groot het annuleringsrisico is, is voor iedereen verschillend. Bovendien kunnen er zich onvoorziene gebeurtenissen voordoen.
                      U kunt u echter verzekeren als uw situatie een eventuele annulering kan veroorzaken, bijvoorbeeld als gevolg van uw gezondheid
                      of die van iemand die u begeleidt, of van een gezinslid in de eerste of tweede graad.
                    </p>
                  </div>
                </div>
              </div>
              <div className="card">
                <div className="card-header">
                  <h2 className="mb-0">
                    <span>Waar kan ik zon speciaal plastic zakje vinden om het vloeistof in te doen?</span>
                  </h2>
                </div>
                <div className="collapse show">
                  <div>
                    Het doorzichtige plastic zakje dat aan de Europese eisen voldoet is verkrijgbaar in verschillende winkels. Wij adviseren u om het
                    zakje te bewaren voor de terugreis. Ook zijn van het merk Toppits hersluitbare diepvrieszakken met een inhoud van 1 liter
                    verkrijgbaar onder de naam Zipper.
                  </div>
                </div>
              </div>
              <div className="card">
                <div className="card-header">
                  <h2 className="mb-0">
                    <span>SGR / ANVR wat is dat?</span>
                  </h2>
                </div>
                <div className="collapse show">
                  <div>
                    <div>
                      <div>
                        De&nbsp;Stichting Garantiefonds Reisgelden&nbsp;staat garant voor uw vooruitbetaalde reisgeld of zorgt er bijvoorbeeld voor
                        dat uw verblijf of terugreis bij financieel onvermogen van de reisorganisatie alsnog wordt betaald.
                      </div>
                      <div>
                        Voor meer informatie: <a href="http://www.sgr.nl/">www.sgr.nl</a>
                      </div>
                      <div>
                        <div>
                          <br />
                        </div>
                        <div>De Algemene Nederlandse Vereniging van Reisondernemingen is een brancheorganisatie voor de reisbranche die de</div>
                        <div>gemeenschappelijke sociaal-economische belangen behartigt van de leden.</div>
                        <div>
                          Voor klanten betekent &nbsp;dit onder andere dat de reis en boekingsvoorwaarden die gehanteerd worden eerlijke voorwaarden
                          zijn.
                        </div>
                        <div>
                          Voor meer informatie :<a href="http://www.anvr.nl/">www.anvr.nl</a>
                        </div>
                        <div>
                          <br />
                        </div>
                        <div>
                          <div>
                            <br />
                          </div>
                          <div>
                            <br />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="card">
                <div className="card-header">
                  <h2 className="mb-0">
                    <span>Wat is het verschil tussen een chartervlucht en een lijndienst?</span>
                  </h2>
                </div>
                <div className="collapse show">
                  <div>
                    <p>
                      Bij een chartermaatschappij is het definitieve vluchtschema onder voorbehoud van wijzigingen. De luchtvaartmaatschappij kan tot
                      de dag van vertrek nog vluchtwijzigingen doorvoeren. Bij een lijndienst is het vluchtschema bij boeking direct bekend en
                      wijzigingen in de dienstregeling komen zelden voor, daarom zijn lijndienstvluchten veel duurder dan de chartervluchten.&nbsp;
                    </p>
                  </div>
                </div>
              </div>
              <div className="card">
                <div className="card-header">
                  <h2 className="mb-0">
                    <span>Wat zijn mijn vluchttijden? </span>
                  </h2>
                </div>
                <div className="collapse show">
                  <div>
                    <p>
                      De vluchttijden bij chartermaatschappijen worden ongeveer 5 dagen voor vertrek bekend gemaakt. Deze charters hebben het recht om
                      hun vluchten te wijzigen tot op de dag van vertrek. We kunnen echter niet aansprakelijk gesteld worden wanneer de vluchttijden
                      voor u minder gunstig uitpakken: Dit beslist de luchtvaartmaatschappij en hierop hebben wij geen enkele invloed.
                    </p>
                  </div>
                </div>
              </div>
              <div className="card">
                <div className="card-header">
                  <h2 className="mb-0">
                    <span>Wat moet ik doen als ik speciale assistentie nodig heb op de luchthaven?</span>
                  </h2>
                </div>
                <div className="collapse show">
                  <div>
                    Als het verzoek om assistentie bij uw reservering is ingediend, zal het grondpersoneel hiervan op de hoogte zijn en overeenkomstig
                    handelen. Ga zo vroeg mogelijk naar de incheckbalie; het grondpersoneel zal u verder helpen.
                  </div>
                </div>
              </div>
              <div className="card">
                <div className="card-header">
                  <h2 className="mb-0">
                    <span>Wat gebeurt er bij de incheckbalie?</span>
                  </h2>
                </div>
                <div className="collapse show">
                  <div>
                    De incheckagent zal u vragen om uw identiteit aan te tonen en uw bevestigingscode en geldige reisdocumenten te overleggen. Uw
                    ingecheckte bagage zal worden gewogen en vervolgens naar het bagageruim van het vliegtuig worden gestuurd. Tot slot zult u uw
                    instapkaart ontvangen.
                  </div>
                </div>
              </div>
              <div className="card">
                <div className="card-header">
                  <h2 className="mb-0">
                    <span>Welke elektronische apparaten mag ik aan boord gebruiken?</span>
                  </h2>
                </div>
                <div className="collapse show">
                  <div>
                    Elektronische apparatuur met een antenne moet te allen tijde uit zijn vanaf het moment dat u aan boord stapt en tijdens de vlucht.
                    Elektronische apparatuur zonder antenne, zoals cd, dvd, mp3-spelers en laptops , mogen gebruikt worden tijdens de vlucht. Zodra
                    het "Stoelriemen Vast" signaal aan is. Bij start en landing dient al uw elektronische apparatuur uitgezet en opgeborgen te worden.
                  </div>
                </div>
              </div>
              <div className="card">
                <div className="card-header">
                  <h2 className="mb-0">
                    <span>Zwangerschap</span>
                  </h2>
                </div>
                <div className="collapse show">
                  <div>
                    <p />
                    <p>Tot het eind van de 28ste week van de zwangerschap is geen doktersverklaring vereist.</p>
                    <p>
                      <br />
                    </p>
                    <p>Enkelvoudige ongecompliceerde zwangerschap</p>
                    <p>
                      -Deze zwangere vrouwen moeten een schriftelijk verklaring van een arts overleggen indien zij tussen 29 &nbsp;en 32 weken zwanger
                      zijn.
                    </p>
                    <p>-Bij zwangerschap langer dan 32 weken is vliegen niet mogelijk</p>
                    <p>-Doktersverklaringen mogen maximaal 7 dagen ouder zijn dan de vluchtdatum</p>
                    <p>
                      <br />
                    </p>
                    <p>Meervoudige ongecompliceerde zwangerschap</p>
                    <p>
                      -Deze zwangere vrouwen moeten een schriftelijk verklaring van een arts overleggen indien zij tussen 29 &nbsp;en 32 weken zwanger
                      zijn.
                    </p>
                    <p>-Bij zwangerschap langer dan 32 weken is vliegen niet mogelijk</p>
                    <p>-Doktersverklaringen mogen maximaal 7 dagen ouder zijn dan de vluchtdatum</p>
                    <p>
                      <br />
                    </p>
                    <p>Gecompliceerde zwangerschap</p>
                    <p>-Deze zwangere vrouwen moeten beschikken over een doktersverklaring ongeacht de fase van hun zwangerschap.&nbsp;</p>
                    <p>-Doktersverklaringen mogen maximaal 7 dagen ouder zijn dan de vluchtdatum</p>
                    <div>
                      <br />
                    </div>
                    <p />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

//export default connect((state) => (state))(withRouter(Faq));
function mapStateToProps(state) {
  return { ...state };
}

const mapDispatchToProps = dispatch => bindActionCreators({ setSystemText, setBlogList3 }, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(
  frontloadConnect(frontload, {
    onMount: true,
    onUpdate: false
  })(Faq)
);
