import 'isomorphic-fetch';
import { getApiUrl } from '../routes/shared/functions';

export default urlPath => {
  let body = {
    method: 'POST',
    body: JSON.stringify({ url: '/ghfchgfhg' }),
    headers: {
      'Content-Type': 'application/json'
    }
  };


  return fetch(getApiUrl() + '/GetRedirectMap', body)
    .then(res => {
      return res.json();
    })
    .then(data => {
      // only keep 10 first results
      // return data.filter((_, idx) => idx < 10);
      return data;
    });
};

export async function getDataAsync(method, data) {
  let options = {
    method: 'POST',
    body: JSON.stringify(data),

    headers: { 'Content-Type': 'application/json' }
  };

  let response = await fetch(getApiUrl() + '/' + method, options);

  let returnData = await response.json();

  return returnData;
}

// export default resourceType => {
//   return fetch(`https://jsonplaceholder.typicode.com/${resourceType}`)
//     .then(res => {
//       return res.json();
//     })
//     .then(data => {
//       // only keep 10 first results
//       return data.filter((_, idx) => idx < 10);
//     });
// };
//
