import { krax } from 'react-krax';
import { getApiUrl, getLocale } from '../shared/functions';
import { client as axios } from '../../api/config/axiosConfig';

let rootUrl = getApiUrl(); //prodapi;

export const getAirports = searchstr => {
  let _body = { searchstr: searchstr };

  return krax({
    name: 'airports',
    request: {
      isJson: true,
      url: process.env.REACT_APP_API + '/airports',
      body: _body,
      method: 'GET',
      headers: { 'Content-Type': 'application/json', accept: '*/*' }
    }
  });
};

export const GetCountryVisa = languageID => {
  let _body = { languageID: languageID };

  return krax({
    name: 'CountryVisa',
    request: {
      isJson: true,
      url: rootUrl + '/GetCountryVisa',
      body: _body,
      method: 'POST',
      headers: { 'Content-Type': 'application/json' }
    }
  });
};

export const GetRedirectMap = url => {
  let _body = { url: url };

  return krax({
    name: 'redirectMap',
    request: {
      isJson: true,
      url: rootUrl + '/GetRedirectMap',
      body: _body,
      method: 'POST',
      headers: { 'Content-Type': 'application/json' }
    }
  });
};

export const GetResProduct = languageID => {
  let _body = { languageID: languageID };

  return krax({
    name: 'resproduct',
    request: {
      isJson: true,
      url: rootUrl + '/GetResProduct',
      body: _body,
      method: 'POST',
      headers: { 'Content-Type': 'application/json' }
    }
  });
};

export const getBlog = (categoryID, languageID) => {
  return axios.get(`${process.env.REACT_APP_API}/cms/dynamic-page-category?categoryId=${categoryID}&languageId=${languageID}`);
};

export const getBanners = (name, top, languageID, depID, arrID, userID, AirlineID) => {
  let _body = { top: top, languageID: languageID, depID: depID, arrID: arrID, userID: userID, AirlineID: AirlineID };

  return krax({
    name: name,
    request: {
      isJson: true,
      url: rootUrl + '/GetBanner',
      body: _body,
      method: 'POST',
      headers: { 'Content-Type': 'application/json' }
    }
  });
};

export const getBannersAirlineID = (name, top, languageID, depID, arrID, userID, AirlineID) => {
  let _body = { top: top, languageID: languageID, depID: depID, arrID: arrID, userID: userID, airlineID: AirlineID };

  return krax({
    name: name,
    request: {
      isJson: true,
      url: rootUrl + '/GetBannerAirlineID',
      body: _body,
      method: 'POST',
      headers: { 'Content-Type': 'application/json' }
    }
  });
};

export const getBannersCityID = (name, top, languageID, userID, cityID) => {
  let _body = { top: top, languageID: languageID, userID: userID, cityID: cityID };

  return krax({
    name: name,
    request: {
      isJson: true,
      url: rootUrl + '/GetBannerCityID',
      body: _body,
      method: 'POST',
      headers: { 'Content-Type': 'application/json' }
    }
  });
};

export const getBannersAnd = (name, top, languageID, depID, arrID) => {
  let _body = { top: top, languageID: languageID, depID: depID, arrID: arrID };

  return krax({
    name: name,
    request: {
      isJson: true,
      url: rootUrl + '/GetBannerAnd',
      body: _body,
      method: 'POST',
      headers: { 'Content-Type': 'application/json' }
    }
  });
};

export const getBannersAndAirline = (name, top, languageID, depID, arrID, airlineID) => {
  let _body = { top: top, languageID: languageID, depID: depID, arrID: arrID, airlineID: airlineID };

  return krax({
    name: name,
    request: {
      isJson: true,
      url: rootUrl + '/GetBannerAndAirline',
      body: _body,
      method: 'POST',
      headers: { 'Content-Type': 'application/json' }
    }
  });
};

export const sendNotFound = url => {
  let _body = { url: url };

  return krax({
    name: 'sendNotFound',
    request: {
      isJson: true,
      url: rootUrl + '/sendNotFound',
      body: _body,
      method: 'POST',
      headers: { 'Content-Type': 'application/json' }
    }
  });
};

export const getTripFlex = (type, segmentcount) => {
  let _body = { type: type, segmentcount: segmentcount };

  return krax({
    name: 'tripflex',
    request: {
      isJson: true,
      url: rootUrl + '/GetTripFlex',
      body: _body,
      method: 'POST',
      headers: { 'Content-Type': 'application/json' }
    }
  });
};

export const generateTaxes = (totalprice, cancel, sms, adultcount, childcount, babycount, sessionid, tripFlexPrice) => {
  let _body = {
    totalprice: totalprice,
    cancel: cancel,
    sms: sms,
    adultcount: adultcount,
    childcount: childcount,
    babycount: babycount,
    sessionid: sessionid,
    tripFlexPrice: tripFlexPrice
  };

  return krax({
    name: 'taxes',
    request: {
      isJson: true,
      url: rootUrl + '/Taxes',
      body: _body,
      method: 'POST',
      headers: { 'Content-Type': 'application/json' }
    }
  });
};

export const getSystemText = (pageID, languageID, suffix = '') => {
  return new Promise((resolve, reject) => {
    resolve('');
  });
};

export const getWeatherApi = (city, date, lang, hour) => {
  let _body = { city: city, date: date, lang: lang, hour: hour };
  return krax({
    name: 'SystemWeather',
    request: {
      isJson: true,
      url: rootUrl + '/GetWeatherApi',
      body: _body,
      method: 'POST',
      headers: { 'Content-Type': 'application/json' }
    }
  });
};

export const getDate = (suffix = "") => {
  return new Promise((resolve, reject) => {
    let object = {};
    object.data = {};
    axios.get(process.env.REACT_APP_API + "/date-time").then((response) => {
      object.data.d = response.data;
      resolve(object);
    });
  })
};

export const getMenu = (_parentID, _languageID, suffix = "") => {
  return axios.get(`${process.env.REACT_APP_API}/cms/sitemenu?siteMenuId=${_parentID}${_languageID && '&languageId=' + _languageID}`);
};

export const getFAQ = (_search, _languageID, suffix = '') => {
  return krax({
    name: 'siteFAQ' + suffix,
    request: {
      isJson: true,
      url: rootUrl + '/GetFaq',
      body: { searchStr: _search, languageID: _languageID },
      method: 'POST',
      headers: { 'Content-Type': 'application/json' }
    }
  });
};

export const getIP = (suffix = '') => {
  let _body = {};

  return krax({
    name: 'ip' + suffix,
    request: {
      isJson: true,
      url: rootUrl + '/GetIP',
      body: _body,
      method: 'POST',
      headers: { 'Content-Type': 'application/json' }
    }
  });
};

export const getReservationBasketTravellers = (sessionID, languageID) => {
  let _body = { sessionID: sessionID, languageID: languageID };

  return krax({
    name: 'BasketTraveller',
    request: {
      isJson: true,
      url: rootUrl + '/GetTravellers',
      body: _body,
      method: 'POST',
      headers: { 'Content-Type': 'application/json' }
    }
  });
};

export const GetReservationBasketFlights = (sessionID, languageID) => {
  let _body = { sessionID: sessionID, languageID: languageID };

  return krax({
    name: 'BasketFlight',
    request: {
      isJson: true,
      url: rootUrl + '/GetReservationBasketFlights',
      body: _body,
      method: 'POST',
      headers: { 'Content-Type': 'application/json' }
    }
  });
};

export const GetAccountInfo = (sessionID, languageID) => {
  let _body = { sessionID: sessionID, languageID: languageID };

  return krax({
    name: 'AccountInfo',
    request: {
      isJson: true,
      url: rootUrl + '/GetAccountInfo',
      body: _body,
      method: 'POST',
      headers: { 'Content-Type': 'application/json' }
    }
  });
};

export const GetGenders = (languageID, traTypeID) => {
  let _body = { languageID: languageID, traTypeID: traTypeID };

  return krax({
    name: 'AccountInfo',
    request: {
      isJson: true,
      url: rootUrl + '/GetAccountInfo',
      body: _body,
      method: 'POST',
      headers: { 'Content-Type': 'application/json' }
    }
  });
};

export const GetReservationAccountInfo = sessionId => {
  let _body = { sessionid: sessionId };

  return krax({
    name: 'ReservationAccountInfo',
    request: {
      isJson: true,
      url: process.env.REACT_APP_API + '/shopping-cart',
      body: _body,
      method: 'POST',
      headers: { 'Content-Type': 'application/json' }
    }
  });
};

export const getIdealUrl = (pnr, price, issuer, issuername) => {
  let _body = {
    pnr: pnr,
    price: price,
    issuer: issuer,
    issuername: issuername
  };

  return krax({
    name: 'ideal',
    request: {
      isJson: true,
      url: rootUrl + '/getIdealUrl',
      body: _body,
      method: 'POST',
      headers: { 'Content-Type': 'application/json' }
    }
  });
};

export const DoTransaction = (transid, languageid) => {
  let _body = { transID: transid, languageID: languageid };

  return krax({
    name: 'transactionResult',
    request: {
      isJson: true,
      url: rootUrl + '/doTransaction',
      body: _body,
      method: 'POST',
      headers: { 'Content-Type': 'application/json' }
    }
  });
};

export const DoTransactionTest = (transid, languageid) => {
  let _body = { pnr: transid, languageID: languageid };

  return krax({
    name: 'transactionResult',
    request: {
      isJson: true,
      url: rootUrl + '/doTransactionTest',
      body: _body,
      method: 'POST',
      headers: { 'Content-Type': 'application/json' }
    }
  });
};

//

export const GetTravellerVisa = (pnr, TID) => {
  let _body = { pnr: pnr, travellerID: TID };

  return krax({
    name: 'TravellerVisa',
    request: {
      isJson: true,
      url: rootUrl + '/GetTravellerVisa',
      body: _body,
      method: 'POST',
      headers: { 'Content-Type': 'application/json' }
    }
  });
};

export const getreservationInfo = sessionId => {
  let _body = { sessionId: sessionId };
  let languageParam = ""

  switch(getLocale()){
    case "tr-TR":
      languageParam = "TR"
      break;
    case "en-GB":
      languageParam = "EN"
      break;
    case "de-DE":
      languageParam = "DE"
      break;
    default:
      languageParam = "NL"
      break;
  }

  return krax({
    name: 'ReservationInfo',
    request: {
      isJson: true,
      url: process.env.REACT_APP_API + `/reservation/contact?sessionId=${sessionId}&language=${languageParam}`,
      method: 'GET',
      headers: { 'Content-Type': 'application/json' }
    }
  });
};

export const GetReservationFlights = sessionid => {
  let _body = { sessionid: sessionid };

  return krax({
    name: 'ReservationFlights',
    request: {
      isJson: true,
      url: process.env.REACT_APP_API + '/shopping-cart/details',
      body: _body,
      method: 'POST',
      headers: { 'Content-Type': 'application/json' }
    }
  });
};

export const GetReservationPassengers = (pnr, languageID) => {
  let _body = { pnr: pnr, languageID: languageID };

  return krax({
    name: 'ReservationPassenger',
    request: {
      isJson: true,
      url: rootUrl + '/GetReservationTravellers',
      body: _body,
      method: 'POST',
      headers: { 'Content-Type': 'application/json' }
    }
  });
};

export const GetReservationAccountInfoPayment = (pnr, languageID) => {
  let _body = { pnr: pnr, languageID: languageID };

  return krax({
    name: 'ReservationAccountInfoPayment',
    request: {
      isJson: true,
      url: rootUrl + '/GetReservationAccountInfoPayment',
      body: _body,
      method: 'POST',
      headers: { 'Content-Type': 'application/json' }
    }
  });
};

export const GetDynamicPage = (DynID, languageID) => {
  return axios.get(`${process.env.REACT_APP_API}/cms/dynamic-page?pageId=${DynID}&languageId=${languageID}`);
};

export const GetDynamicPage2 = (DynID, languageID, subItems) => {
  return axios.get(`${process.env.REACT_APP_API}/cms/dynamic-page?pageId=${DynID}&languageId=${languageID}`);
};

export const GetUrlFromSeoUrl = url => {
  let _body = { url: url };

  return krax({
    name: 'GetUrlFromSeoUrl',
    request: {
      isJson: true,
      url: rootUrl + '/GetUrlFromSeoUrl',
      body: _body,
      method: 'POST',
      headers: { 'Content-Type': 'application/json' }
    }
  });
};

export const GetSiteMapRoutes = (languageID, url) => {
  return axios.get(`${process.env.REACT_APP_API}/cms/sitemap-routes?languageId=${languageID}${url ? '&url=' + url : ''}`);
};

export const GetAirportWithCity = (city, languageID) => {
  let _body = { city: city, languageID: languageID };

  return krax({
    name: 'GetAirportWithCity',
    request: {
      isJson: true,
      url: rootUrl + '/GetAirportWithCity',
      body: _body,
      method: 'POST',
      headers: { 'Content-Type': 'application/json' }
    }
  });
};

export const GetTravellerSSR = languageID => {
  let _body = { languageID: languageID };

  return krax({
    name: 'TravellerSSR',
    request: {
      isJson: true,
      url: rootUrl + '/getTravellerSSR',
      body: _body,
      method: 'POST',
      headers: { 'Content-Type': 'application/json' }
    }
  });
};

export const GetPaymentIssuers = (sessionID, paymentType) => {
  let data = {
    sessionid: sessionID,
    shoppingPaymentRequest: {
      paymentType: paymentType,
      issuers: true
    }
  };
  return axios.post('/issuers', data);
};

export const CheckVendorPrices = (pnr, languageID) => {
  let _body = { pnr: pnr, languageID: languageID };

  return krax({
    name: 'VendorPrices',
    request: {
      isJson: true,
      url: rootUrl + '/CheckVendorPrices',
      body: _body,
      method: 'POST',
      headers: { 'Content-Type': 'application/json' }
    }
  });
};

export const CheckMisterCash = (pnr, languageID, price, typeCC, sid) => {
  let _body = {
    pnr: pnr,
    languageID: languageID,
    price: price,
    typeCC: typeCC,
    sessionID: sid
  };

  return krax({
    name: 'MisterCash',
    request: {
      isJson: true,
      url: rootUrl + '/CheckMisterCashV2',
      body: _body,
      method: 'POST',
      headers: { 'Content-Type': 'application/json' }
    }
  });
};

export const AgentValidate = (userName, providerkey) => {
  let _body = { userName: userName, providerkey: providerkey };

  return krax({
    name: 'AgentValidate',
    request: {
      isJson: true,
      url: rootUrl + '/agentValidate',
      body: _body,
      method: 'POST',
      headers: { 'Content-Type': 'application/json' }
    }
  });
};

export const AgentLogin = (userName, passWord) => {
  let _body = { userName: userName, passWord: passWord };

  return krax({
    name: 'AgentLogin',
    request: {
      isJson: true,
      url: rootUrl + '/agentLogin',
      body: _body,
      method: 'POST',
      headers: { 'Content-Type': 'application/json' }
    }
  });
};

export const ClientLogin = (userName, passWord, languageID) => {
  let _body = { username: userName, password: passWord, languageID: languageID };

  return krax({
    name: 'ClientLogin',
    request: {
      isJson: true,
      url: rootUrl + '/clientLogin',
      body: _body,
      method: 'POST',
      headers: { 'Content-Type': 'application/json' }
    }
  });
};

export const UserRememberPass = (email, languageID) => {
  let _body = { email: email, languageID: languageID };

  return krax({
    name: 'sendResetPassToken',
    request: {
      isJson: true,
      url: rootUrl + '/sendResetPassToken',
      body: _body,
      method: 'POST',
      headers: { 'Content-Type': 'application/json' }
    }
  });
};

export const ClientSave = (gender, name, surname, email, password, tel, telcode, LanguageID) => {
  let _body = {
    customerData: {
      GenderID: gender,
      Name: name,
      Surname: surname,
      CompanyName: '',
      Country: '',
      City: '',
      PostCode: '',
      Adres: '',
      TelCode: '',
      Tel: '',
      FaksCode: '',
      Faks: '',
      GsmCode: telcode,
      Gsm: tel,
      Email: email,
      Note: '',
      Referention: '',
      UserName: ''
    },
    password: password,
    languageID: LanguageID
  };

  return krax({
    name: 'ClientSave',
    request: {
      isJson: true,
      url: rootUrl + '/insertClient',
      body: _body,
      method: 'POST',
      headers: { 'Content-Type': 'application/json' }
    }
  });
};

export const ClientPassChange = (clientid, userName, passWord, newpassWord) => {
  let _body = { clientID: clientid, username: userName, oldpass: passWord, newpass: newpassWord };

  return krax({
    name: 'updateClientPassword',
    request: {
      isJson: true,
      url: rootUrl + '/updateClientPassword',
      body: _body,
      method: 'POST',
      headers: { 'Content-Type': 'application/json' }
    }
  });
};

export const ClientRememberPassChange = (key, passWord, newpassWord, languageID) => {
  let _body = { token: key, newpass: passWord, newpass2: newpassWord, languageID: languageID };

  return krax({
    name: 'updateClientPasswordToken',
    request: {
      isJson: true,
      url: rootUrl + '/updateClientPasswordToken',
      body: _body,
      method: 'POST',
      headers: { 'Content-Type': 'application/json' }
    }
  });
};

export const ClientInfoChange = (clientid, GenderID, name, surname, company, land, city, postcode, adres, telcode, tel, email) => {
  let _body = {
    clientID: clientid,
    customerData: {
      GenderID: GenderID,
      Name: name,
      Surname: surname,
      CompanyName: company,
      Country: land,
      City: city,
      PostCode: postcode,
      Adres: adres,
      TelCode: '',
      Tel: '',
      FaksCode: '',
      Faks: '',
      GsmCode: telcode,
      Gsm: tel,
      Email: email,
      Note: '',
      Referention: '',
      UserName: ''
    }
  };

  return krax({
    name: 'updateClient',
    request: {
      isJson: true,
      url: rootUrl + '/updateClient',
      body: _body,
      method: 'POST',
      headers: { 'Content-Type': 'application/json' }
    }
  });
};

export const SetSendFriends = (
  depID,
  retID,
  SearchType,
  oneWay,
  depDate,
  retDate,
  txtUWnaam,
  txtEmailontvanger,
  txtOpmerking,
  txtNaamontvanger,
  txtUWemailadres,
  url
) => {
  let _body = {
    depID: depID,
    retID: retID,
    SearchType: SearchType,
    oneWay: oneWay,
    depDate: depDate,
    retDate: retDate,
    txtUWnaam: txtUWnaam,
    txtEmailontvanger: txtEmailontvanger,
    txtOpmerking: txtOpmerking,
    txtNaamontvanger: txtNaamontvanger,
    txtUWemailadres: txtUWemailadres,
    url: url
  };

  return krax({
    name: 'SetSendFriends',
    request: {
      isJson: true,
      url: rootUrl + '/SetSendFriends',
      body: _body,
      method: 'POST',
      headers: { 'Content-Type': 'application/json' }
    }
  });
};

export const GetFriends = sfID => {
  let _body = { sfID: sfID };

  return krax({
    name: 'GetSendFriends',
    request: {
      isJson: true,
      url: rootUrl + '/GetSendFriends',
      body: _body,
      method: 'POST',
      headers: { 'Content-Type': 'application/json' }
    }
  });
};

export const GetClientTripCreditSummary = (clientid, airlineid) => {
  let _body = { clientId: clientid, airlineId: airlineid };

  return krax({
    name: 'GetClientTripCreditSummary',
    request: {
      isJson: true,
      url: rootUrl + '/GetClientTripCreditSummary',
      body: _body,
      method: 'POST',
      headers: { 'Content-Type': 'application/json' }
    }
  });
};

export const GetClientDetail = clientid => {
  let _body = { clientID: clientid };

  return krax({
    name: 'GetClient',
    request: {
      isJson: true,
      url: rootUrl + '/GetClient',
      body: _body,
      method: 'POST',
      headers: { 'Content-Type': 'application/json' }
    }
  });
};

export const saveClientSearchPageLog = (clientSearchId, pageID) => {
  let _body = { clientSearchId, pageID };

  return krax({
    name: 'saveClientSearchPageLog',
    request: {
      isJson: true,
      url: rootUrl + '/saveClientSearchPageLog',
      body: _body,
      method: 'POST',
      headers: { 'Content-Type': 'application/json' }
    }
  });
};

export const GetClientTripCredit = (clientid, airlineid) => {
  let _body = { clientId: clientid, airlineId: airlineid };

  return krax({
    name: 'GetClientTripCredit',
    request: {
      isJson: true,
      url: rootUrl + '/GetClientTripCredit',
      body: _body,
      method: 'POST',
      headers: { 'Content-Type': 'application/json' }
    }
  });
};

export const GetClientTripCreditUser = clientid => {
  let _body = { clientId: clientid };

  return krax({
    name: 'GetClientTripCreditUser',
    request: {
      isJson: true,
      url: rootUrl + '/GetClientTripCreditUser',
      body: _body,
      method: 'POST',
      headers: { 'Content-Type': 'application/json' }
    }
  });
};

export const SaveBasketTripCredit = (clientId, triptCreditID, reservationBasketAccountID) => {
  let _body = { clientId: clientId, tripCreditID: triptCreditID, reservationBasketAccountID: reservationBasketAccountID };

  return krax({
    name: 'saveResBasketAccountTripCredit',

    request: {
      isJson: true,
      url: rootUrl + '/saveResBasketAccountTripCredit',
      body: _body,
      method: 'POST',
      credentials: 'same-origin'
    }
  });
};

export const SaveResBasketAccountResProduct = (sessionID, productID, count, languageID, proname, icon, ship) => {
  let _body = { sessionID, productID, count, languageID, proname, icon, ship };

  return krax({
    name: 'saveResBasketAccountResProductv2',

    request: {
      isJson: true,
      url: rootUrl + '/saveResBasketAccountResProductv2',
      body: _body,
      method: 'POST',
      credentials: 'same-origin'
    }
  });
};

export const SaveBasketTripCreditAcc = (clientId, triptCreditID, reservationBasketAccountID, amount) => {
  let _body = { clientId: clientId, tripCreditID: triptCreditID, reservationBasketAccountID: reservationBasketAccountID, accAmount: amount };

  return krax({
    name: 'saveResBasketAccountTripCreditAcc',

    request: {
      isJson: true,
      url: rootUrl + '/saveResBasketAccountTripCreditAcc',
      body: _body,
      method: 'POST',
      credentials: 'same-origin'
    }
  });
};

export const clearTripCreditPayments = sessionID => {
  let _body = { sessionID: sessionID };

  return krax({
    name: 'clearTripCreditPayments',

    request: {
      isJson: true,
      url: rootUrl + '/clearTripCreditPayments',
      body: _body,
      method: 'POST',
      credentials: 'same-origin'
    }
  });
};

export const ClientList = (ClientID, StartDate, EndDate, TraStartDate, TraEndDate, InvoiceNo, Pnr, NameSurname, Balance, SecilenSayfa) => {
  let _body = {
    ClientID: ClientID,
    StartDate: StartDate,
    EndDate: EndDate,
    TraStartDate: TraStartDate,
    TraEndDate: TraEndDate,
    InvoiceNo: InvoiceNo,
    Pnr: Pnr,
    NameSurname: NameSurname,
    Balance: Balance,
    SecilenSayfa: SecilenSayfa
  };

  return krax({
    name: 'ClientList',
    request: {
      isJson: true,
      url: rootUrl + '/GetReservationList',
      body: _body,
      method: 'POST',
      headers: { 'Content-Type': 'application/json' }
    }
  });
};

export const doReservation = (sessionid, languageID, travellers, customerData, userGuid) => {
  let _body = {
    sessionid: sessionid,
    languageID: languageID,
    travellers: travellers,
    customerData: customerData,
    userGuid: userGuid
  };
  //d

  return krax({
    name: 'doReservation',

    request: {
      isJson: true,
      url: rootUrl + '/DoReservation',
      body: _body,
      method: 'POST',
      credentials: 'same-origin'
    }
  });
};

export const clientSearchLog = (ClientUID, DepartureID, ArrivalID, DepartureDate, ReturnDate, SearchType, AdultCount, ChildCount, BabyCount) => {
  let _body = {
    ClientUID,
    DepartureID,
    ArrivalID,
    DepartureDate,
    ReturnDate,
    SearchType,
    AdultCount,
    ChildCount,
    BabyCount
  };

  return krax({
    name: 'clientSearchLog',

    request: {
      isJson: true,
      url: rootUrl + '/clientSearchLog',
      body: _body,
      method: 'POST',
      credentials: 'same-origin'
    }
  });
};

export const saveContact = (label, name, middleName, surname, adres, email, comment, place, postCode, tel) => {
  let _body = {
    label: label,
    name: name,
    middleName: middleName,
    surname: surname,
    adres: adres,
    email: email,
    comment: comment,
    place: place,
    postCode: postCode,
    tel: tel
  };

  return krax({
    name: 'saveContact',

    request: {
      isJson: true,
      url: rootUrl + '/saveContact',
      body: _body,
      method: 'POST',
      credentials: 'same-origin'
    }
  });
};

export const SaveTravellerVisa = (
  pnr,
  travellerID,
  visaID,
  reservationID,
  passIssueDay,
  passIssueMonth,
  passIssueYear,
  expireDay,
  expireMonth,
  expireYear,
  birthDay,
  birthMonth,
  birthYear,
  name,
  surName,
  placeOfBirth,
  mothersName,
  fathersName,
  passportNo,
  email,
  adres,
  telNo,
  cardType,
  postCode,
  city,
  country,
  nationalityID,
  languageID
) => {
  let _body = {
    pnr: pnr,
    travellerID: travellerID,
    visaID: visaID,
    reservationID: reservationID,
    passIssueDay: passIssueDay,
    passIssueMonth: passIssueMonth,
    passIssueYear: passIssueYear,
    expireDay: expireDay,
    expireMonth: expireMonth,
    expireYear: expireYear,
    birthDay: birthDay,
    birthMonth: birthMonth,
    birthYear: birthYear,
    name: name,
    surName: surName,
    placeOfBirth: placeOfBirth,
    mothersName: mothersName,
    fathersName: fathersName,
    passportNo: passportNo,
    email: email,
    adres: adres,
    telNo: telNo,
    cardType: cardType,
    postCode: postCode,
    city: city,
    country: country,
    nationalityID: nationalityID,
    languageID: languageID
  };

  return krax({
    name: 'SaveTravellerVisa',

    request: {
      isJson: true,
      url: rootUrl + '/SaveTravellerVisa',
      body: _body,
      method: 'POST',
      credentials: 'same-origin'
    }
  });
};

export const SaveNewsletteremail = email => {
  let _body = { email: email };

  return krax({
    name: 'SaveEmailSender',

    request: {
      isJson: true,
      url: rootUrl + '/SaveEmailSender',
      body: _body,
      method: 'POST',
      credentials: 'same-origin'
    }
  });
};

export const getFlights = (
  _sourceGrid,
  _tarih,
  _departureID,
  _arrivalID,
  _adultCount,
  _childCount,
  _babyCount,
  _AddDays,
  _adddays,
  _LanguageID,
  _directflights,
  _istAlternates,
  _xmlTypes,
  _DepartureAlternate,
  _ReturnAlternate,
  _FromCountryID,
  _ToCountryID,
  _xmlAlternates,
  _sessionID,
  _isReturn,
  _iatiType,
  _corendonDe
) => {
  function getCookie(cname) {
    if(typeof document == "undefined") return '';
    let name = cname + '=';
    let decodedCookie = decodeURIComponent(document.cookie);
    let ca = decodedCookie.split(';');
    for (let i = 0; i < ca.length; i++) {
      let c = ca[i];
      while (c.charAt(0) == ' ') {
        c = c.substring(1);
      }
      if (c.indexOf(name) == 0) {
        return c.substring(name.length, c.length);
      }
    }
    return '';
  }
  let languageCode = '';
  switch (getCookie('languageID')) {
    case '1':
      languageCode = 'EN';
      break;
    case '2':
      languageCode = 'DE';
      break;
    case '3':
      languageCode = 'NL';
      break;
    case '5':
      languageCode = 'TR';
      break;
    default:
      languageCode = 'NL';
      break;
  }
  let maxStop = 0;
  if (_directflights === 0) {
    maxStop = 1;
  }
  let _body = {
    departureDate: _tarih.split('-')[0].length === 2 ? _tarih.split('-')[2] + '-' + _tarih.split('-')[1] + '-' + _tarih.split('-')[0] : _tarih,
    departureCode: _departureID,
    arrivalCode: _arrivalID,
    adultCount: parseInt(_adultCount),
    childCount: parseInt(_childCount),
    infantCount: parseInt(_babyCount),
    providerList: null,
    alternativeCountry: _DepartureAlternate === 0 ? null : _DepartureAlternate,
    language: languageCode,
    maxStop: maxStop
  };

  return krax({
    name: 'flights_' + _sourceGrid,

    request: {
      isJson: true,
      url: process.env.REACT_APP_API + '/availability',
      body: _body,
      method: 'POST',
      credentials: 'same-origin',

      cache: 'no-cache',
      redirect: 'follow',
      referrer: '',

      headers: {
        'Content-Type': 'application/json',
        Accept: '*/*',
        'Accept-Encoding': 'gzip, deflate',
        Connection: 'keep-alive',
        Cookie: 'ASP.NET_SessionId=jupmggtdgobm5ktwuvxj3jw2',
        Host: 'www.triptime.nl'
      }
    }
  });
};