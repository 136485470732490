import * as moment from 'moment';
import _ from 'lodash';
import Cookies from 'js-cookie';
import * as React from 'react';
import { Redirect, Route } from 'react-router';
import { ConsoleView } from 'react-device-detect';

export function getApiUrl() {
  let testapi = 'https://prod.triptime.nl/service.aspx';
  let localapi = 'http://localhost:32640/service.aspx';
  let prodapi = 'https://beta.triptime.nl/service.aspx'; //"https://webapi.triptime.nl/service.aspx";

  let rootUrl = prodapi;

  return rootUrl;
}

export function convertToShortDate(date) {
  if (date === '0001-01-01T00:00:00+00:00') {
    return '';
  }
  if (date === undefined) {
    return '';
  }

  let dates = date.split('+');
  if (dates.length < 2) {
    dates = date.split(' ');
  }
  if (dates.length === 2) {
    date = dates[0];
    //moment().format("MMM Do YY");
    moment.locale('tr');
    //var retval =  moment('25/04/2019').format('l LT');
    return date; // .format("DD.MM.YYYY HH:mm")
  }
  return date;
}

export function makeTwo(val) {
  if (String(val).length === 1) val = '0' + String(val);
  return val;
}
//
// export function GetFormattedDate(datum) {
//     moment.locale();
//     var fulldate = moment().format(datum, 'L');
//     return fulldate.split(" ")[0];
//
// }

/*export function getControlText(id, dataSource) {
  if (!dataSource) return { __type: "", Text: "", SystemTextID: "0" };


  let orj = _.filter(dataSource, function(o) {
    return  o ?  o.SystemTextID == id : '';
  });

  return orj[0];
}*/

export function getControlText(id, array) {
  let data = typeof window !== "undefined" ? window.sessionStorage.getItem(id) : '';
  if (!data) return { Text: '' };
  return { Text: data };
}

export function getLanguageID() {
  let languageID = '3';
  let data = Cookies.get('languageID');
  if (!data) {
    let getLanguage = 'nl';

    switch (getLanguage) {
      case 'tr':
        languageID = '5';
        break;
      case 'en':
        languageID = '1';
        break;
      case 'de':
        languageID = '2';
        break;
      default:
        languageID = '3';
    }
  } else languageID = data;
  return parseInt(languageID);
}

export function getLocale() {
  let data = Cookies.get('languageID');
  let locale;

  if (data === '5') locale = 'tr-TR';
  else if (data === '1') locale = 'en-GB';
  else if (data === '2') locale = 'de-DE';
  else locale = 'nl-NL';

  return locale;
}

export function getTelNumbers() {
  return [
    { value: 31, text: '+31 NETHERLANDS' },
    { value: 90, text: '+90 TURKEY' },
    { value: 49, text: '+49 GERMANY' },
    { value: 32, text: '+32 BELGIUM' },
    { value: 1, text: '+1 UNITED STATES' },
    { value: 44, text: '+44 UNITED KINGDOM' },
    { value: 7, text: '+7 RUSSIA' },
    { value: 33, text: '+33 FRANCE' },
    { value: 34, text: '+34 SPAIN' },
    { value: 81, text: '+81 JAPAN' },
    { value: 39, text: '+39 ITALY' },
    { value: 93, text: '+93 AFGHANISTAN' },
    { value: 355, text: '+355 ALBANIA' },
    { value: 213, text: '+213 ALGERIA' },
    { value: 1684, text: '+1684 AMERICAN SAMOA' },
    { value: 376, text: '+376 ANDORRA' },
    { value: 244, text: '+244 ANGOLA' },
    { value: 1264, text: '+1264 ANGUILLA' },
    { value: 1268, text: '+1268 ANTIGUA AND BARBUDA' },
    { value: 54, text: '+54 ARGENTINA' },
    { value: 374, text: '+374 ARMENIA' },
    { value: 297, text: '+297 ARUBA' },
    { value: 61, text: '+61 AUSTRALIA' },
    { value: 43, text: '+43 AUSTRIA' },
    { value: 994, text: '+994 AZERBAIJAN' },
    { value: 1242, text: '+1242 BAHAMAS' },
    { value: 973, text: '+973 BAHRAIN' },
    { value: 880, text: '+880 BANGLADESH' },
    { value: 1246, text: '+1246 BARBADOS' },
    { value: 375, text: '+375 BELARUS' },
    { value: 501, text: '+501 BELIZE' },
    { value: 229, text: '+229 BENIN' },
    { value: 1441, text: '+1441 BERMUDA' },
    { value: 975, text: '+975 BHUTAN' },
    { value: 591, text: '+591 BOLIVIA' },
    { value: 387, text: '+387 BOSNIA AND HERZEGOVINA' },
    { value: 267, text: '+267 BOTSWANA' },
    { value: 55, text: '+55 BRAZIL' },
    { value: 673, text: '+673 BRUNEI' },
    { value: 359, text: '+359 BULGARIA' },
    { value: 226, text: '+226 BURKINA FASO' },
    { value: 257, text: '+257 BURUNDI' },
    { value: 855, text: '+855 CAMBODIA' },
    { value: 237, text: '+237 CAMEROON' },
    { value: 1, text: '+1 CANADA' },
    { value: 238, text: '+238 CAPE VERDE' },
    { value: 1345, text: '+1345 CAYMAN ISLANDS' },
    { value: 236, text: '+236 CENTRAL AFRICAN REPUBLIC' },
    { value: 235, text: '+235 CHAD' },
    { value: 56, text: '+56 CHILE' },
    { value: 86, text: '+86 CHINA' },
    { value: 57, text: '+57 COLOMBIA' },
    { value: 2693, text: '+2693 COMOROS' },
    { value: 242, text: '+242 CONGO' },
    { value: 682, text: '+682 COOK ISLANDS' },
    { value: 506, text: '+506 COSTA RICA' },
    { value: 385, text: '+385 CROATIA' },
    { value: 53, text: '+53 CUBA' },
    { value: 357, text: '+357 CYPRUS' },
    { value: 420, text: '+420 CZECH REPUBLIC' },
    { value: 243, text: '+243 DEMOCRATIC REPUBLIC OF CONGO' },
    { value: 45, text: '+45 DENMARK' },
    { value: 253, text: '+253 DJIBOUTI' },
    { value: 1767, text: '+1767 DOMINICA' },
    { value: 1809, text: '+1809 DOMINICAN REPUBLIC' },
    { value: 670, text: '+670 EAST TIMOR' },
    { value: 593, text: '+593 ECUADOR' },
    { value: 20, text: '+20 EGYPT' },
    { value: 503, text: '+503 EL SALVADOR' },
    { value: 240, text: '+240 EQUATORIAL GUINEA' },
    { value: 291, text: '+291 ERITREA' },
    { value: 372, text: '+372 ESTONIA' },
    { value: 251, text: '+251 ETHIOPIA' },
    { value: 298, text: '+298 FAROE ISLANDS' },
    { value: 691, text: '+691 FEDERATED STATES OF MICRONESIA' },
    { value: 679, text: '+679 FIJI' },
    { value: 358, text: '+358 FINLAND' },
    { value: 594, text: '+594 FRENCH GUYANA' },
    { value: 689, text: '+689 FRENCH POLYNESIA' },
    { value: 241, text: '+241 GABON' },
    { value: 220, text: '+220 GAMBIA' },
    { value: 995, text: '+995 GEORGIA' },
    { value: 233, text: '+233 GHANA' },
    { value: 350, text: '+350 GIBRALTAR' },
    { value: 30, text: '+30 GREECE' },
    { value: 299, text: '+299 GREENLAND' },
    { value: 1473, text: '+1473 GRENADA' },
    { value: 590, text: '+590 GUADELOUPE' },
    { value: 1671, text: '+1671 GUAM' },
    { value: 502, text: '+502 GUATEMALA' },
    { value: 224, text: '+224 GUINEA' },
    { value: 592, text: '+592 GUYANA' },
    { value: 509, text: '+509 HAITI' },
    { value: 504, text: '+504 HONDURAS' },
    { value: 852, text: '+852 HONG KONG' },
    { value: 36, text: '+36 HUNGARY' },
    { value: 354, text: '+354 ICELAND' },
    { value: 91, text: '+91 INDIA' },
    { value: 62, text: '+62 INDONESIA' },
    { value: 98, text: '+98 IRAN' },
    { value: 964, text: '+964 IRAQ' },
    { value: 353, text: '+353 IRELAND' },
    { value: 972, text: '+972 ISRAEL' },
    { value: 225, text: '+225 IVORY COAST' },
    { value: 1876, text: '+1876 JAMAICA' },
    { value: 962, text: '+962 JORDAN' },
    { value: 7, text: '+7 KAZAKHSTAN' },
    { value: 254, text: '+254 KENYA' },
    { value: 686, text: '+686 KIRIBATI' },
    { value: 381, text: '+381 KOSOVO' },
    { value: 965, text: '+965 KUWAIT' },
    { value: 996, text: '+996 KYRGYZSTAN' },
    { value: 856, text: '+856 LAOS' },
    { value: 371, text: '+371 LATVIA' },
    { value: 961, text: '+961 LEBANON' },
    { value: 266, text: '+266 LESOTHO' },
    { value: 231, text: '+231 LIBERIA' },
    { value: 218, text: '+218 LIBYA' },
    { value: 423, text: '+423 LIECHTENSTEIN' },
    { value: 370, text: '+370 LITHUANIA' },
    { value: 352, text: '+352 LUXEMBOURG' },
    { value: 389, text: '+389 MACEDONIA' },
    { value: 261, text: '+261 MADAGASCAR' },
    { value: 265, text: '+265 MALAWI' },
    { value: 60, text: '+60 MALAYSIA' },
    { value: 960, text: '+960 MALDIVES' },
    { value: 223, text: '+223 MALI' },
    { value: 356, text: '+356 MALTA' },
    { value: 692, text: '+692 MARSHALL ISLANDS' },
    { value: 596, text: '+596 MARTINIQUE' },
    { value: 222, text: '+222 MAURITANIA' },
    { value: 262, text: '+262 MAYOTTE' },
    { value: 52, text: '+52 MEXICO' },
    { value: 373, text: '+373 MOLDOVA' },
    { value: 377, text: '+377 MONACO' },
    { value: 976, text: '+976 MONGOLIA' },
    { value: 382, text: '+382 MONTENEGRO' },
    { value: 1664, text: '+1664 MONTSERRAT' },
    { value: 212, text: '+212 MOROCCO' },
    { value: 258, text: '+258 MOZAMBIQUE' },
    { value: 95, text: '+95 MYANMAR' },
    { value: 264, text: '+264 NAMIBIA' },
    { value: 674, text: '+674 NAURU' },
    { value: 977, text: '+977 NEPAL' },
    { value: 599, text: '+599 NETHERLANDS ANTILLES' },
    { value: 687, text: '+687 NEW CALEDONIA' },
    { value: 64, text: '+64 NEW ZEALAND' },
    { value: 505, text: '+505 NICARAGUA' },
    { value: 227, text: '+227 NIGER' },
    { value: 234, text: '+234 NIGERIA' },
    { value: 683, text: '+683 NIUE' },
    { value: 672, text: '+672 NORFOLK ISLAND' },
    { value: 850, text: '+850 NORTH KOREA' },
    { value: 47, text: '+47 NORWAY' },
    { value: 968, text: '+968 OMAN' },
    { value: 92, text: '+92 PAKISTAN' },
    { value: 970, text: '+970 PALESTINIAN OCCUPIED TERRITORIES' },
    { value: 507, text: '+507 PANAMA' },
    { value: 675, text: '+675 PAPUA NEW GUINEA' },
    { value: 595, text: '+595 PARAGUAY' },
    { value: 51, text: '+51 PERU' },
    { value: 63, text: '+63 PHILIPPINES' },
    { value: 48, text: '+48 POLAND' },
    { value: 351, text: '+351 PORTUGAL' },
    { value: 1787, text: '+1787 PUERTO RICO' },
    { value: 974, text: '+974 QATAR' },
    { value: 262, text: '+262 REUNION' },
    { value: 40, text: '+40 ROMANIA' },
    { value: 250, text: '+250 RWANDA' },
    { value: 290, text: '+290 SAINT HELENA AND DEPENDENCIES' },
    { value: 1869, text: '+1869 SAINT KITTS AND NEVIS' },
    { value: 1758, text: '+1758 SAINT LUCIA' },
    { value: 508, text: '+508 SAINT PIERRE AND MIQUELON' },
    { value: 1784, text: '+1784 SAINT VINCENT AND THE GRENADINES' },
    { value: 378, text: '+378 SAN MARINO' },
    { value: 239, text: '+239 SAO TOME AND PRINCIPE' },
    { value: 966, text: '+966 SAUDI ARABIA' },
    { value: 221, text: '+221 SENEGAL' },
    { value: 381, text: '+381 SERBIA' },
    { value: 248, text: '+248 SEYCHELLES' },
    { value: 232, text: '+232 SIERRA LEONE' },
    { value: 65, text: '+65 SINGAPORE' },
    { value: 421, text: '+421 SLOVAKIA' },
    { value: 386, text: '+386 SLOVENIA' },
    { value: 677, text: '+677 SOLOMON ISLANDS' },
    { value: 252, text: '+252 SOMALIA' },
    { value: 27, text: '+27 SOUTH AFRICA' },
    { value: 82, text: '+82 SOUTH KOREA' },
    { value: 94, text: '+94 SRI LANKA' },
    { value: 249, text: '+249 SUDAN' },
    { value: 597, text: '+597 SURINAME' },
    { value: 268, text: '+268 SWAZILAND' },
    { value: 46, text: '+46 SWEDEN' },
    { value: 41, text: '+41 SWITZERLAND' },
    { value: 963, text: '+963 SYRIA' },
    { value: 886, text: '+886 TAIWAN' },
    { value: 992, text: '+992 TAJIKISTAN' },
    { value: 255, text: '+255 TANZANIA' },
    { value: 66, text: '+66 THAILAND' },
    { value: 228, text: '+228 TOGO' },
    { value: 676, text: '+676 TONGA' },
    { value: 1868, text: '+1868 TRINIDAD AND TOBAGO' },
    { value: 216, text: '+216 TUNISIA' },
    { value: 993, text: '+993 TURKMENISTAN' },
    { value: 1649, text: '+1649 TURKS AND CAICOS ISLANDS' },
    { value: 688, text: '+688 TUVALU' },
    { value: 1340, text: '+1340 US VIRGIN ISLANDS' },
    { value: 256, text: '+256 UGANDA' },
    { value: 380, text: '+380 UKRAINE' },
    { value: 971, text: '+971 UNITED ARAB EMIRATES' },
    { value: 598, text: '+598 URUGUAY' },
    { value: 998, text: '+998 UZBEKISTAN' },
    { value: 379, text: '+379 VATICAN CITY' },
    { value: 58, text: '+58 VENEZUELA' },
    { value: 84, text: '+84 VIETNAM' },
    { value: 681, text: '+681 WALLIS AND FUTUNA' },
    { value: 967, text: '+967 YEMEN' },
    { value: 260, text: '+260 ZAMBIA' },
    { value: 263, text: '+263 ZIMBABWE' },
    { value: 0, text: 'OTHER COUNTRIES' }
  ];
}

export function getLands() {
  return [
    { country: 'NETHERLANDS' },
    { country: 'AFGHANISTAN' },
    { country: 'ALBANIA' },
    { country: 'ALGERIA' },
    { country: 'AMERICAN SAMOA' },
    { country: 'ANDORRA' },
    { country: 'ANGOLA' },
    { country: 'ANGUILLA' },
    { country: 'ANTARCTICA' },
    { country: 'ANTIGUA AND BARBUDA' },
    { country: 'ARGENTINA' },
    { country: 'ARMENIA' },
    { country: 'ARUBA' },
    { country: 'AUSTRALIA' },
    { country: 'AUSTRIA' },
    { country: 'AZERBAIJAN' },
    { country: 'BAHAMAS' },
    { country: 'BAHRAIN' },
    { country: 'BANGLADESH' },
    { country: 'BARBADOS' },
    { country: 'BELARUS' },
    { country: 'BELGIUM' },
    { country: 'BELIZE' },
    { country: 'BENIN' },
    { country: 'BERMUDA' },
    { country: 'BHUTAN' },
    { country: 'BOLIVIA' },
    { country: 'BOSNIA AND HERZEGOVINA' },
    { country: 'BOTSWANA' },
    { country: 'BOUVET ISLAND' },
    { country: 'BRAZIL' },
    { country: 'BRITISH INDIAN OCEAN TERRITORY' },
    { country: 'BRUNEI DARUSSALAM' },
    { country: 'BULGARIA' },
    { country: 'BURKINA FASO' },
    { country: 'BURUNDI' },
    { country: 'CAMBODIA' },
    { country: 'CAMEROON' },
    { country: 'CANADA' },
    { country: 'CAPE VERDE' },
    { country: 'CAYMAN ISLANDS' },
    { country: 'CENTRAL AFRICAN REPUBLIC' },
    { country: 'CHAD' },
    { country: 'CHILE' },
    { country: 'CHINA' },
    { country: 'CHRISTMAS ISLAND' },
    { country: 'COCOS (KEELING) ISLANDS' },
    { country: 'COLOMBIA' },
    { country: 'COMOROS' },
    { country: 'CONGO' },
    { country: 'CONGO, THE DEMOCRATIC REPUBLIC OF THE' },
    { country: 'COOK ISLANDS' },
    { country: 'COSTA RICA' },
    { country: "COTE D'IVOIRE" },
    { country: 'CROATIA' },
    { country: 'CUBA' },
    { country: 'CYPRUS' },
    { country: 'CZECH REPUBLIC' },
    { country: 'DENMARK' },
    { country: 'DJIBOUTI' },
    { country: 'DOMINICA' },
    { country: 'DOMINICAN REPUBLIC' },
    { country: 'ECUADOR' },
    { country: 'EGYPT' },
    { country: 'EL SALVADOR' },
    { country: 'EQUATORIAL GUINEA' },
    { country: 'ERITREA' },
    { country: 'ESTONIA' },
    { country: 'ETHIOPIA' },
    { country: 'FALKLAND ISLANDS (MALVINAS)' },
    { country: 'FAROE ISLANDS' },
    { country: 'FIJI' },
    { country: 'FINLAND' },
    { country: 'FRANCE' },
    { country: 'FRENCH GUIANA' },
    { country: 'FRENCH POLYNESIA' },
    { country: 'FRENCH SOUTHERN TERRITORIES' },
    { country: 'GABON' },
    { country: 'GAMBIA' },
    { country: 'GEORGIA' },
    { country: 'GERMANY' },
    { country: 'GHANA' },
    { country: 'GIBRALTAR' },
    { country: 'GREECE' },
    { country: 'GREENLAND' },
    { country: 'GRENADA' },
    { country: 'GUADELOUPE' },
    { country: 'GUAM' },
    { country: 'GUATEMALA' },
    { country: 'GUERNSEY' },
    { country: 'GUINEA' },
    { country: 'GUINEA-BISSAU' },
    { country: 'GUYANA' },
    { country: 'HAITI' },
    { country: 'HEARD ISLAND AND MCDONALD ISLANDS' },
    { country: 'HOLY SEE (VATICAN CITY STATE)' },
    { country: 'HONDURAS' },
    { country: 'HONG KONG' },
    { country: 'HUNGARY' },
    { country: 'ICELAND' },
    { country: 'INDIA' },
    { country: 'INDONESIA' },
    { country: 'IRAN, ISLAMIC REPUBLIC OF' },
    { country: 'IRAQ' },
    { country: 'IRELAND' },
    { country: 'ISLE OF MAN' },
    { country: 'ISRAEL' },
    { country: 'ITALY' },
    { country: 'JAMAICA' },
    { country: 'JAPAN' },
    { country: 'JERSEY' },
    { country: 'JORDAN' },
    { country: 'KAZAKHSTAN' },
    { country: 'KENYA' },
    { country: 'KIRIBATI' },
    { country: "KOREA, DEMOCRATIC PEOPLE'S REPUBLIC OF" },
    { country: 'KOREA, REPUBLIC OF' },
    { country: 'KUWAIT' },
    { country: 'KYRGYZSTAN' },
    { country: "LAO PEOPLE'S DEMOCRATIC REPUBLIC" },
    { country: 'LATVIA' },
    { country: 'LEBANON' },
    { country: 'LESOTHO' },
    { country: 'LIBERIA' },
    { country: 'LIBYAN ARAB JAMAHIRIYA' },
    { country: 'LIECHTENSTEIN' },
    { country: 'LITHUANIA' },
    { country: 'LUXEMBOURG' },
    { country: 'MACAO' },
    { country: 'MACEDONIA, THE FORMER YUGOSLAV REPUBLIC OF' },
    { country: 'MADAGASCAR' },
    { country: 'MALAWI' },
    { country: 'MALAYSIA' },
    { country: 'MALDIVES' },
    { country: 'MALI' },
    { country: 'MALTA' },
    { country: 'MARSHALL ISLANDS' },
    { country: 'MARTINIQUE' },
    { country: 'MAURITANIA' },
    { country: 'MAURITIUS' },
    { country: 'MAYOTTE' },
    { country: 'MEXICO' },
    { country: 'MICRONESIA, FEDERATED STATES OF' },
    { country: 'MOLDOVA, REPUBLIC OF' },
    { country: 'MONACO' },
    { country: 'MONGOLIA' },
    { country: 'MONTSERRAT' },
    { country: 'MOROCCO' },
    { country: 'MOZAMBIQUE' },
    { country: 'MYANMAR' },
    { country: 'NAMIBIA' },
    { country: 'NAURU' },
    { country: 'NEPAL' },

    { country: 'NETHERLANDS ANTILLES' },
    { country: 'NEW CALEDONIA' },
    { country: 'NEW ZEALAND' },
    { country: 'NICARAGUA' },
    { country: 'NIGER' },
    { country: 'NIGERIA' },
    { country: 'NIUE' },
    { country: 'NORFOLK ISLAND' },
    { country: 'NORTHERN MARIANA ISLANDS' },
    { country: 'NORWAY' },
    { country: 'OMAN' },
    { country: 'PAKISTAN' },
    { country: 'PALAU' },
    { country: 'PALESTINIAN TERRITORY, OCCUPIED' },
    { country: 'PANAMA' },
    { country: 'PAPUA NEW GUINEA' },
    { country: 'PARAGUAY' },
    { country: 'PERU' },
    { country: 'PHILIPPINES' },
    { country: 'PITCAIRN' },
    { country: 'POLAND' },
    { country: 'PORTUGAL' },
    { country: 'PUERTO RICO' },
    { country: 'QATAR' },
    { country: 'REUNION' },
    { country: 'ROMANIA' },
    { country: 'RUSSIAN FEDERATION' },
    { country: 'RWANDA' },
    { country: 'SAINT HELENA' },
    { country: 'SAINT KITTS AND NEVIS' },
    { country: 'SAINT LUCIA' },
    { country: 'SAINT PIERRE AND MIQUELON' },
    { country: 'SAINT VINCENT AND THE GRENADINES' },
    { country: 'SAMOA' },
    { country: 'SAN MARINO' },
    { country: 'SAO TOME AND PRINCIPE' },
    { country: 'SAUDI ARABIA' },
    { country: 'SENEGAL' },
    { country: 'SERBIA AND MONTENEGRO' },
    { country: 'SEYCHELLES' },
    { country: 'SIERRA LEONE' },
    { country: 'SINGAPORE' },
    { country: 'SLOVAKIA' },
    { country: 'SLOVENIA' },
    { country: 'SOLOMON ISLANDS' },
    { country: 'SOMALIA' },
    { country: 'SOUTH AFRICA' },
    { country: 'SOUTH GEORGIA AND THE SOUTH SANDWICH ISLANDS' },
    { country: 'SPAIN' },
    { country: 'SRI LANKA' },
    { country: 'SUDAN' },
    { country: 'SURINAME' },
    { country: 'SVALBARD AND JAN MAYEN' },
    { country: 'SWAZILAND' },
    { country: 'SWEDEN' },
    { country: 'SWITZERLAND' },
    { country: 'SYRIAN ARAB REPUBLIC' },
    { country: 'TAIWAN, PROVINCE OF CHINA' },
    { country: 'TAJIKISTAN' },
    { country: 'TANZANIA, UNITED REPUBLIC OF' },
    { country: 'THAILAND' },
    { country: 'TIMOR-LESTE' },
    { country: 'TOGO' },
    { country: 'TOKELAU' },
    { country: 'TONGA' },
    { country: 'TRINIDAD AND TOBAGO' },
    { country: 'TUNISIA' },
    { country: 'TURKEY' },
    { country: 'TURKMENISTAN' },
    { country: 'TURKS AND CAICOS ISLANDS' },
    { country: 'TUVALU' },
    { country: 'UGANDA' },
    { country: 'UKRAINE' },
    { country: 'UNITED ARAB EMIRATES' },
    { country: 'UNITED KINGDOM' },
    { country: 'UNITED STATES' },
    { country: 'UNITED STATES MINOR OUTLYING ISLANDS' },
    { country: 'URUGUAY' },
    { country: 'UZBEKISTAN' },
    { country: 'VANUATU' },
    { country: 'VENEZUELA' },
    { country: 'VIET NAM' },
    { country: 'VIRGIN ISLANDS, BRITISH' },
    { country: 'VIRGIN ISLANDS, U.S.' },
    { country: 'WALLIS AND FUTUNA' },
    { country: 'WESTERN SAHARA' },
    { country: 'YEMEN' },
    { country: 'ZAMBIA' },
    { country: 'ZIMBABWE' }
  ];
}

export function getUserID() {
  const userJson = Cookies.get('Login'); //localStorage.getItem('Login');
  let loginData = userJson ? JSON.parse(userJson) : {};
  let clientID = '';
  if (loginData && loginData.username && loginData.key && loginData.key.clientGuid) {
    clientID = loginData.key.clientGuid;
  }
  return clientID;
}

export function GetDecimalForJson(val) {
  let number = parseFloat(val);
  return number.toFixed(2);
}

export function RedirectWithStatus({ from, to, status }) {
  return (
    <Route
      render={({ staticContext }) => {
        // there is no `staticContext` on the client, so
        // we need to guard against that here

        if (staticContext) staticContext.status = status;

        return <Redirect from={from} to={to} />;
      }}
    />
  );
}

export function calculateAge(birthDay, birthMonth, birthYear){
  let today = new Date();
  let birthDate = new Date(birthYear, birthMonth -1, birthDay);
  
  var age = today.getFullYear() - birthDate.getFullYear();
  var m = today.getMonth() - birthDate.getMonth();
  if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
      age--;
  }
  return age;
}

export function calculateAgeAtFlightDate(birthDay, birthMonth, birthYear, flightDate){
  let birthDate = new Date(birthYear, birthMonth -1, birthDay);
  
  var age = flightDate.getFullYear() - birthDate.getFullYear();
  var m = flightDate.getMonth() - birthDate.getMonth();
  if (m < 0 || (m === 0 && flightDate.getDate() < birthDate.getDate())) {
      age--;
  }
  return age;
}
